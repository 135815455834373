import { ReactNode, useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

import { GetSurveysQuery, SurveyFragment, UnitOfMeasure } from '../../graphql/types.generated';
import {
  GeneralSurveySliderQuestion,
  SurveyQuestion,
  SanitizedSurvey,
  SurveyFormValues,
  AwardVouchersSurvey,
  SurveyAward,
  GetControlForQuestionParams,
} from './survey.types';
import { isSurveyValid } from './survey.validation';
import { buildGetIsDismissed } from './storage/get-dismissed-surveys.utils';
import { AwardPoints, AwardType } from '../offers/award.types';
import { SizingMultiplier } from '../../theme/constants';
import { SliderField } from '../../components/Fields/SliderField/SliderField';
import { sliderTooltipMessages } from './survey.messages';
import { RadiosField } from '../../components/Fields/RadiosField/RadiosField';
import { CheckboxesField } from '../../components/Fields/CheckboxesField/CheckboxesField';

export const getFirstNotDismissedSurvey = async (
  membershipId: string,
  surveys: SanitizedSurvey[],
): Promise<SanitizedSurvey | null> => {
  const isDismissed = await buildGetIsDismissed(membershipId);

  const firstNotDismissedSurvey = surveys.find(({ uuid }) => !isDismissed(uuid));

  if (!firstNotDismissedSurvey) {
    return null;
  }

  return firstNotDismissedSurvey;
};

export type SanitizeSurveyParams = {
  surveys?: GetSurveysQuery['surveys'];
};

export const sanitizeSurvey = (survey: SurveyFragment): SanitizedSurvey | null => {
  if (isSurveyValid(survey)) {
    return {
      ...survey,
      type: 'authenticated',
    };
  }

  return null;
};

export const sanitizeSurveys = ({ surveys }: SanitizeSurveyParams): SanitizedSurvey[] => {
  if (!surveys) {
    return [];
  }

  const rawSurveys: SurveyFragment[] = surveys?.edges.map(({ node }) => node) || [];

  return rawSurveys.map(sanitizeSurvey).filter((survey): survey is SanitizedSurvey => survey !== null);
};

const isGeneralSurveySliderQuestion = (
  question: SanitizedSurvey['questions'][0],
): question is GeneralSurveySliderQuestion => {
  if (question.__typename === 'SurveyQuestionIntSlider') {
    // WalletSteeringSurvey stores 'min' and 'max' values in configuration
    // At the same time, general survey stores these values in 'question' itself
    // That's why we need to check if 'min' exists
    return 'min' in question;
  }

  return false;
};

export const getQuestionsFromSurvey = (survey: SanitizedSurvey): SurveyQuestion[] => {
  const sanitizeQuestion = (question: SanitizedSurvey['questions'][0]): SurveyQuestion | null => {
    if (survey.__typename === 'WalletSteeringSurvey') {
      return {
        type: 'slider',
        uuid: question.uuid,
        min: survey.configuration.minUnits,
        max: survey.configuration.maxUnits,
        question: question.questionText,
        measurement: survey.configuration.steeringWalletUnitOfMeasure,
        // First item because we support only one question with only one field
        name: survey.questions[0].fields[0].uuid,
        surveyTypename: survey.__typename,
      };
    }

    if (isGeneralSurveySliderQuestion(question)) {
      const { min, max } = question;

      return {
        type: 'slider',
        uuid: question.uuid,
        min,
        max,
        question: question.questionText,
        name: question.uuid,
        measurement: UnitOfMeasure.Unit,
        surveyTypename: survey.__typename,
      };
    }

    if (question.__typename === 'SurveyQuestionSingleChoice') {
      return {
        type: 'single-choice',
        uuid: question.uuid,
        question: question.questionText,
        name: question.uuid,
        options: question.options,
        surveyTypename: survey.__typename,
      };
    }

    if (question.__typename === 'SurveyQuestionMultipleChoice') {
      return {
        type: 'multiple-choice',
        uuid: question.uuid,
        question: question.questionText,
        name: question.uuid,
        options: question.options,
        surveyTypename: survey.__typename,
      };
    }

    return null;
  };

  return survey.questions.map(sanitizeQuestion).filter((question): question is SurveyQuestion => question !== null);
};

export const buildSurveyFormInitialValues = (questions: SurveyQuestion[]): SurveyFormValues => {
  return questions.reduce((accumulator, question) => {
    if (question.type === 'slider') {
      const { min, max, name } = question;

      return {
        ...accumulator,
        [name]: Math.floor((min + max) / 2),
      };
    }

    if (question.type === 'single-choice') {
      return {
        ...accumulator,
        [question.name]: null,
      };
    }

    if (question.type === 'multiple-choice') {
      return {
        ...accumulator,
        [question.name]: [],
      };
    }

    return accumulator;
  }, {});
};

interface IGetNextQuestionUUIDParams {
  uuid: string | null;
  questions: SurveyQuestion[];
}

interface IGetNextQuestionUUIDReturns {
  uuid: string | null;
  reason: 'no-questions' | 'no-next-question' | null;
}

export const getNextQuestionUUID = ({ uuid, questions }: IGetNextQuestionUUIDParams): IGetNextQuestionUUIDReturns => {
  if (!questions.length) {
    return { uuid: null, reason: 'no-questions' };
  }

  if (!uuid) {
    return { uuid: questions[0].uuid, reason: null };
  }

  const lastAnsweredQuestionIndex = questions.findIndex(({ uuid: currentUuid }) => currentUuid === uuid);

  if (lastAnsweredQuestionIndex === -1 || lastAnsweredQuestionIndex + 2 > questions.length) {
    return { uuid: null, reason: 'no-next-question' };
  }

  return { uuid: questions[lastAnsweredQuestionIndex + 1].uuid, reason: null };
};

export const getSurveyAward = ({ award }: SanitizedSurvey): SurveyAward => {
  if (!award) {
    return null;
  }

  if (award.__typename === 'AwardPoints') {
    const result: AwardPoints = {
      type: AwardType.Points,
      points: award.points,
    };
    return result;
  }

  if (award.__typename === 'AwardVouchers') {
    const title = award.voucheredOffer.marketingContents[0]?.title ?? undefined;
    const imageUrl = award.voucheredOffer.marketingContents[0]?.imageUrl ?? undefined;
    const result: AwardVouchersSurvey = { type: AwardType.Vouchers, offer: { title, imageUrl } };

    return result;
  }

  return null;
};

export const getIsSurveySubmitDisabled = (question: SurveyQuestion, formProps: FormRenderProps<SurveyFormValues>) => {
  const { modified, hasValidationErrors, submitting, values } = formProps;

  if (question.type === 'slider') {
    const hasValueChangedOnce = modified?.[question.name] ?? false;
    return hasValidationErrors || submitting || !hasValueChangedOnce;
  }

  if (question.type === 'multiple-choice') {
    const selectedValues = values[question.name];
    const hasNoSelectedValues = Array.isArray(selectedValues) ? selectedValues.length === 0 : true;
    return hasValidationErrors || submitting || hasNoSelectedValues;
  }

  if (question.type === 'single-choice') {
    const selectedValue = values[question.name];
    const hasNoSelectedValue = typeof selectedValue !== 'string';
    return hasValidationErrors || submitting || hasNoSelectedValue;
  }

  return true;
};

export const useGetControlForQuestion = ({ question, onChange }: GetControlForQuestionParams): ReactNode | null => {
  const intl = useIntl();

  return useMemo(() => {
    if (question.type === 'slider') {
      const { min, max, name, measurement } = question;

      return (
        <Box sx={{ pt: SizingMultiplier.lg }}>
          <SliderField
            valueLabelDisplay="on"
            valueLabelFormat={(value: number) => intl.formatMessage(sliderTooltipMessages[measurement], { value })}
            name={name}
            min={min}
            max={max}
            onChange={onChange}
          />
        </Box>
      );
    }

    if (question.type === 'single-choice') {
      return <RadiosField name={question.name} options={question.options} />;
    }

    if (question.type === 'multiple-choice') {
      return <CheckboxesField name={question.name} options={question.options} />;
    }

    return null;
  }, [intl, onChange, question]);
};
