import { useMemo } from 'react';
import { WatchQueryFetchPolicy } from '@apollo/client';

import { ProductCategoryFragment, useProductCategoriesQuery } from '../../graphql/types.generated';
import { sortProductCategoriesByNameAndRank } from './utils';
import { useConfiguration } from '../configuration/useConfiguration';

export interface IUseOfferCategoriesParams {
  fetchPolicy?: WatchQueryFetchPolicy;
}
export interface IUseOfferCategoriesReturns {
  productCategories: ProductCategoryFragment[];
  loading: boolean;
  refetch(): Promise<any>;
}

export const useProductCategories = (params: IUseOfferCategoriesParams = {}): IUseOfferCategoriesReturns => {
  const { fetchPolicy = 'cache-and-network' } = params;
  const chainId = useConfiguration('chainId');
  const { data, loading, refetch } = useProductCategoriesQuery({
    fetchPolicy,
    variables: {
      filter: {
        chainIdEquals: chainId,
      },
    },
  });

  const productCategories: ProductCategoryFragment[] = useMemo(
    () => data?.productCategories.edges.map((edge) => edge.node).sort(sortProductCategoriesByNameAndRank) || [],
    [data],
  );
  return { productCategories, loading, refetch };
};
