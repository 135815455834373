import { ApolloError, isApolloError } from '@apollo/client';
import map from 'lodash/map';
import get from 'lodash/get';
import { GraphQLErrors } from '@apollo/client/errors';

import { UserVisibleError } from './apollo.types';

export const defaultGeneralErrorMessage = 'Something went wrong. Please try again.';

const getUserVisibleError = (errors: GraphQLErrors): string | null => {
  if (!errors.length) {
    return null;
  }
  return (
    errors
      .filter((error) => !!error.extensions?.exception?.userVisibleError)
      .map((error) => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const visibleError = error!.extensions!.exception!.userVisibleError as UserVisibleError;
        return `${visibleError.message} ${visibleError!.details}`;
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      })
      .join('\n') || null
  );
};

type FormatApolloErrorOptions = {
  defaultMessage?: string;
  ignoreNetworkError?: boolean;
  ignoreGraphqlError?: boolean;
};
export const formatApolloError = (error?: ApolloError | Error, options: FormatApolloErrorOptions = {}): string => {
  const {
    defaultMessage = defaultGeneralErrorMessage,
    ignoreGraphqlError = false,
    ignoreNetworkError = false,
  } = options;

  if (!error || !isApolloError(error)) {
    return defaultMessage;
  }

  const { graphQLErrors, networkError, message } = error;
  if (!graphQLErrors.length && !networkError) {
    return message || defaultMessage;
  }

  const userVisibleError = getUserVisibleError(graphQLErrors);
  if (userVisibleError) {
    return userVisibleError;
  }

  const graphQlErrorMessage = map(graphQLErrors, (error) => error.message).join('\n');
  if (graphQlErrorMessage && !ignoreGraphqlError) {
    return graphQlErrorMessage;
  }
  const networkErrorArray = get(networkError, 'result.errors', []);
  const networkErrorMessages = map(networkErrorArray, 'message').join('\n');
  const networkErrorMessage = get(networkError, 'message');
  if ((networkErrorMessages || networkErrorMessage) && !ignoreNetworkError) {
    return networkErrorMessages || networkErrorMessage || defaultMessage;
  }

  return defaultMessage;
};

// TODO: handle case with a rate limiting API exception, that does not change code on the exception, but it should
// The best way to resolve it is to update an API
export const hasGraphQLErrorCode = (error: ApolloError, code: string) =>
  error.graphQLErrors.findIndex(({ extensions }) => extensions?.code === code) > -1;
