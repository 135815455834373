import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Grid, Container } from '@mui/material';
import { Form } from 'react-final-form';

import { PrepaidCardSchema } from './vault-card.schema';
import { CardCVVField } from '../../components/CardCVVField';
import { PrepaidCardFormFields } from './vault-card.types';
import { makeValidateSync } from '../../lib/form/utils';
import { SizingMultiplier } from '../../theme/constants';
import { Button } from '../../components/Button';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { useShowCardNumberError } from './vault-card.utils';
import { PaymentAccountType } from '../../graphql/types.generated';
import { TextField } from '../../components/TextField';

export type AddPrepaidCardProps = {
  onSubmit(values: PrepaidCardFormFields): Promise<void>;
};

const validate = makeValidateSync(PrepaidCardSchema);
const initialValues: PrepaidCardFormFields = {
  accountType: PaymentAccountType.Prepaid,
  cardNumber: '',
  securityCode: '',
};

export const AddPrepaidCard = (props: AddPrepaidCardProps) => {
  const { formatMessage } = useIntl();
  const showCardNumberError = useShowCardNumberError();
  const { onSubmit } = props;

  return (
    <Container sx={{ pt: SizingMultiplier.lg }}>
      <Typography variant="h4" align="center" mb={SizingMultiplier.md}>
        <FormattedMessage
          id="add-card:prepaid:title"
          defaultMessage="Add Card"
          description="Title for Add Prepaid Card page"
        />
      </Typography>
      <Form<PrepaidCardFormFields>
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="cardNumber"
                  fullWidth
                  label={formatMessage({
                    id: 'add-card:prepaid:card-number-field-label',
                    defaultMessage: 'Card Number',
                    description: 'Card Number field label',
                  })}
                  showError={showCardNumberError}
                />
              </Grid>
              <Grid item xs={12}>
                <CardCVVField
                  variant="outlined"
                  name="securityCode"
                  fullWidth
                  label={formatMessage({
                    id: 'add-card:prepaid:cvv-label',
                    defaultMessage: 'PIN number',
                    description: 'PIN number field label',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={hasValidationErrors || !dirty} type="submit" fullWidth>
                  <FormattedMessage {...buttonTextMessages['button-text:submit']} />
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};
