import { useIntl } from 'react-intl';
import { useCallback } from 'react';

import { useSnackbar } from '../../../lib/snackbar/snackbar';
import { IneligibilityReason } from '../../../modules/offers/eligibility.types';
import { ConstraintType } from '../../../modules/offers/custom-data/constraints/constraints.types';
import { ineligibilityMessages } from './ineligibility.messages';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { PointsStrategy } from '../../../graphql/types.generated';

const findIfMembershipHasReward = (reason: IneligibilityReason) =>
  reason.type === 'constraint_failed' && reason.constraint[0] === ConstraintType.IneligibleIfMembershipHasReward;
const findNotEnoughPoints = (reason: IneligibilityReason) => reason.type === 'not_enough_points';

export const useShowExplanationSnackbarForIneligibleOffer = () => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  return useCallback(
    (reason: IneligibilityReason) => {
      if (findIfMembershipHasReward(reason)) {
        snackbar.open(
          intl.formatMessage(ineligibilityMessages['offer:ineligibility-reason:single-reward-restriction']),
        );
        return;
      }
      if (findNotEnoughPoints(reason) && pointsStrategy === PointsStrategy.Points) {
        snackbar.open(
          intl.formatMessage(
            ineligibilityMessages['offer:points-strategy:points:ineligibility-reason:not-enough-funds'],
          ),
        );
        return;
      }
      if (findNotEnoughPoints(reason) && pointsStrategy === PointsStrategy.Cents) {
        snackbar.open(
          intl.formatMessage(
            ineligibilityMessages['offer:points-strategy:cents:ineligibility-reason:not-enough-funds'],
          ),
        );
        return;
      }
      snackbar.open(intl.formatMessage(ineligibilityMessages['offer:ineligibility-reason:unknown']));
    },
    [intl, pointsStrategy, snackbar],
  );
};
