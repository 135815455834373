import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { PointsStrategy } from '../../../graphql/types.generated';
import { formatPrice } from '../../../lib/formatters/formatPrice';
import { BuyAllEntriesConfirmationActionSheetProps } from './buy-all-entries-confirmation-action-sheet.types';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { messages } from './buy-all-entries-confirmation-action-sheet.messages';
import { BuyEntriesButton } from '../../../pages/Sweeps/PointsSweeps/components/BuyEntriesButton';

export const BuyAllEntriesConfirmationActionSheetController: React.FC<BuyAllEntriesConfirmationActionSheetProps> = (
  props,
) => {
  const intl = useIntl();
  const { entries, price, onConfirm, loading } = props;
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy') as Exclude<
    PointsStrategy,
    PointsStrategy.NotUsed
  >;
  const modal = useModal();

  return (
    <ModalDrawer open={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <Box pt={SizingMultiplier.xl} px={SizingMultiplier.lg} pb={SizingMultiplier.lg} width="100%" textAlign="center">
        <Typography variant="h5" component="h2" paragraph>
          <FormattedMessage {...messages['buy-all-entries-confirmation-action-sheet:title']} />
        </Typography>
        <Typography>
          {pointsStrategy === PointsStrategy.Points && (
            <FormattedMessage
              {...messages['buy-all-entries-confirmation-action-sheet:description:points-strategy-points']}
              values={{ entries, points: price }}
            />
          )}
          {pointsStrategy === PointsStrategy.Cents && (
            <FormattedMessage
              {...messages['buy-all-entries-confirmation-action-sheet:description:points-strategy-cents']}
              values={{ entries, dollars: formatPrice(price) }}
            />
          )}
        </Typography>
        <Stack mt={SizingMultiplier.lg} spacing={2}>
          <BuyEntriesButton
            entries={entries}
            price={price}
            onClick={onConfirm}
            loading={loading}
            pointsStrategy={pointsStrategy}
          />
          <Button variant="text" fullWidth onClick={modal.hide}>
            {intl.formatMessage(buttonTextMessages['button-text:cancel'])}
          </Button>
        </Stack>
      </Box>
    </ModalDrawer>
  );
};
