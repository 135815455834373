import { v4 as uuidV4 } from 'uuid';
import { FormatNumberOptions } from 'react-intl';
import find from 'lodash/find';
import * as Sentry from '@sentry/react';

import { IActivateTransactionReceipt, IReceipt, PrimaryInternalFuelingStatus, SanitizedReceipt } from './types';
import {
  ActivateTransactionFragment,
  ActivateTransactionLineItem,
  CarWashInput,
  LoyaltyActivateTransactionItemFragment,
  LoyaltyPointChangeItemFragment,
  PaymentInstrumentType,
  PointChangeType,
  PrimaryTransactionStatus,
  SecondaryTransactionStatus,
  TransactionFragment,
  TransactionHistoryItemFragment,
  TransactionStart,
  TransactionType,
} from '../../graphql/types.generated';
import { externalStatusToInternalStatus } from './statuses';
import { PAYMENT_INSTRUMENT_TYPE } from '../../lib/payments/payments.types';
import { PaymentInstrument } from '../wallet/wallet.types';
import { KountUniversal } from '../../lib/payments/Kount/KountUniversal';
import { sanitizePaymentInstrumentFragment } from '../wallet/wallet.utils';
import { formatDateTime } from '../../lib/date/date';
import { ITransactionHistoryItem, TransactionHistoryItemType } from '../transaction-history/transaction-history.types';
import { DateTimeFormat } from '../../lib/date/date.types';
import { ON_DEVICE_ACCOUNT_TYPES, TOKENIZABLE_ACCOUNT_TYPES } from '../wallet/wallet.constants';

export const isReceiptReady = (transaction?: TransactionFragment | null): transaction is SanitizedReceipt => {
  if (!transaction) {
    return false;
  }
  if (!transaction.receiptLines) {
    return false;
  }
  if (typeof transaction.amount?.price !== 'number') {
    return false;
  }
  if (!transaction.completedAt) {
    return false;
  }
  if (!transaction.location?.address) {
    return false;
  }
  return true;
};

export const mapReceipt = (transaction?: TransactionFragment | null): IReceipt | null => {
  if (!isReceiptReady(transaction)) {
    return null;
  }
  const {
    receiptLines,
    fuelReceipt,
    amount,
    completedAt,
    tax,
    location: { address },
    paymentInstrument,
  } = transaction;

  const receipt: IReceipt = {
    total: amount.price,
    receiptLines: receiptLines.map((line) => line?.value || '') || [],
    gallons: null,
    pricePerGallon: null,
    fuelTotal: null,
    tax: tax ? tax.price : null,
    date: completedAt,
    carWash: null,
    address: `${address.street1}, ${address.city} ${address.state}`,
    paymentInstrument: paymentInstrument
      ? sanitizePaymentInstrumentFragment(paymentInstrument, {
          // It's not important for that case
          // This file requires probably own query and own helper
          availableAccountTypes: [...TOKENIZABLE_ACCOUNT_TYPES, ...ON_DEVICE_ACCOUNT_TYPES],
        })
      : null,
  };

  if (fuelReceipt) {
    receipt.gallons = parseFloat(fuelReceipt.gallons);
    receipt.pricePerGallon = parseFloat(fuelReceipt.priceG);
    receipt.fuelTotal = parseFloat(fuelReceipt.fuelSale);
  }

  if (fuelReceipt && fuelReceipt.carWashCode && fuelReceipt.carWashName && fuelReceipt.carWashPrice) {
    receipt.carWash = {
      name: fuelReceipt.carWashName,
      code: fuelReceipt.carWashCode,
      price: parseFloat(fuelReceipt.carWashPrice),
    };
  }

  return receipt;
};

export const TRANSACTION_RECEIPT_NUMBER_FORMAT_OPTIONS: FormatNumberOptions = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
};
export const getTransactionGroupedDate = (createdAt: string) =>
  formatDateTime(new Date(createdAt), DateTimeFormat.DateYearMonthGroupBy);

export const mapPointsChangeToTransactionHistoryItem = (
  node: LoyaltyPointChangeItemFragment,
): ITransactionHistoryItem => {
  const { source, createdAt, notes, quantity, offer, type } = node;

  if (type === PointChangeType.BreakageReturn) {
    return {
      id: uuidV4(),
      title: 'Refund',
      points: quantity,
      type: TransactionHistoryItemType.PointsChange,
      date: createdAt,
      dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
      groupedDate: getTransactionGroupedDate(createdAt),
    };
  }

  /**
   * Yeah; I think that’s mostly to temporarily deal with a bug where manual point changes aren’t always associated with a user.
   * In a perfect world source would be non-nullable — but it’s nullable today.
   */
  if (!source || ['Chain', 'ActivateUser'].includes(source.__typename)) {
    return {
      id: uuidV4(),
      title: notes || 'Points Change',
      points: quantity,
      type: TransactionHistoryItemType.PointsChange,
      date: createdAt,
      dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
      groupedDate: getTransactionGroupedDate(createdAt),
    };
  }

  if (source.__typename === 'LoyaltyEvent') {
    return {
      id: uuidV4(),
      title: offer?.name || source.eventType || 'Points Change',
      points: quantity,
      type: TransactionHistoryItemType.LoyaltyEvent,
      date: createdAt,
      dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
      groupedDate: getTransactionGroupedDate(createdAt),
    };
  }

  if (source.__typename === 'OfferPurchase') {
    return {
      id: uuidV4(),
      title: source.purchasedWith.marketingContents[0]?.title || 'Points Change',
      points: quantity,
      type: TransactionHistoryItemType.PointsChange,
      date: createdAt,
      dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
      groupedDate: getTransactionGroupedDate(createdAt),
    };
  }

  if (source?.__typename === 'ActivateTransaction') {
    return {
      id: uuidV4(),
      title: 'Transaction',
      points: quantity,
      type: TransactionHistoryItemType.PointsChange,
      date: createdAt,
      dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
      groupedDate: getTransactionGroupedDate(createdAt),
    };
  }

  if (source.__typename === 'PointChange') {
    return {
      id: uuidV4(),
      title: source.offer?.marketingContents[0]?.title || 'Points Change',
      points: quantity,
      type: TransactionHistoryItemType.PointsChange,
      date: createdAt,
      dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
      groupedDate: getTransactionGroupedDate(createdAt),
    };
  }

  // Default return (should never get here)
  return {
    id: uuidV4(),
    title: 'Points Change',
    points: quantity,
    type: TransactionHistoryItemType.PointsChange,
    date: createdAt,
    dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
    groupedDate: getTransactionGroupedDate(createdAt),
  };
};

export const mapActivateTransactionToTransactionHistoryItem = (
  node: LoyaltyActivateTransactionItemFragment,
): ITransactionHistoryItem => {
  const { id, createdAt } = node;

  return {
    id,
    title: 'Transaction',
    points: null,
    type: TransactionHistoryItemType.Transaction,
    date: createdAt,
    dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.DateTimeFull),
    groupedDate: getTransactionGroupedDate(createdAt),
  };
};

export const mapTransactionToTransactionHistoryItem = (
  node: TransactionHistoryItemFragment,
): ITransactionHistoryItem => {
  const { uuid, completedAt } = node;
  return {
    id: uuid,
    title: 'Transaction',
    points: null,
    type: TransactionHistoryItemType.Transaction,
    date: completedAt,
    dateTitle: formatDateTime(new Date(completedAt), DateTimeFormat.DateTimeFull),
    groupedDate: getTransactionGroupedDate(completedAt),
  };
};

export const mapActivateTransactionToTransactionReceipt = (
  transaction: ActivateTransactionFragment,
): IActivateTransactionReceipt => {
  return {
    id: transaction.id,
    date: transaction.createdAt,
    address: transaction.location
      ? `${transaction.location.address1}, ${transaction.location.city} ${transaction.location.state}`
      : null,
    lineItems: transaction.lineItems
      // TODO fix Pick<ActivateTransactionLineItem, 'id' | 'group' | 'moneyAmount' | 'quantity' | 'sku' | 'upc'> with Fragment
      .filter(
        (
          item,
        ): item is Pick<ActivateTransactionLineItem, 'id' | 'group' | 'moneyAmount' | 'quantity' | 'sku' | 'upc'> =>
          item !== null,
      )
      .map((lineItem) => ({
        id: lineItem.id,
        amount: lineItem.moneyAmount,
        name: lineItem.sku || lineItem.upc,
        quantity: lineItem.quantity,
      })),
  };
};

export const getTransactionPrimaryFuelingStatus = (
  primary: PrimaryTransactionStatus,
  secondary: SecondaryTransactionStatus,
): PrimaryInternalFuelingStatus => {
  const status = find(externalStatusToInternalStatus, ({ primaries, secondaries }) => {
    return primaries.includes(primary) && secondaries.includes(secondary);
  });

  return status?.internalStatus || PrimaryInternalFuelingStatus.Unknown;
};

export interface BuildPayAtPumpTransactionStartInput {
  transactionType: TransactionType.AtPump;
  paymentType: PAYMENT_INSTRUMENT_TYPE | null;
  locationId: string;
  siteId: string;
  fuelingPosition: string | null;
  carWash: CarWashInput | null;
  printReceipt: boolean;
  paymentInstrument: PaymentInstrument | null;
  token: string;
  nonce: string | null;
  kountSessionId: string | null;
  pinCode: string | null;
}

export interface BuildPayInsideTransactionStartInput {
  transactionType: TransactionType.InStore;
  paymentType: 'CARD';
  paymentInstrument: PaymentInstrument;
  kountSessionId: string | null;
  pinCode: string;
}

export const buildTransactionStartInput = (
  params: BuildPayAtPumpTransactionStartInput | BuildPayInsideTransactionStartInput,
): TransactionStart => {
  const emptyParams = {
    transactionType: params.transactionType,
    kountSessionId: params.kountSessionId,
    locationUuid: null,
    siteId: null,
    printReceipt: null,
    fuelingPosition: null,
    carWash: null,

    // TODO finish all data collecting
    loyaltyInstruments: null,
    items: null,
    stac: null,
    nonce: null,
    initiationResource: null,
    geoLocation: null,
    promptForCarWash: null,
    loyaltyIdentifier: null,

    paymentInstruments: null,
    paymentInstrumentType: null,
    billingZipCode: null,
    paymentToken: null,
    pinCode: null,
  };

  const transactionStartParams: TransactionStart =
    params.transactionType === TransactionType.AtPump
      ? {
          ...emptyParams,
          locationUuid: params.locationId || null,
          siteId: params.siteId || null,
          printReceipt: params.printReceipt,
          fuelingPosition: params.fuelingPosition || null,
          carWash: params.carWash || null,
        }
      : { ...emptyParams };

  if (params.transactionType === TransactionType.AtPump) {
    if (params.nonce) {
      transactionStartParams.nonce = params.nonce;
      if (params.paymentType === 'APPLE_PAY') {
        transactionStartParams.paymentInstrumentType = PaymentInstrumentType.ApplePay;
      }
      if (params.paymentType === 'GOOGLE_PAY') {
        transactionStartParams.paymentInstrumentType = PaymentInstrumentType.GooglePay;
      }
    } else {
      if (params.paymentType === 'APPLE_PAY') {
        transactionStartParams.paymentToken = { applePay: params.token, googlePay: null };
      }
      if (params.paymentType === 'GOOGLE_PAY') {
        transactionStartParams.paymentToken = { googlePay: params.token, applePay: null };
      }
    }
  }

  if (params.paymentType === 'CARD' && params.paymentInstrument) {
    transactionStartParams.paymentInstruments = [params.paymentInstrument.uuid];
    transactionStartParams.paymentInstrumentType = params.paymentInstrument.paymentType;
    transactionStartParams.billingZipCode = params.paymentInstrument?.address?.zipCode || null;
  }
  if (params.pinCode !== null) {
    transactionStartParams.pinCode = { pinCode: params.pinCode };
  }

  return transactionStartParams;
};

export const getKountSessionId = async (): Promise<string | null> => {
  try {
    await KountUniversal.collect();
  } catch (e: any) {
    Sentry.captureException(e, {
      extra: {
        sessionId: KountUniversal.sessionId,
      },
    });
  }
  // Return Kount Session ID in any case
  return KountUniversal.sessionId;
};
