import { MEMBERSHIP_STATE } from '../../graphql/types.generated';

declare global {
  interface Window {
    /* eslint-disable camelcase */
    gtag?(key: 'config', trackingId: string, config: { page_path?: string; user_id?: string }): void;
    gtag?(key: string, trackingId: string): void;
    gtag?(type: 'event', event: string, params?: Record<string, any>): void;
    gtag?(type: 'set', params: Record<string, any>): void;
    gtag?(type: 'set', event: 'page_path', page_path: string): void;
  }
}

/**
 * A list of recognized GA event types, represented as a discriminated union of either 1- or 2- element tuples.
 * The first (always required) element is the 'name' of the event.
 * The second (optional) element is the 'parameters' object that should be sent with this event.
 * Make sure you don't use reserved keyword https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/faq/#what-is-the-reserved-keyword-error
 */
export type Event =
  | ['loading_started']
  | ['loading_completed']
  | ['pay_at_pump_select_pump']
  | ['pay_at_pump_carwash']
  | ['pay_at_pump_authorized', { type: string }]
  | ['pay_at_pump_fueling_completed']
  | ['pay_at_pump_view_receipt']
  | ['external_offer_details_modal_opened', { uuid: string }]
  | ['offer_details_modal_opened', { uuid: string }]
  | ['offer_details_not_found', { uuid: string }]
  | ['offer_activate_start', { type: OfferActivateStartType; uuid: string }]
  | ['store_locator_directions']
  | ['store_locator_map_selected_store_clicked', { uuid: string }]
  | ['store_locator_search_submit', { search: string }]
  | ['home_categories', { category: string }]
  | ['pull_to_refresh']
  | ['add_payment']
  | ['delete_payment']
  | ['delete_account_confirm_requested']
  | ['delete_account_confirmed']
  | ['verify_pin']
  | ['set_pin']
  | ['reset_pin']
  | ['pay_inside_qr_expired']
  | ['transaction_history_load_more']
  | ['transaction_history_receipts_tab_clicked']
  | ['transaction_history_loyalty_transactions_tab_clicked']
  | ['support_and_feedback_clicked']
  | ['app_rate_positive']
  | ['app_rate_negative']
  | ['ready_to_use_clicked']
  | ['offer_redeem_confirm_requested', { uuid: string }]

  // region Session tracking (sing in, sign out, session refresh, etc)
  | ['magic_link_requested_web']
  | ['magic_link_requested_ios']
  | ['magic_link_requested_android']
  | ['otp_sms_requested_web']
  | ['otp_phone_call_requested_web']
  | ['otp_email_requested_web']
  | ['otp_sms_requested_ios']
  | ['otp_phone_call_requested_ios']
  | ['otp_email_requested_ios']
  | ['otp_sms_requested_android']
  | ['otp_phone_call_requested_android']
  | ['otp_email_requested_android']

  // MagicLink/OTP request failed
  | ['magic_link_request_failed_web']
  | ['magic_link_request_failed_ios']
  | ['magic_link_request_failed_android']
  | ['otp_sms_request_failed_web']
  | ['otp_phone_call_request_failed_web']
  | ['otp_email_request_failed_web']
  | ['otp_sms_request_failed_ios']
  | ['otp_phone_call_request_failed_ios']
  | ['otp_email_request_failed_ios']
  | ['otp_sms_request_failed_android']
  | ['otp_phone_call_request_failed_android']
  | ['otp_email_request_failed_android']

  // MagicLink/OTP Session Created. Should be tracked only when session is created and not replaced.
  | ['magic_link_session_created_web']
  | ['magic_link_session_created_ios']
  | ['magic_link_session_created_android']
  | ['otp_sms_session_created_web']
  | ['otp_phone_call_session_created_web']
  | ['otp_email_session_created_web']
  | ['otp_sms_session_created_ios']
  | ['otp_phone_call_session_created_ios']
  | ['otp_email_session_created_ios']
  | ['otp_sms_session_created_android']
  | ['otp_phone_call_session_created_android']
  | ['otp_email_session_created_android']

  // Session Replaced by MagicLink/OTP. Should be tracked when user already has a session, but his session is being replaced by using a valid magic link
  | ['magic_link_session_replaced_web']
  | ['magic_link_session_replaced_ios']
  | ['magic_link_session_replaced_android']
  | ['otp_sms_session_replaced_web']
  | ['otp_phone_call_session_replaced_web']
  | ['otp_email_session_replaced_web']
  | ['otp_sms_session_replaced_ios']
  | ['otp_phone_call_session_replaced_ios']
  | ['otp_email_session_replaced_ios']
  | ['otp_sms_session_replaced_android']
  | ['otp_phone_call_session_replaced_android']
  | ['otp_email_session_replaced_android']

  // Magic Link is not valid and user does not have session
  | ['magic_link_invalid_unauthorized_web']
  | ['magic_link_invalid_unauthorized_ios']
  | ['magic_link_invalid_unauthorized_android']

  // Magic Link is not valid and user has session
  | ['magic_link_invalid_authorized_web']
  | ['magic_link_invalid_authorized_ios']
  | ['magic_link_invalid_authorized_android']

  // Session refresh state
  | ['auth_session_refreshed']
  | ['auth_session_refresh_failed']
  | ['auth_session_refresh_token_expired']
  | ['auth_session_refresh_token_invalid']
  // endregion

  // TODO: Utilize when flow is implemented
  | ['pay_at_pump_enter_pin']
  | ['native_app_opened']
  | ['display_category_clicked', { displayCategoryId: string }]
  | ['sign_in_page_phone_number_submitted']
  | ['offer_clicked', { offerId: string }]
  | ['profile_updated']
  | ['profile_completed']
  | ['sms_opt_in_toggled', { smsOptIn: boolean }]
  | ['add_card_modal_opened']
  | ['pay_at_pump_clicked']
  | ['scan_for_pay_inside_clicked']

  // Age Verification
  | ['age_verification_submitted']
  | ['age_verification_failed']
  | ['age_verification_abandoned']
  | ['age_verification_completed']
  | ['age_verification_additional_prompt_required']

  // Identity Verification
  | ['identity_verification_start_submitted']
  | ['identity_verification_start_failed']
  | ['identity_verification_start_completed']
  | ['identity_verification_start_additional_prompt_required']
  | ['identity_verification_submitted']
  | ['identity_verification_additional_questions_requested']
  | ['identity_verification_failed']
  | ['identity_verification_abandoned']
  | ['identity_verification_completed']

  // Push Notifications
  | ['push_notification_received']
  | ['push_notification_tapped']
  | ['push_notification_link_opened']
  | ['push_notification_link_error']

  // SMS Opt IN
  | ['sms_opt_in_accepted']
  | ['sms_opt_in_rejected']
  | ['sms_opt_in_offer_available']
  | ['sms_opt_in_modal_appeared'];

export enum OfferActivateStartType {
  Redeem = 0,
  Activate = 1,
}

export type RudderStackUserTraits = {
  customerUuid: string;
  membershipId: string;
  points: number;
  memberState: MEMBERSHIP_STATE;
  platform: string;
  loyaltyBrand: string;
  homeStore: string;
};

export type AnalyticsInstance = {
  triggerEvent(...args: Event): void;
  triggerLocation(path?: string): void;
  identify(userId: string, userIdentity: RudderStackUserTraits): void;
  identifyAudiences(userId: string, audiences: string[]): void;
  init(): void;
  reset(): void;
};

export type RudderStackPageOptions = {
  path: string;
  tab_url: string;
  url: string;
};

export type MaskingRule = {
  pattern: RegExp;
  replacement: string;
};
