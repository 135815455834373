import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'spinWheelGame:title': {
    id: 'spinWheelGame:title',
    defaultMessage: 'SPIN TO WIN!',
    description: 'Game view title',
  },
  'spinWheelGame:play-button:text': {
    id: 'spinWheelGame:play-button:text',
    defaultMessage: 'Spin The Wheel!',
    description: 'Game view play button text',
  },
  'spinWheelGame:purchase-gameplay-button:text': {
    id: 'spinWheelGame:purchase-gameplay-button:text',
    defaultMessage: 'Purchase Gameplay',
    description: 'Game view purchase button text',
  },
  'spinWheelGame:wheel-spin': {
    id: 'spinWheelGame:wheel-spin',
    defaultMessage: 'SPIN!',
    description: 'Wheel sector SPIN label',
  },
  'spinWheelGame:wheel-no-lock': {
    id: 'spinWheelGame:wheel-no-lock',
    defaultMessage: 'NO LUCK',
    description: 'Wheel sector NO LUCK label',
  },
  'spinWheelGame:win-popup-title': {
    id: 'spinWheelGame:win-popup-title',
    defaultMessage: 'Amazing! You won!',
    description: 'Win popup title',
  },
  'spinWheelGame:win-popup-description': {
    id: 'spinWheelGame:win-popup-description',
    defaultMessage:
      'Redeem your prize at any {vendor} Rewards location by typing in your phone number at the cash register!',
    description: 'Win popup description',
  },
  'spinWheelGame:win-popup-button': {
    id: 'spinWheelGame:win-popup-button',
    defaultMessage: 'Awesome!',
    description: 'Win popup button label',
  },
  'spinWheelGame:try-again-popup-title': {
    id: 'spinWheelGame:try-again-popup-title',
    defaultMessage: "You've got another chance!",
    description: 'Try again popup title',
  },
  'spinWheelGame:try-again-popup-description': {
    id: 'spinWheelGame:try-again-popup-description',
    defaultMessage: "Spin the wheel again to see what you'll win next!",
    description: 'Try again popup description',
  },
  'spinWheelGame:try-again-popup-button': {
    id: 'spinWheelGame:try-again-popup-button',
    defaultMessage: 'Awesome!',
    description: 'Try again popup button label',
  },
  'spinWheelGame:no-awards-popup-title': {
    id: 'spinWheelGame:no-awards-popup-title',
    defaultMessage: 'No luck this time',
    description: 'No luck popup title',
  },
  'spinWheelGame:no-awards-popup-description': {
    id: 'spinWheelGame:no-awards-popup-description',
    defaultMessage: 'You missed this time, but keep spinning for your chance to win big!',
    description: 'No luck popup description',
  },
  'spinWheelGame:no-awards-popup-button': {
    id: 'spinWheelGame:no-awards-popup-button',
    defaultMessage: 'Got it',
    description: 'No luck popup button label',
  },
  'spinWheelGame:generic-error-popup-title': {
    id: 'spinWheelGame:generic-error-popup-title',
    defaultMessage: 'Something went wrong',
    description: 'Generic error popup title',
  },
  'spinWheelGame:generic-error-popup-description': {
    id: 'spinWheelGame:generic-error-popup-description',
    defaultMessage: 'Please try and spin the wheel again.',
    description: 'Generic error popup description',
  },
});
