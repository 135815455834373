import React from 'react';
import { Stack, Typography } from '@mui/material';

import { IReceiptOption } from '../../../../modules/transaction/types';

export type OptionItemProps = {
  option: IReceiptOption;
};

export const OptionItem: React.FC<OptionItemProps> = ({ option }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="body1">{option.title}</Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        {option.icon}
        {option.value && (
          <Typography variant="body1" color="textSecondary">
            {option.value}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
