import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button, Stack, Typography } from '@mui/material';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { SelectOtpChannelActionSheetProps } from './select-otp-channel-action-sheet.types';
import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { messages } from './select-otp-channel-action-sheet.messages';
import { OtpChannel } from '../../../graphql/types.generated';
import { SmsIcon } from '../../Icons/SmsIcon';
import { PhoneIconContained } from '../../Icons/PhoneIconContained';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';

const OTP_CHANNEL_BUTTONS: Record<OtpChannel, { text: MessageDescriptor; icon?: React.ReactNode }> = {
  [OtpChannel.Sms]: {
    text: messages['select-otp-channel-action-sheet.channel-button-text.sms'],
    icon: <SmsIcon />,
  },
  [OtpChannel.PhoneCall]: {
    text: messages['select-otp-channel-action-sheet.channel-button-text.phone-call'],
    icon: <PhoneIconContained />,
  },
  // Added for type safety, EMAIL should not be enabled
  [OtpChannel.Email]: {
    text: messages['select-otp-channel-action-sheet.channel-button-text.email'],
  },
};

export const SelectOtpChannelActionSheetController = (props: SelectOtpChannelActionSheetProps) => {
  const { channels, onSelected, onCancel } = props;

  const { visible, hide, remove } = useModal();

  const handleClose = useCallback(() => {
    onCancel();
    hide();
  }, [hide, onCancel]);

  const handleSelect = useCallback(
    (channel: OtpChannel) => {
      onSelected(channel);
      hide();
    },
    [hide, onSelected],
  );

  return (
    <ModalDrawer open={visible} onClose={handleClose} onExited={remove}>
      <Stack
        spacing={SizingMultiplier.md}
        p={SizingMultiplier.lg}
        pb={(theme) => `calc(${theme.spacing(SizingMultiplier.lg)} + var(--ion-safe-area-bottom, 0px))`}>
        <Stack spacing={SizingMultiplier.sm}>
          <Typography variant="h5" component="h1">
            <FormattedMessage {...messages['select-otp-channel-action-sheet.title']} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages['select-otp-channel-action-sheet.description']} />
          </Typography>
        </Stack>
        {channels.map((channel) => (
          <Button
            color="primary"
            variant="contained"
            key={channel}
            onClick={() => handleSelect(channel)}
            endIcon={OTP_CHANNEL_BUTTONS[channel].icon}>
            <FormattedMessage {...OTP_CHANNEL_BUTTONS[channel].text} />
          </Button>
        ))}
        <Button onClick={handleClose}>
          <FormattedMessage {...buttonTextMessages['button-text:cancel']} />
        </Button>
      </Stack>
    </ModalDrawer>
  );
};
