import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { Capacitor } from '@capacitor/core';

import { EnvConfiguration } from '../../configuration/EnvConfiguration';
import { tracingMiddleware } from './tracingMiddleware';
import { authService } from '../../auth/instances/authService';
import { apolloCacheTypePolicies } from '../apollo-cache-type-policies';
import AuthMiddleware from '../AuthMiddleware';
import { globalErrorHandlerMiddleware } from './error-handler-middleware';
import introspectionResult from '../../../graphql/introspection-result.generated.json';

const httpLink = createHttpLink({
  uri: EnvConfiguration.api.url,
  headers: {
    'x-api-key': EnvConfiguration.api.key,
  },
});

const authMiddleware = new AuthMiddleware();
const link = ApolloLink.from([tracingMiddleware, globalErrorHandlerMiddleware, authMiddleware, httpLink]);

const cache = new InMemoryCache({
  typePolicies: apolloCacheTypePolicies,
  possibleTypes: introspectionResult.possibleTypes,
});

// TODO: fix it. It should not export instance
export const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
  },
  name: `ocx_${Capacitor.getPlatform()}`,
  version: EnvConfiguration.release,
});

authMiddleware.injectAuthService(authService);
authService.injectNetworkClient(apolloClient);
