import React from 'react';
import { Stack } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

type ListItemProps = {
  children?: React.ReactNode;
};

export const ListItem: React.FC<ListItemProps> = (props) => {
  const { children } = props;
  return (
    <Stack direction="row" alignItems="center" p={SizingMultiplier.md} minHeight={56}>
      {children}
    </Stack>
  );
};
