import { useMemo } from 'react';

import { Offer } from '../../../../modules/offers/offer.types';
import { useDisplayCategories } from '../../../../modules/loyalty/useDisplayCategories';
import { groupOfferAndCategories } from './offers-carousel.utils';
import { createExternalOffersQueryVariables } from '../../../../modules/external-loyalty/external-offers.utils';
import { useGetExternalOffersQuery } from '../../../../graphql/types.generated';

export const useOffersCarouselCategories = (offers: Offer[]) => {
  const { displayCategories: categories } = useDisplayCategories();

  const variables = useMemo(() => createExternalOffersQueryVariables(categories), [categories]);
  const { data: externalBanners } = useGetExternalOffersQuery({
    skip: !variables?.filter.bannerConfigurationUuidIncludes.length,
    variables,
  });

  return useMemo(
    () => groupOfferAndCategories(categories, offers, externalBanners?.banners),
    [offers, categories, externalBanners?.banners],
  );
};
