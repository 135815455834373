import React from 'react';
import { Box, Typography } from '@mui/material';

import { SizingMultiplier } from '../../../../../theme/constants';

type BaseMechanicListItemProps = {
  imageSrc?: string | null;
  title: string;
  description?: string | null;
};

export const BaseMechanicListItem: React.FC<BaseMechanicListItemProps> = (props) => {
  const { imageSrc, title, description } = props;

  return (
    <Box display="flex" alignItems="center">
      {imageSrc && (
        <Box
          component="img"
          src={imageSrc}
          alt={title}
          sx={{
            display: 'block',
            flexShrink: 0,
            width: 56,
            height: 56,
            objectFit: 'cover',
            borderRadius: 8,
            mr: SizingMultiplier.sm,
          }}
        />
      )}
      <Box flex={1} display="flex" flexDirection="column">
        {title && (
          <Typography gutterBottom variant="subtitle1">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="caption" color="textSecondary" component="p">
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
