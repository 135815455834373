import { useModal } from '@ebay/nice-modal-react';
import { useCallback } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { AwardVouchersSurvey } from '../../../modules/survey/survey.types';
import { SizingMultiplier } from '../../../theme/constants';
import { surveyAwardMessages } from './survey-award-messages';
import { ModalDrawer } from '../components';
import { RoundedBorderStack } from '../../RoundedBorderStack/RoundedBorderStack';

export interface SurveyAwardPopupProps {
  offer: AwardVouchersSurvey['offer'];
  isEarned: boolean;
  onClose: () => void;
}

export const SurveyAwardPopup = ({ offer: { title, imageUrl }, isEarned, onClose }: SurveyAwardPopupProps) => {
  const { hide, visible, remove } = useModal();
  const { formatMessage } = useIntl();
  const handleClose = useCallback(() => {
    hide();
    onClose();
  }, [hide, onClose]);

  return (
    <ModalDrawer open={visible} onClose={handleClose} onExited={remove}>
      <Stack spacing={SizingMultiplier.lg} p={SizingMultiplier.lg} paddingBottom={SizingMultiplier.lg * 2}>
        <Stack spacing={SizingMultiplier.xs}>
          <Typography variant="h5" component="h1">
            {formatMessage(
              isEarned
                ? surveyAwardMessages['survey:vouchered-award-earned:popup-title-text']
                : surveyAwardMessages['survey:vouchered-award-not-earned:popup-title-text'],
            )}
          </Typography>

          <Typography variant="body2">
            {formatMessage(
              isEarned
                ? surveyAwardMessages['survey:vouchered-award-earned:popup-description-text']
                : surveyAwardMessages['survey:vouchered-award-not-earned:popup-description-text'],
            )}
          </Typography>
        </Stack>

        <Stack spacing={SizingMultiplier.lg}>
          {title && (
            <RoundedBorderStack
              direction="row"
              spacing={SizingMultiplier.md}
              alignItems="center"
              paddingX={1.5}
              paddingY={imageUrl ? 1.5 : SizingMultiplier.xl}>
              {imageUrl && (
                <Box
                  src={imageUrl}
                  alt="Survey award"
                  component="img"
                  sx={{
                    width: 72,
                    height: 72,
                    borderRadius: 2,
                    flexShrink: 0,
                    objectFit: 'cover',
                    overflow: 'hidden',
                  }}
                />
              )}

              <Typography variant="subtitle1">{title}</Typography>
            </RoundedBorderStack>
          )}
          <Button onClick={handleClose} color="primary" variant="contained">
            {formatMessage(surveyAwardMessages['survey:vouchered-award:popup-close-button-text'])}
          </Button>
        </Stack>
      </Stack>
    </ModalDrawer>
  );
};
