import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import { ModalDrawer, ModalFullScreenDrawer, ModalFullScreenHeader } from '../../components/modals';
import { PointsStrategy } from '../../graphql/types.generated';
import { PointsChange } from '../../components/PointsChange/PointsChange';
import { BackButtonComponent } from '../../components/Layout/NavigationBar/BackButton/BackButtonComponent';

type OfferDetailsLayoutProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  onExited(): void;
};

export const OfferDetailsLayout = (props: OfferDetailsLayoutProps) => {
  const { children, isOpen, onClose, onExited } = props;
  return (
    <ModalDrawer open={isOpen} onClose={onClose} onExited={onExited}>
      {children}
    </ModalDrawer>
  );
};

type OfferDetailsFullScreenLayoutProps = OfferDetailsLayoutProps & {
  pointsStrategy: PointsStrategy;
  membershipPoints: number;
};

const OfferDetailsFullScreenContentWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.appBar.background,
  paddingTop: `calc(var(--ion-safe-area-top, 0px) + ${theme.mixins.toolbar.minHeight}px)`,
  height: '100vh',
  overflowY: 'auto',
  overscrollBehavior: 'contain',
  alignSelf: 'stretch',
}));

const OfferFullScreenContentWrapper: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={(theme) => ({
      position: 'relative',
      width: '100%',
      height: '100vh',
      overflowY: 'auto',
      alignSelf: 'stretch',
      background: theme.palette.appBar.background,
    })}>
    {children}
  </Box>
);

export const OfferDetailsFullScreenLayout = (props: OfferDetailsFullScreenLayoutProps) => {
  const { children, isOpen, onClose, onExited, pointsStrategy, membershipPoints } = props;

  const headerPoints = useMemo(() => {
    if (pointsStrategy === PointsStrategy.NotUsed) {
      return undefined;
    }
    return (
      <Box p={1.5}>
        <PointsChange points={membershipPoints} position="right" allowPlus={false} pointsStrategy={pointsStrategy} />
      </Box>
    );
  }, [membershipPoints, pointsStrategy]);

  return (
    <ModalFullScreenDrawer open={isOpen} onClose={onClose} onExited={onExited}>
      <ModalFullScreenHeader onBackClick={onClose} rightSlot={headerPoints} />
      <OfferDetailsFullScreenContentWrapper>{children}</OfferDetailsFullScreenContentWrapper>
    </ModalFullScreenDrawer>
  );
};

export const OfferFullScreenLayout = (props: OfferDetailsLayoutProps) => {
  const { children, isOpen, onClose, onExited } = props;

  return (
    <ModalFullScreenDrawer open={isOpen} onClose={onClose} onExited={onExited}>
      <OfferFullScreenContentWrapper>
        <ModalFullScreenHeader
          centerSlot={null}
          rightSlot={<BackButtonComponent onClick={onClose} type="close" />}
          position="absolute"
          transparent
          isElevationDisabled
        />
        {children}
      </OfferFullScreenContentWrapper>
    </ModalFullScreenDrawer>
  );
};

export const useOfferDetailsLayout = (params: { returnPath: string }) => {
  const { returnPath } = params;
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleExited = useCallback(() => {
    if (location.state?.shouldHistoryPopOnClose) {
      navigate(returnPath, { replace: true, state: { skipPageTracking: true } });
      navigate(-1);
      return;
    }
    navigate(returnPath, { replace: true });
  }, [location.state?.shouldHistoryPopOnClose, navigate, returnPath]);
  return { isOpen, handleClose, handleExited };
};
