import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { sentryInit } from './modules/sentry/sentry-init';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
sentryInit();
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
