import React from 'react';
import { AppBarProps } from '@mui/material';

import { BackButtonComponent } from '../../../Layout/NavigationBar/BackButton/BackButtonComponent';
import { VendorLogoComponent } from '../../../Layout/NavigationBar/VendorLogo/VendorLogoComponent';
import { NavigationBar } from '../../../Layout/NavigationBar/NavigationBar';

type ModalFullScreenHeaderProps = {
  onBackClick?(): void;
  isElevationDisabled?: boolean;
  rightSlot?: React.ReactElement;
  centerSlot?: React.ReactNode;
  position?: AppBarProps['position'];
  transparent?: boolean;
};

export const ModalFullScreenHeader: React.FC<ModalFullScreenHeaderProps> = (props) => {
  const { onBackClick, isElevationDisabled, rightSlot, centerSlot, position, transparent } = props;
  return (
    <NavigationBar
      isElevationDisabled={isElevationDisabled}
      position={position}
      left={onBackClick ? <BackButtonComponent onClick={onBackClick} /> : undefined}
      center={centerSlot === null ? undefined : centerSlot || <VendorLogoComponent />}
      right={rightSlot}
      color={transparent ? 'transparent' : undefined}
    />
  );
};
