import React from 'react';
import fill from 'lodash/fill';
import { Stack, styled, Typography } from '@mui/material';

import { SizingMultiplier } from '../../../../../theme/constants';
import { OfferMemberProgress } from '../../../../../modules/offers/earning-mechanism.types';

const CheckMarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor', ...rest } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} fill={color}>
      <path d="M18.7319 7.29592C18.639 7.20219 18.5284 7.1278 18.4065 7.07703C18.2846 7.02626 18.1539 7.00012 18.0219 7.00012C17.8899 7.00012 17.7592 7.02626 17.6373 7.07703C17.5155 7.1278 17.4049 7.20219 17.3119 7.29592L9.86192 14.7559L6.73192 11.6159C6.6354 11.5227 6.52146 11.4494 6.3966 11.4002C6.27175 11.351 6.13843 11.3268 6.00424 11.3292C5.87006 11.3315 5.73765 11.3602 5.61458 11.4137C5.4915 11.4672 5.38016 11.5444 5.28692 11.6409C5.19368 11.7374 5.12037 11.8514 5.07117 11.9762C5.02196 12.1011 4.99783 12.2344 5.00015 12.3686C5.00247 12.5028 5.0312 12.6352 5.0847 12.7583C5.13819 12.8813 5.2154 12.9927 5.31192 13.0859L9.15192 16.9259C9.24489 17.0196 9.35549 17.094 9.47735 17.1448C9.59921 17.1956 9.72991 17.2217 9.86192 17.2217C9.99393 17.2217 10.1246 17.1956 10.2465 17.1448C10.3684 17.094 10.479 17.0196 10.5719 16.9259L18.7319 8.76592C18.8334 8.67228 18.9144 8.55863 18.9698 8.43213C19.0252 8.30563 19.0539 8.16902 19.0539 8.03092C19.0539 7.89282 19.0252 7.75621 18.9698 7.62971C18.9144 7.50321 18.8334 7.38956 18.7319 7.29592Z" />
    </svg>
  );
};

export const PUNCHES_TEST_ID = 'offer_membership_punches';
interface IStyledPunchProps {
  isActive: boolean;
}

const StyledPunch = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<IStyledPunchProps>(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: isActive ? 'none' : `1px solid ${theme.palette.divider}`,
  borderRadius: '50%',
  width: 24,
  height: 24,
  color: isActive ? theme.palette.primary.contrastText : theme.palette.text.disabled,
  backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
}));

export const Punches = ({ required, achieved }: OfferMemberProgress) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      rowGap={SizingMultiplier.sm}
      gap={SizingMultiplier.sm}
      data-testid={PUNCHES_TEST_ID}>
      {fill(Array(required), null).map((value, index) => {
        const isActive = achieved > index;

        return (
          <StyledPunch
            // There is nothing better than index
            // eslint-disable-next-line react/no-array-index-key
            key={`${isActive ? '' : 'in'}active_${index}`}
            isActive={isActive}>
            {isActive ? (
              <CheckMarkIcon />
            ) : (
              <Typography variant="body2" color="inherit">
                {index + 1}
              </Typography>
            )}
          </StyledPunch>
        );
      })}
    </Stack>
  );
};
