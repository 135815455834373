import { useMemo } from 'react';

import { mapFragmentsToSortedOffersList } from './offer.dto';
import { OfferFragment, useOfferQuery } from '../../graphql/types.generated';
import { useMembership } from '../membership/use-membership.hook';

export const useOffer = (params: { offerId: string; limitsIncluded?: boolean }) => {
  const { offerId, limitsIncluded } = params;
  const { membership } = useMembership();
  const { data, loading } = useOfferQuery({
    variables: { offerId, offerLimitsIncluded: limitsIncluded },
    fetchPolicy: 'cache-and-network',
  });

  const offer = useMemo(() => {
    if (!data?.customer?.membership?.offers?.edges) {
      return null;
    }
    const fragments = data.customer.membership.offers.edges
      .filter((edge): edge is { node: OfferFragment } => !!edge?.node)
      .map(({ node }) => node);
    const offers = mapFragmentsToSortedOffersList({
      offers: fragments,
      membershipPoints: membership.points,
      membershipIsCompleted: membership.isCompleted,
    });
    return offers[0] || null;
  }, [data, membership.isCompleted, membership.points]);

  return { offer, loading };
};
