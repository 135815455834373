import React from 'react';

import { MapMarkerIcon } from '../../../Icons/MapMarkerIcon';
import { IconButton } from '../../../IconButton';

export interface IStoreLocatorButtonComponentProps {
  onClick: () => void;
}

export const StoreLocatorButtonComponent = (props: IStoreLocatorButtonComponentProps) => {
  const { onClick } = props;

  return (
    <IconButton onClick={onClick} color="inherit">
      <MapMarkerIcon />
    </IconButton>
  );
};
