import { v4 as uuid } from 'uuid';

import { EncryptFunc } from './fiserv-encryption.utils';
import { TokenizeCardInput } from '../../payment-provider.types';
import { FiservCreateAccountInput, FiservCreateAccountTokenRequestOptions } from './fiserv-client.types';
import { PaymentAccountType } from '../../../../../graphql/types.generated';

export const mapCardDataToAccountInput = async (
  encrypt: EncryptFunc,
  card: TokenizeCardInput,
): Promise<FiservCreateAccountInput> => {
  if (card.accountType === PaymentAccountType.Credit) {
    const [cardNumber, month, year, securityCode] = await Promise.all([
      encrypt(card.cardNumber),
      encrypt(card.expirationDateMonth),
      // Fiserv tokenization works fine with YY format only
      encrypt(card.expirationDateYear.slice(-2)),
      encrypt(card.cvv),
    ]);

    return {
      type: 'CREDIT',
      credit: {
        cardNumber,
        securityCode,
        expiryDate: { month, year },
        cardType: card.cardType,
        billingAddress: {
          postalCode: card.zipCode,
        },
        nameOnCard: card.cardholderName,
      },
    };
  }

  if (card.accountType === PaymentAccountType.Prepaid) {
    const [cardNumber, securityCode] = await Promise.all([encrypt(card.cardNumber), encrypt(card.securityCode)]);
    return {
      type: 'PREPAID',
      prepaid: {
        cardNumber: `ENC_[${cardNumber}]`,
        securityCode: `ENC_[${securityCode}]`,
        alias: card.alias,
      },
    };
  }

  throw new Error(`[Fiserv]: Unsupported account type is provided`);
};

/**
 * Submits an HTTP request to the `/v1/account-tokens` endpoint of Fiserv's API, using the specified base URL, request
 * body, API Key and oAuth Token.
 *
 */
export const createToken = ({ baseUrl, body, apiKey, oAuthToken }: FiservCreateAccountTokenRequestOptions) =>
  fetch(`${baseUrl}/v1/account-tokens`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Api-Key': apiKey,
      Authorization: `Bearer ${oAuthToken}`,
      'Client-Request-Id': uuid(),
      'Content-Type': 'application/json',
      Timestamp: Date.now().toString(),
    },
  });
