import React from 'react';
import { Stack, Box } from '@mui/material';

import { PoweredByLogo } from '../../Icons/PoweredByLogo';
import { SizingMultiplier } from '../../../theme/constants';

export const PoweredByOpenCommerce = () => {
  return (
    <Box mt="auto">
      <Stack alignItems="center" color="text.disabled" m={SizingMultiplier.xl}>
        <PoweredByLogo />
      </Stack>
    </Box>
  );
};
