import { isApolloError } from '@apollo/client';

import { GLOBAL_ERRORS } from './global-error-handler.constants';
import { GlobalErrorCode } from './global-error-handler.types';

export const isErrorHandledGlobally = (error: unknown) => {
  if (!(error instanceof Error)) {
    return false;
  }

  if (isApolloError(error)) {
    return !!error.graphQLErrors.find((gqlError) => GLOBAL_ERRORS.includes(gqlError.extensions?.code));
  }

  return false;
};

export const isGlobalErrorCode = (errorCode: string): errorCode is GlobalErrorCode => {
  return GLOBAL_ERRORS.includes(errorCode as GlobalErrorCode);
};
