import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ReadyToUse } from './ReadyToUse';
import { ReadyToUseLayout } from './ReadyToUseLayout';
import { ScreenLoaderBackdrop } from '../../components/ScreenLoader/ScreenLoader';
import { isReadyToUseOffer } from '../../modules/offers/offer.type-guards';
import { useOffers } from '../../modules/offers/use-offers.hook';
import { OfferDetailsController } from '../OfferDetails/offer-details.controller';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { AppRoutePath } from '../AppRoutePath';

export const ReadyToUseController: React.FC = () => {
  const { offers: membershipOffers, loading: isOffersLoading } = useOffers();
  const { pushToOfferDetails, pushToHome } = useGetNavigationActions();

  const offers = useMemo(() => membershipOffers.filter(isReadyToUseOffer) || [], [membershipOffers]);

  const isLoading = isOffersLoading;

  return (
    <ReadyToUseLayout onBackButtonClick={pushToHome}>
      <ScreenLoaderBackdrop open={isLoading} />
      <ReadyToUse offers={offers} getOfferOnClick={(offer) => () => pushToOfferDetails(offer.id)} loading={isLoading} />
      <Routes>
        <Route
          path={AppRoutePath.OfferDetails}
          element={<OfferDetailsController returnPath={AppRoutePath.ReadyToUseOffers} />}
        />
      </Routes>
    </ReadyToUseLayout>
  );
};

export default ReadyToUseController;
