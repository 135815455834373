import { OfferEngagementType } from '../../../graphql/types.generated';
import { SpinWheelGameOffer } from '../../../modules/offers/offer.types';
import { SpinWheelFreeGameController } from './SpinWheelFreeGame';
import { SpinWheelVoucheredGameController } from './SpinWheelVoucheredGame';

export type SpinWheelGameControllerProps = {
  offer: SpinWheelGameOffer;
  onLastGameplayEnd(): void;
};

export const SpinWheelGameController = (props: SpinWheelGameControllerProps) => {
  const { offer, onLastGameplayEnd } = props;

  if (offer.engagementType === OfferEngagementType.GameVoucher) {
    return <SpinWheelVoucheredGameController offer={offer} onLastGameplayEnd={onLastGameplayEnd} />;
  }

  if (offer.engagementType === OfferEngagementType.Game) {
    return <SpinWheelFreeGameController offer={offer} onLastGameplayEnd={onLastGameplayEnd} />;
  }

  return null;
};
