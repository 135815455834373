import { Capacitor } from '@capacitor/core';

import {
  IPaymentProvider,
  TokenizeApplePayResponse,
  TokenizeCardInput,
  TokenizeCardResponse,
} from '../../payment-provider.types';
import { EProtect } from './eProtect';
import { getPaymentInstrumentTypeByCardType } from '../../../payments.utils';
import {
  EProtectReportGroup,
  type IPaymentProviderWorldpayConstructorParams,
  type TokenizeWorldPayRequestData,
} from './worldpay.types';
import { ApplePayPaymentToken } from '../../../ApplePay/types';
import { getRandomEProtectId } from './worldpay.utils';
import { PaymentData } from '../../../GooglePay/types';
import { PaymentAccountType } from '../../../../../graphql/types.generated';

export class PaymentProviderWorldpay implements IPaymentProvider {
  private googlePayPageId: string;

  private eProtectReportGroup: EProtectReportGroup = Capacitor.isNativePlatform()
    ? EProtectReportGroup.NATIVE
    : EProtectReportGroup.WEB;

  constructor(params: IPaymentProviderWorldpayConstructorParams) {
    EProtect.setOptions({
      paypageId: params.payPageId,
      sdkApiUrl: params.sdkApiUrl,
      sdkUrl: params.sdkUrl,
    });
    this.googlePayPageId = params.googlePayPageId;
  }

  tokenizeCard = async (params: TokenizeCardInput): Promise<TokenizeCardResponse> => {
    if (params.accountType === PaymentAccountType.Prepaid) {
      throw new Error('[Worldpay] Account type PREPAID is not supported');
    }
    const response = await EProtect.tokenize({
      cardNumber: params.cardNumber,
      cvv: params.cvv,
      reportGroup: this.eProtectReportGroup,
    });
    const externalRequestData: TokenizeWorldPayRequestData = {
      id: response?.id,
      responseTime: response?.responseTime,
      orderId: response?.orderId,
      type: response?.type,
      vantivTxnId: response?.vantivTxnId,
    };
    return {
      accountType: params.accountType,
      nonce: response.paypageRegistrationId,
      paymentInstrumentType: getPaymentInstrumentTypeByCardType(params.cardType),
      oAuthToken: null, // Not needed, Fiserv field only
      externalRequestData,
    };
  };

  tokenizeApplePay = async (data: ApplePayPaymentToken['paymentData']): Promise<TokenizeApplePayResponse> => {
    const { paypageRegistrationId } = await EProtect.tokenizeApplePay(data, {
      reportGroup: this.eProtectReportGroup,
    });
    return {
      nonce: paypageRegistrationId,
    };
  };

  getGooglePayGatewayParameters = () => {
    return {
      gateway: 'vantiv',
      'vantiv:merchantPayPageId': this.googlePayPageId,
      'vantiv:merchantOrderId': getRandomEProtectId(),
      'vantiv:merchantTransactionId': getRandomEProtectId(),
      'vantiv:merchantReportGroup': this.eProtectReportGroup,
    };
  };

  /**
   * Based on eProtect integration guide - "low value token" is "token" field in GooglePay PaymentDate
   * Page 64, section 2.3.5, "Using the Worldpay Mobile API for Google Pay"
   * Doc: https://developer.worldpay.com/assets/pdf/us-ecom-pdf/Worldpay_eProtect_Integration_Guide_V4.16.pdf
   */
  getGooglePayTokenNonce = (data: PaymentData) => data.paymentMethodData.tokenizationData.token;
}
