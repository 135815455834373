import React from 'react';
import { AppBar, AppBarProps, Box, Toolbar } from '@mui/material';

import { SizingMultiplier } from '../../../theme/constants';

export interface INavigationBarProps {
  isElevationDisabled?: boolean;
  position?: AppBarProps['position'];
  left?: React.ReactElement;
  center?: React.ReactNode;
  right?: React.ReactElement;
  color?: 'default' | 'transparent';
}

export const NavigationBar: React.FC<INavigationBarProps> = (props) => {
  const { isElevationDisabled = false, position = 'static', left, center, right, color } = props;

  // TODO: Paper elevation is not visible because of content bg color. Refactor or get rid of elevation props at all
  return (
    <AppBar
      position={position}
      elevation={isElevationDisabled ? 0 : undefined}
      sx={{ pt: 'var(--ion-safe-area-top, 0)' }}
      color={color}>
      <Toolbar sx={{ px: SizingMultiplier.xs, justifyContent: 'space-between' }}>
        <Box width={56}>{left}</Box>
        {center}
        <Box width={56} display="flex" justifyContent="flex-end">
          {right}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
