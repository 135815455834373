import { Stack, ButtonBase, Typography, styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';

import { messages } from '../messages';
import readyToUseRewardsIcon from '../../../@active-configuration/assets/ready-to-use-rewards.svg';
import totalAvailableRollbackIcon from '../../../@active-configuration/assets/total-available-rollback.svg';
import { useGetNavigationActions } from '../../../hooks/useGetNavigationActions';
import { SizingMultiplier } from '../../../theme/constants';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { formatPrice } from '../../../lib/formatters/formatPrice';

const StyledButtonBase = styled(ButtonBase)(({ theme: { palette, shape, spacing } }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: palette.background.paper,
  borderRadius: shape.borderRadius * 4,
  padding: spacing(SizingMultiplier.sm, 0, SizingMultiplier.sm, SizingMultiplier.md),
}));

export type RewardsControllerProps = {
  rewardsQuantity: number;
  totalAvailableRollbackMoneyAmount?: number;
};

export const RewardsController = ({ rewardsQuantity, totalAvailableRollbackMoneyAmount }: RewardsControllerProps) => {
  const intl = useIntl();
  const { config } = useConfiguration();
  const { pushToReadyToUseOffers, pushToTotalAvailableRollbackOffers } = useGetNavigationActions();
  const { triggerEvent } = useRudderStack();

  const handleReadyToUseClick = useCallback(() => {
    triggerEvent('ready_to_use_clicked');
    pushToReadyToUseOffers();
  }, [pushToReadyToUseOffers, triggerEvent]);

  const moneyAmount = formatPrice(totalAvailableRollbackMoneyAmount || 0, {
    displayCentsWhenLessThanDollar: true,
  });
  const showTotalAvailableRollback = config.totalAvailableRollback.enabled && totalAvailableRollbackMoneyAmount;

  if (!rewardsQuantity && !showTotalAvailableRollback) {
    return null;
  }

  return (
    <Stack direction="row" spacing={SizingMultiplier.sm} useFlexGap>
      {showTotalAvailableRollback ? (
        <StyledButtonBase onClick={pushToTotalAvailableRollbackOffers}>
          <Typography variant="subtitle1">
            {rewardsQuantity
              ? intl.formatMessage(messages['home:rewards:totalAvailableRollback'], { moneyAmount })
              : intl.formatMessage(messages['home:rewards:totalAvailableRollbackFull'], { moneyAmount })}
          </Typography>
          <img src={totalAvailableRollbackIcon} alt="rewards icon" width={56} height={48} />
        </StyledButtonBase>
      ) : null}
      {rewardsQuantity ? (
        <StyledButtonBase onClick={handleReadyToUseClick}>
          <Typography variant="subtitle1">
            {showTotalAvailableRollback
              ? intl.formatMessage(messages['home:rewards:readyToUseRewards'], { rewardsQuantity })
              : intl.formatMessage(messages['home:rewards:readyToUseRewardsFull'], { rewardsQuantity })}
          </Typography>
          <img src={readyToUseRewardsIcon} alt="rewards icon" width={56} height={48} />
        </StyledButtonBase>
      ) : null}
    </Stack>
  );
};
