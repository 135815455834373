import { useCallback } from 'react';

import { GetDisplayCategoriesQuery } from '../../graphql/types.generated';
import { cacheStorage } from '../cache-layer/cacheStorage';

export const DISPLAY_CATEGORIES_CACHE_KEY = 'display_categories';

const serializeDisplayCategoriesQuery = (query: GetDisplayCategoriesQuery) => {
  return query;
};

export const useCacheDisplayCategories = () => {
  return useCallback(
    (query: GetDisplayCategoriesQuery) =>
      cacheStorage.setItem(DISPLAY_CATEGORIES_CACHE_KEY, serializeDisplayCategoriesQuery(query)),
    [],
  );
};
