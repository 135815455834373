import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Paper } from '@mui/material';
import AccountBalanceWalletOutlined from '@mui/icons-material/AccountBalanceWalletOutlined';
import NotificationsActiveOutlined from '@mui/icons-material/NotificationsActiveOutlined';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import LocalMallOutlined from '@mui/icons-material/LocalMallOutlined';
import LocalGasStationOutlined from '@mui/icons-material/LocalGasStationOutlined';

import { List, ListItemText, ListItemButton, ListItemStartIcon, ListItemNavLink } from '../../../components/List_v2';
import { messages } from '../messages';
import { AppRoutePath } from '../../AppRoutePath';

interface LoyaltyDetailsListProps {
  isWalletEnabled: boolean;
  isCommunicationPreferenceEnabled: boolean;
  isProductInterestsEnabled: boolean;
  isPreferredFuelGradeEnabled: boolean;
  handleCommunicationPreferencesClick(): void;
}

export const LoyaltyDetailsList: React.FC<LoyaltyDetailsListProps> = (props) => {
  const {
    isWalletEnabled,
    isCommunicationPreferenceEnabled,
    isProductInterestsEnabled,
    isPreferredFuelGradeEnabled,
    handleCommunicationPreferencesClick,
  } = props;

  return (
    <Paper elevation={0} sx={{ overflow: 'hidden' }}>
      <List>
        {isCommunicationPreferenceEnabled && (
          <ListItemButton onClick={handleCommunicationPreferencesClick}>
            <ListItemStartIcon>
              <NotificationsActiveOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:communicationPreference']} />
            </ListItemText>
          </ListItemButton>
        )}

        {isProductInterestsEnabled && (
          <ListItemNavLink to={AppRoutePath.ProductInterests}>
            <ListItemStartIcon>
              <LocalMallOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:productInterests']} />
            </ListItemText>
          </ListItemNavLink>
        )}

        <ListItemNavLink to={AppRoutePath.TransactionHistory}>
          <ListItemStartIcon>
            <ReceiptLongOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:receiptsButtonTitle']} />
          </ListItemText>
        </ListItemNavLink>

        {isWalletEnabled && (
          <ListItemNavLink to={AppRoutePath.Wallet}>
            <ListItemStartIcon>
              <AccountBalanceWalletOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:walletButtonTitle']} />
            </ListItemText>
          </ListItemNavLink>
        )}

        {isPreferredFuelGradeEnabled && (
          <ListItemNavLink to={AppRoutePath.PreferredFuelGrade}>
            <ListItemStartIcon>
              <LocalGasStationOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:preferredFuelGradeButtonTitle']} />
            </ListItemText>
          </ListItemNavLink>
        )}
      </List>
    </Paper>
  );
};
