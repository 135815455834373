import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useRudderStack } from '../../../../lib/rudderStack/useRudderStack';
import { messages } from '../wallet-pin-code-popup.messages';
import { useShowGenericErrorSnackbar } from '../../../../hooks/useShowGenericErrorSnackbar';
import { PinCodeErrorCode, useGetErrorMessage } from './use-get-error-message';
import { useConfirmationActionSheet } from '../../ConfirmationActionSheet';
import { useCustomerResetPinCodeMutation, useCustomerVerifyPinCodeMutation } from '../../../../graphql/types.generated';
import { useInMemoryStorage } from '../../../../lib/in-memory-storage/use-in-memory-storage';
import { IM_MEMORY_PIN_CODE_STORAGE_KEY } from '../../../../modules/wallet/wallet.constants';

export type UseVerifyFlowParam = {
  onComplete(pinCode: string | null): void;
};
export const useVerifyFlow = (params: UseVerifyFlowParam) => {
  const intl = useIntl();
  const { triggerEvent } = useRudderStack();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const getErrorMessages = useGetErrorMessage();
  const { open: openConfirmationActionSheet } = useConfirmationActionSheet();

  const { onComplete } = params;
  const { eraseStorageValue: eraseCachedPinCode } = useInMemoryStorage<string>(IM_MEMORY_PIN_CODE_STORAGE_KEY);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [verifyCustomerPinCode, { loading: verifyCustomerPinCodeLading }] = useCustomerVerifyPinCodeMutation();
  const [resetCustomerPinCode, { loading: resetCustomerPinCodeLoading }] = useCustomerResetPinCodeMutation({
    refetchQueries: ['currentCustomer', 'getPaymentInstruments'], // currentCustomer is required to have updated hasPasscode value
  });

  const handleSubmit = useCallback(
    async (value: string) => {
      try {
        const isVerified = await verifyCustomerPinCode({ variables: { pinCode: value } });

        if (isVerified.data?.success) {
          onComplete(value); // Resole modal with pn code value and hide modal
          triggerEvent('verify_pin');
        } else {
          setValidationError(getErrorMessages(PinCodeErrorCode.OC_PINCODE_VALIDATE_ERROR));
        }
      } catch (e: any) {
        const errorCode = e?.graphQLErrors[0]?.extensions?.code;
        setValidationError(getErrorMessages(errorCode));
      }
    },
    [getErrorMessages, onComplete, triggerEvent, verifyCustomerPinCode],
  );

  const confirmResetPin = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      openConfirmationActionSheet({
        title: intl.formatMessage(messages.title),
        description: intl.formatMessage(messages.text),
        confirmButtonText: intl.formatMessage(messages.confirmButtonTitle),
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }, [intl, openConfirmationActionSheet]);

  const handleReset = useCallback(async () => {
    try {
      const isResetPinConfirmed = await confirmResetPin();
      if (!isResetPinConfirmed) {
        return;
      }
      await resetCustomerPinCode();
      eraseCachedPinCode();
      onComplete(null);
      triggerEvent('reset_pin');
    } catch (e) {
      showGenericErrorSnackbar();
    }
  }, [confirmResetPin, eraseCachedPinCode, onComplete, resetCustomerPinCode, showGenericErrorSnackbar, triggerEvent]);

  return {
    loading: verifyCustomerPinCodeLading || resetCustomerPinCodeLoading,
    handleSubmit,
    handleReset,
    validationError,
  };
};
