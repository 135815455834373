import React, { ReactNode } from 'react';

import styles from './PureBackdrop.module.css';

type PureBackdropProps = {
  children: ReactNode;
};

export const PureBackdrop: React.FC<PureBackdropProps> = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};
