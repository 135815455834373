import { DeviceType } from '../../../graphql/types.generated';
import { getDevicePlatform } from '../../../lib/device/utils/info';
import { CapacitorPlatform } from '../../../lib/device/types/types';

const mapPlatformToDeviceType: Record<CapacitorPlatform, DeviceType> = {
  ios: DeviceType.Iphone,
  android: DeviceType.Android,
  web: DeviceType.Web,
};

export const getDeviceType = () => {
  const platform = getDevicePlatform();
  return mapPlatformToDeviceType[platform];
};

export const isIosPlatform = () => getDeviceType() === DeviceType.Iphone;

export const isAndroidPlatform = () => getDeviceType() === DeviceType.Android;

// https://github.com/mui/material-ui/issues/32737#issuecomment-1347356610
export const isAppleWithTouchScreen = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
