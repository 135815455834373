import React from 'react';
import { Typography } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

type ListHeaderProps = {
  children?: React.ReactNode;
};

export const ListHeader: React.FC<ListHeaderProps> = (props) => {
  const { children } = props;
  return (
    <Typography variant="subtitle1" ml={SizingMultiplier.md}>
      {children}
    </Typography>
  );
};
