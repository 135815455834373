import { useCallback } from 'react';

import {
  useSubmitSurveyAnswerMutation,
  useSubmitGuestSurveyAnswerMutation,
  useSubmitSurveyAnswersMutation,
  useSubmitGuestSurveyAnswersMutation,
  SurveyQuestionAnswerInput,
  SurveyFieldInput,
  SurveyAnswerSubmitInput,
} from '../../graphql/types.generated';
import { QuestionUUID, SanitizedSurvey, SurveyFormValues } from './survey.types';

export type SubmitAnswerBasicParams = {
  onSuccess?(): void;
  onError?(e: Error): void;
  questionUUID: QuestionUUID;
  values: SurveyFormValues;
  type: SanitizedSurvey['type'];
  surveyTypename: SanitizedSurvey['__typename'];
};
export type SubmitAuthenticatedAnswerParams = SubmitAnswerBasicParams & { type: 'authenticated' };
export type SubmitGuestAnswerParams = SubmitAnswerBasicParams & { type: 'guest'; guestSurveyId: string };
export type SubmitAnswerParams = SubmitAuthenticatedAnswerParams | SubmitGuestAnswerParams;

export interface IUseGetSubmitAnswerReturns {
  submit(params: SubmitAnswerParams): Promise<void>;
  loading: boolean;
}

const getAnswerFieldsForWsSurvey = (params: SubmitAnswerParams): SurveyFieldInput[] => {
  const { values } = params;

  const fields: SurveyAnswerSubmitInput['fields'] = Object.entries(values).map(([uuid, value]) => {
    return {
      uuid,
      answer: String(value),
    };
  });

  return fields;
};

const getAnswersForGeneralSurvey = (params: SubmitAnswerParams): SurveyQuestionAnswerInput[] => {
  const { questionUUID, values } = params;
  const value = values[questionUUID];

  if (typeof value === 'number') {
    return [
      {
        uuid: questionUUID,
        answer: {
          slider: { value },
        },
      },
    ];
  }

  if (typeof value === 'string') {
    return [
      {
        uuid: questionUUID,
        answer: {
          singleChoice: {
            optionUuid: value,
          },
        },
      },
    ];
  }

  return [
    {
      uuid: questionUUID,
      answer: {
        multipleChoice: {
          optionUuids: value,
        },
      },
    },
  ];
};

const useSubmitAuthenticatedAnswer = () => {
  const [submitWsSurveyAnswer, { loading: isSubmitWsSurveyAnswerLoading }] = useSubmitSurveyAnswerMutation();
  const [submitSurveyAnswers, { loading: isSurveyAnswersSubmitLoading }] = useSubmitSurveyAnswersMutation();

  const submit = useCallback(
    async (params: SubmitAuthenticatedAnswerParams) => {
      const { questionUUID, surveyTypename } = params;

      if (surveyTypename === 'WalletSteeringSurvey') {
        const fields = getAnswerFieldsForWsSurvey(params);
        return submitWsSurveyAnswer({
          variables: { input: { fields, questionUuid: questionUUID } },
          refetchQueries: ['getSurveysTotalCount'],
        });
      }

      const answers = getAnswersForGeneralSurvey(params);
      return submitSurveyAnswers({
        variables: {
          input: { answers },
        },
        refetchQueries: ['getSurveysTotalCount'],
      });
    },
    [submitWsSurveyAnswer, submitSurveyAnswers],
  );

  return [submit, isSubmitWsSurveyAnswerLoading || isSurveyAnswersSubmitLoading] as const;
};

const useSubmitGuestAnswer = () => {
  const [submitGuestWsSurveyAnswer, { loading: isSubmitGuestWsSurveyAnswerLoading }] =
    useSubmitGuestSurveyAnswerMutation();
  const [submitGuestGeneralSurveyAnswers, { loading: isSubmitGuestGeneralSurveyAnswersLoading }] =
    useSubmitGuestSurveyAnswersMutation();

  const submit = useCallback(
    async (params: SubmitGuestAnswerParams) => {
      const { questionUUID, surveyTypename, guestSurveyId } = params;

      if (surveyTypename === 'WalletSteeringSurvey') {
        const fields = getAnswerFieldsForWsSurvey(params);
        await submitGuestWsSurveyAnswer({
          variables: { input: { fields, questionUuid: questionUUID, guestSurveyId } },
        });
      } else {
        const answers = getAnswersForGeneralSurvey(params);
        await submitGuestGeneralSurveyAnswers({
          variables: { input: { answers, guestSurveyId } },
        });
      }
    },
    [submitGuestGeneralSurveyAnswers, submitGuestWsSurveyAnswer],
  );

  return [submit, isSubmitGuestWsSurveyAnswerLoading || isSubmitGuestGeneralSurveyAnswersLoading] as const;
};

export const useGetSubmitAnswer = (): IUseGetSubmitAnswerReturns => {
  const [submitAuthenticatedAnswer, isSubmitAuthenticatedAnswerLoading] = useSubmitAuthenticatedAnswer();
  const [submitGuestAnswer, isSubmitGuestAnswerLoading] = useSubmitGuestAnswer();

  const submit = useCallback(
    async (params: SubmitAnswerParams) => {
      const { onSuccess, onError, questionUUID, type } = params;

      if (!questionUUID) {
        return;
      }

      try {
        if (type === 'authenticated') {
          await submitAuthenticatedAnswer(params);
        } else {
          await submitGuestAnswer(params);
        }

        onSuccess?.();
      } catch (e: any) {
        onError?.(e);
      }
    },
    [submitAuthenticatedAnswer, submitGuestAnswer],
  );
  return {
    submit,
    loading: isSubmitAuthenticatedAnswerLoading || isSubmitGuestAnswerLoading,
  };
};
