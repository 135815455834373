import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Paper } from '@mui/material';
import DoNotDisturbOnOutlined from '@mui/icons-material/DoNotDisturbOnOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';

import {
  List,
  ListHeader,
  ListItemButton,
  ListItemNavLink,
  ListItemStartIcon,
  ListItemText,
} from '../../../components/List_v2';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { messages } from '../messages';
import { useShowGenericErrorSnackbar } from '../../../hooks/useShowGenericErrorSnackbar';
import { useLogoutController } from '../../../modules/auth/useLogoutController';
import { AppRoutePath } from '../../AppRoutePath';
import { SizingMultiplier } from '../../../theme/constants';

export const AccountActionsList: React.FC = () => {
  const { config } = useConfiguration();
  const handleError = useShowGenericErrorSnackbar();
  const { logout } = useLogoutController({ onError: handleError });

  return (
    <Paper elevation={0} sx={{ overflow: 'hidden', paddingTop: SizingMultiplier.md }}>
      <ListHeader>
        <FormattedMessage {...messages['profile:accountSection:title']} />
      </ListHeader>

      <List>
        {config.deleteAccount.enabled && (
          <ListItemNavLink to={AppRoutePath.DeleteAccount}>
            <ListItemStartIcon>
              <DoNotDisturbOnOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:accountActions:deleteAccountButtonTitle']} />
            </ListItemText>
          </ListItemNavLink>
        )}
        <ListItemButton onClick={() => logout()}>
          <ListItemStartIcon>
            <LogoutOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:accountActions:signOutButtonTitle']} />
          </ListItemText>
        </ListItemButton>
      </List>
    </Paper>
  );
};
