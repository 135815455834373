import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { useShowGenericErrorSnackbar } from '../../hooks/useShowGenericErrorSnackbar';
import { useGetSurveyQuery } from '../../graphql/types.generated';
import { sanitizeSurvey } from '../../modules/survey/survey.utils';

type UseSurveyDetailsControllerParams = { surveyUuid: string; returnPath: string };

export const useSurveyDetailsController = ({ surveyUuid, returnPath }: UseSurveyDetailsControllerParams) => {
  const [isOpen, setIsOpen] = useState(true);

  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const navigate = useNavigate();

  const { data, loading } = useGetSurveyQuery({
    variables: { uuid: surveyUuid },
    skip: !surveyUuid,
    fetchPolicy: 'cache-and-network',
    onError: showGenericErrorSnackbar,
  });

  const sanitizedSurvey = data ? sanitizeSurvey(data.survey) : null;

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleExit = useCallback(() => {
    navigate(returnPath, { replace: true });
  }, [navigate, returnPath]);

  return {
    isOpen,
    handleClose,
    handleExit,
    sanitizedSurvey,
    loading,
  };
};
