import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import { IPinItemProps } from './types';

const DashPinItemComponent = styled('div', { shouldForwardProp: () => false })(({ theme }) => ({
  height: 5,
  width: 40,
  backgroundColor: theme.palette.text.disabled,
}));

export const DashPinItem: React.FC<IPinItemProps> = (props) => {
  const { value } = props;

  return (
    <Box height={48} width={40} marginX="10px" display="flex" alignItems="center" justifyContent="center">
      {value ? (
        <Typography variant="h2" align="center">
          {value}
        </Typography>
      ) : (
        <DashPinItemComponent />
      )}
    </Box>
  );
};
