import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client';
import { addBreadcrumb } from '@sentry/react';

import { AuthService } from '../auth/AuthService';
import { clearCache } from '../cache-layer/utils';
import { logInDev } from '../../lib/logger/logger';

const REFRESH_TOKEN_OPERATION_NAME = 'getAccessTokenByRefreshToken';
const SESSION_EXPIRED_EXTENSIONS = ['OC_ACCESS_TOKEN_EXPIRED_ERROR'];
const SESSION_INVALID_EXTENSIONS = ['INVALID_ACCESS_TOKEN_ERROR', 'NOT_AUTHORIZED_ERROR'];

interface Subscription {
  closed: boolean;
  unsubscribe(): void;
}

class AuthMiddleware extends ApolloLink {
  private refreshPromise?: Promise<void>;

  private authService: AuthService | null = null;

  public injectAuthService(authService: AuthService) {
    this.authService = authService;
  }

  public request = (operation: Operation, forward: NextLink) => {
    this.setAuthHeaders(operation);
    return new Observable<FetchResult>((observer) => {
      let innerSubscription: Subscription;
      const subscription = forward(operation).subscribe({
        next: async (value) => {
          const isRefreshTokenFailed = operation.operationName === REFRESH_TOKEN_OPERATION_NAME && value.errors;
          const shouldRefreshSession =
            isOperationFailedWithExtensions(value, SESSION_EXPIRED_EXTENSIONS) && !isRefreshTokenFailed;
          const shouldDestroySession = isOperationFailedWithExtensions(value, SESSION_INVALID_EXTENSIONS);

          if (shouldDestroySession) {
            addBreadcrumb({
              category: 'logger_auth',
              message: `Session should be destroyed. The error code is ${value}`,
              level: 'info',
            });
            delete this.refreshPromise;
            await this.clearSession();
          }
          if (!shouldRefreshSession) {
            observer.next(value);
            observer.complete();
            return;
          }

          try {
            addBreadcrumb({
              category: 'logger_auth',
              message: `Starting to refresh the accessToken (await this.refreshToken()).`,
              level: 'info',
            });
            await this.refreshToken();
            addBreadcrumb({
              category: 'logger_auth',
              message: `Token refreshed. Updating headers (this.setAuthHeaders(operation)).`,
              level: 'info',
            });
            this.setAuthHeaders(operation);
            addBreadcrumb({
              category: 'logger_auth',
              message: `Scheduling operation retry after token has been updated (forward(operation).subscribe(observer)).`,
              level: 'info',
            });
            innerSubscription = forward(operation).subscribe(observer);
          } catch (e) {
            addBreadcrumb({
              category: 'logger_auth',
              message: `Session refresh failed. Wiping the session (await this.clearSession()).`,
              level: 'warning',
            });
            await this.clearSession();
            observer.error(e);
          } finally {
            delete this.refreshPromise;
          }
        },
        error: observer.error.bind(observer),
        // TODO: check how zen observable flush subscription
        // complete: observer.complete.bind(observer),
      });
      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
        if (innerSubscription) {
          innerSubscription.unsubscribe();
        }
      };
    });
  };

  private async refreshToken(): Promise<void> {
    if (this.authService === null) {
      throw new Error('AuthService not specified');
    }
    if (!this.refreshPromise) {
      this.refreshPromise = this.authService.refreshTokens();
    }
    return this.refreshPromise;
  }

  private async clearSession(): Promise<void> {
    if (this.authService === null) {
      throw new Error('AuthService not specified');
    }
    await clearCache().catch(logInDev);
    return this.authService.clearSession();
  }

  public setAuthHeaders(operation: Operation) {
    if (this.authService === null) {
      throw new Error('AuthService not specified');
    }
    const accessToken = this.authService.getAccessToken();
    if (!accessToken) {
      return;
    }
    operation.setContext(({ headers = {} }) => ({ headers: { ...headers, authorization: `Bearer ${accessToken}` } }));
  }
}

const isOperationFailedWithExtensions = (result: FetchResult, extensionCodes: string[]) => {
  if (!result.errors) {
    return false;
  }
  const error = result.errors.find((error) => {
    if (error.extensions && error.extensions.code) {
      return extensionCodes.includes(error.extensions.code);
    }
    return false;
  });

  return error !== undefined;
};

export default AuthMiddleware;
