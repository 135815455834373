import React, { useMemo } from 'react';
import { Box, Container, Stack, Link, Typography } from '@mui/material';
import { Config } from 'final-form';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';

import { IProfileFormFields, ProfileFormFieldsInitialValues } from './types';
import { SimpleDatePickerController } from '../../components/SimpleDatePicker/SimpleDatePickerController';
import { TextField } from '../../components/TextField';
import { FormIconHelperText } from '../../components/Fields/FormIconHelperText/FormIconHelperText';
import { InfoIcon } from '../../components/Icons/InfoIcon';
import { HeartIcon } from '../../components/Icons/HeartIcon';
import { SizingMultiplier } from '../../theme/constants';
import { phoneNumberFormatNationalForUs } from '../../lib/phone-number';
import { LoadingButton } from '../../components/LoadingButton';
import { Button } from '../../components/Button';
import { formLabelMessages } from '../../modules/localization/form-label.messages';
import { messages } from './messages';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { CheckboxField } from '../../components/Fields/CheckboxField/CheckboxField';

const markdownOptions: MarkdownToJSX.Options = {
  overrides: {
    p: {
      component: Typography,
      props: {
        color: 'textSecondary',
        variant: 'caption',
      },
    },
    a: {
      component: Link,
      props: {
        color: 'textSecondary',
        underline: 'always',
        target: '_blank',
        rel: 'noreferrer',
      },
    },
  },
};

export interface IEditProfileProps {
  loading: boolean;
  isProfileCompleted: boolean;
  initialValues: ProfileFormFieldsInitialValues;
  updateCustomer: Config<IProfileFormFields>['onSubmit'];
  onCloseClick(): void;
  validationSchema: Config<IProfileFormFields>['validate'];
  maxPossibleYOB: number;
  minPossibleYOB: number;
  termsConditionsLink: string;
  privacyPolicyLink: string;
  isExplicitTermsAcceptanceEnabled: boolean;
}

export const EditProfile: React.FC<IEditProfileProps> = ({
  loading,
  initialValues,
  updateCustomer,
  isProfileCompleted,
  onCloseClick,
  validationSchema,
  minPossibleYOB,
  maxPossibleYOB,
  termsConditionsLink,
  privacyPolicyLink,
  isExplicitTermsAcceptanceEnabled,
}) => {
  const intl = useIntl();

  const disclaimerMarkdown = useMemo(
    () =>
      intl.formatMessage(messages.disclaimerMarkdown, {
        termsConditionsLink,
      }),
    [intl, termsConditionsLink],
  );

  const birthdateFieldDescription = useMemo(() => intl.formatMessage(messages.birthdateFieldDescription), [intl]);

  // TODO: refactor, remove Container
  return (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
      }}>
      <Typography variant="h5">
        <FormattedMessage {...(isProfileCompleted ? messages.completedProfile : messages.notCompletedProfile)} />
      </Typography>
      <Box alignItems="center" marginTop={3}>
        <Form<IProfileFormFields>
          validate={validationSchema}
          onSubmit={updateCustomer}
          // @ts-ignore initialValues
          initialValues={initialValues}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  variant="outlined"
                  name="firstName"
                  fullWidth
                  label={intl.formatMessage(formLabelMessages['form-label:first-name'])}
                  disabled={loading}
                />
                <TextField
                  variant="outlined"
                  name="lastName"
                  fullWidth
                  label={intl.formatMessage(formLabelMessages['form-label:last-name'])}
                  disabled={loading}
                />
                <Stack>
                  <TextField
                    variant="outlined"
                    name="email"
                    fullWidth
                    type="email"
                    label={intl.formatMessage(formLabelMessages['form-label:email'])}
                    disabled={loading}
                  />
                  <FormIconHelperText icon={InfoIcon} text={intl.formatMessage(messages.emailFieldDescription)} />
                </Stack>
                <TextField
                  variant="outlined"
                  name="phoneNumber"
                  fullWidth
                  disabled
                  label={intl.formatMessage(formLabelMessages['form-label:phone-number'])}
                  fieldProps={{ format: phoneNumberFormatNationalForUs }}
                />
                <Stack>
                  <Typography variant="subtitle1" color="textSecondary" paragraph>
                    <FormattedMessage {...messages.birthdateFieldLabel} />
                  </Typography>
                  <SimpleDatePickerController
                    minYear={minPossibleYOB}
                    maxYear={maxPossibleYOB}
                    fieldNamePrefix="birthdate"
                    disabled={loading}
                  />
                  {birthdateFieldDescription !== 'none' && (
                    <FormIconHelperText icon={HeartIcon} text={birthdateFieldDescription} />
                  )}
                </Stack>
                {disclaimerMarkdown !== 'none' && (
                  <Box p={SizingMultiplier.md} borderRadius={SizingMultiplier.md} bgcolor="background.box">
                    <Markdown options={markdownOptions}>{disclaimerMarkdown}</Markdown>
                  </Box>
                )}
                {isExplicitTermsAcceptanceEnabled && (
                  <CheckboxField
                    name="termsAccepted"
                    label={
                      <Markdown options={markdownOptions}>
                        {intl.formatMessage(messages.termsAcceptanceCheckboxLabel, {
                          termsConditionsLink,
                          privacyPolicyLink,
                        })}
                      </Markdown>
                    }
                    disabled={loading}
                    formControlLabelProps={{
                      sx: { '& .MuiCheckbox-root': { alignSelf: 'flex-start' } },
                    }}
                  />
                )}
                <LoadingButton
                  loading={loading}
                  disabled={props.hasValidationErrors || props.submitting || loading || !props.dirty}
                  type="submit"
                  fullWidth>
                  <FormattedMessage {...buttonTextMessages['button-text:submit']} />
                </LoadingButton>
                <Button variant="text" onClick={onCloseClick}>
                  <FormattedMessage {...buttonTextMessages['button-text:close']} />
                </Button>
              </Stack>
            </form>
          )}
        />
      </Box>
    </Container>
  );
};
