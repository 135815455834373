import React from 'react';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

type ListItemButtonProps = {
  children: React.ReactNode;
  onClick?: ButtonBaseProps['onClick'];
  disableRipple?: ButtonBaseProps['disableRipple'];
  selected?: boolean;
};

export const ListItemButton: React.FC<ListItemButtonProps> = (props) => {
  const { children, onClick, selected, disableRipple } = props;
  return (
    <ButtonBase
      onClick={onClick}
      disableRipple={disableRipple}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: SizingMultiplier.md,
        minHeight: 56,
        color: selected === false ? 'text.secondary' : 'text.primary',
      }}>
      {children}
    </ButtonBase>
  );
};
