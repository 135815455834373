import React from 'react';
import { Box } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

type ListItemStartIconProps = {
  children?: React.ReactNode;
};

export const ListItemStartIcon: React.FC<ListItemStartIconProps> = (props) => {
  const { children } = props;
  return (
    <Box display="flex" mr={SizingMultiplier.sm} color="action.active">
      {children}
    </Box>
  );
};
