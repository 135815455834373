import React, { useCallback, useMemo, useState } from 'react';

import { PointsSweeps } from './PointsSweeps';
import { PointsStrategy, useOfferPurchaseMutation } from '../../../graphql/types.generated';
import { logInDev } from '../../../lib/logger/logger';
import { useShowGenericErrorSnackbar } from '../../../hooks/useShowGenericErrorSnackbar';
import { MarketingContent } from '../../../modules/offers/offer.types';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import {
  BuyAllEntriesConfirmationActionSheet,
  useBuyAllEntriesConfirmationActionSheet,
} from '../../../components/modals/BuyAllEntriesConfirmationActionSheet';

export interface PointsSweepsControllerProps {
  memberEntries: number;
  memberPoints: number;
  offerId: string;
  entryPrice: number;
  marketingContent: MarketingContent;
  onCompleted(entriesAmount: number): void;
}

export const PointsSweepsController: React.FC<PointsSweepsControllerProps> = (props) => {
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy') as Exclude<
    PointsStrategy,
    PointsStrategy.NotUsed
  >;

  const { memberEntries, memberPoints, offerId, entryPrice, marketingContent, onCompleted } = props;
  const [purchaseEntries, { loading }] = useOfferPurchaseMutation();

  const [entriesAmountToBuy, setEntriesAmountToBuy] = useState<number>(1);
  const onEntriesAmountToBuyChange = useCallback((value: number) => {
    setEntriesAmountToBuy(value);
  }, []);

  const buyAllEntriesConfirmationActionSheet = useBuyAllEntriesConfirmationActionSheet();

  // TODO: do we need profile complete for sweeps?
  const onPurchaseEntries = useCallback(
    (amount: number) => {
      purchaseEntries({
        refetchQueries: ['currentCustomer'],
        variables: {
          input: {
            offerId,
            count: amount,
          },
        },
        onCompleted: () => onCompleted(amount),
        onError: showGenericErrorSnackbar,
      }).then(logInDev);
    },
    [purchaseEntries, offerId, showGenericErrorSnackbar, onCompleted],
  );

  const { maxEntriesMemberCanBuy, maxEntriesMemberCanBuyPrice, entriesPrice, canAddEntries, canRemoveEntries } =
    useMemo(() => {
      const maxEntriesMemberCanBuy = memberPoints ? Math.floor(memberPoints / entryPrice) : 0;
      const maxEntriesMemberCanBuyPrice = maxEntriesMemberCanBuy * entryPrice;

      return {
        maxEntriesMemberCanBuy,
        maxEntriesMemberCanBuyPrice,
        entriesPrice: entriesAmountToBuy * entryPrice,
        canAddEntries: entriesAmountToBuy < maxEntriesMemberCanBuy,
        canRemoveEntries: entriesAmountToBuy > 1,
      };
    }, [entriesAmountToBuy, entryPrice, memberPoints]);

  const onBuyEntriesClick = useCallback(() => {
    onPurchaseEntries(entriesAmountToBuy);
  }, [entriesAmountToBuy, onPurchaseEntries]);

  return (
    <>
      <PointsSweeps
        memberEntries={memberEntries}
        entriesAmountToBuy={entriesAmountToBuy}
        entriesAmountToBuyPrice={entriesPrice}
        onEntriesAmountToBuyChange={onEntriesAmountToBuyChange}
        maxEntriesMemberCanBuy={maxEntriesMemberCanBuy}
        maxEntriesMemberCanBuyPrice={maxEntriesMemberCanBuyPrice}
        onBuyEntriesClick={onBuyEntriesClick}
        onBuyAllEntriesClick={buyAllEntriesConfirmationActionSheet.show}
        canAddEntries={canAddEntries}
        canRemoveEntries={canRemoveEntries}
        marketingContent={marketingContent}
        loading={loading}
        pointsStrategy={pointsStrategy}
      />
      <BuyAllEntriesConfirmationActionSheet
        entries={maxEntriesMemberCanBuy}
        price={maxEntriesMemberCanBuyPrice}
        loading={loading}
        onConfirm={() => onPurchaseEntries(maxEntriesMemberCanBuy)}
        id={buyAllEntriesConfirmationActionSheet.id}
      />
    </>
  );
};
