import { useCallback } from 'react';
import { isApolloError } from '@apollo/client';
import { useIntl } from 'react-intl';

import { useSnackbar } from '../lib/snackbar/snackbar';
import { formatApolloError } from '../modules/apollo/apollo.utils';
import { genericMessages } from '../modules/localization/genericMessages';

export const useShowSnackbarBarForApollo = () => {
  const intl = useIntl();
  const snackbar = useSnackbar();

  return useCallback(
    (error: any) => {
      const message = isApolloError(error)
        ? formatApolloError(error)
        : intl.formatMessage(genericMessages['generic:errorMessageText']);
      snackbar.open(message, {
        variant: 'error',
      });
    },
    [intl, snackbar],
  );
};
