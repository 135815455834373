import { useMemo } from 'react';

import { useGetActivateTransactionQuery } from '../../../graphql/types.generated';
import { mapActivateTransactionToTransactionReceipt } from '../../../modules/transaction/utils';

export const useTransactionReceiptController = (id: string) => {
  const { data, loading } = useGetActivateTransactionQuery({ variables: { id }, fetchPolicy: 'cache-and-network' });
  const receipt = useMemo(() => {
    if (!data?.transactionHistory?.membership?.transactions?.edges[0]) {
      return null;
    }
    return mapActivateTransactionToTransactionReceipt(data.transactionHistory.membership.transactions.edges[0].node);
  }, [data]);
  return { loading, receipt };
};
