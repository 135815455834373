import React from 'react';
import { Box } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

type ListItemActionProps = {
  children?: React.ReactNode;
};

export const ListItemAction: React.FC<ListItemActionProps> = (props) => {
  const { children } = props;
  return (
    <Box my={-SizingMultiplier.md} mr={-SizingMultiplier.sm}>
      {children}
    </Box>
  );
};
