import React from 'react';
import { Typography } from '@mui/material';

type ListItemTextProps = { children?: React.ReactNode; primary?: React.ReactNode; secondary?: React.ReactNode };

export const ListItemText: React.FC<ListItemTextProps> = (props) => {
  const { children, primary, secondary } = props;
  return (
    <Typography display="flex" flexDirection="column" flexGrow="1" align="left" color="inherit">
      {children}
      {primary}
      {secondary && (
        <Typography variant="caption" align="left" color="textSecondary">
          {secondary}
        </Typography>
      )}
    </Typography>
  );
};
