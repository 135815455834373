import { number, object } from 'zod';

import { SurveyFragment } from '../../graphql/types.generated';
import { ValidSurvey } from './survey.types';

export const surveySchema = object({
  min: number(),
  max: number(),
});

export const isSurveyValid = (survey: SurveyFragment): survey is ValidSurvey => {
  const hasQuestions = survey.questions.length > 0;

  if (survey.__typename === 'WalletSteeringSurvey') {
    const { configuration } = survey;
    const isRangeValid = surveySchema.safeParse({ min: configuration.minUnits, max: configuration.maxUnits }).success;
    return Boolean(hasQuestions && isRangeValid && configuration.steeringWalletUnitOfMeasure);
  }

  return hasQuestions;
};
