import { CategoryRestrictions, DisplayCategoryFragment, ProductCategoryFragment } from '../../graphql/types.generated';
import { ProductCategory } from './loyalty.types';
import { Membership } from '../membership/membership.types';

/**
 *  DisplayCategories sorting by "rank" field
 *  If DisplayCategories has the same "rank" value - they should be sorted by "name"
 */
export const sortDisplayCategoriesByNameAndRank = (a: DisplayCategoryFragment, b: DisplayCategoryFragment) => {
  if (a.rank === b.rank) {
    return a.name.localeCompare(b.name);
  }
  return a.rank - b.rank;
};

/**
 *  ProductCategories sorting by "rank" field
 *  If DisplayCategories has the same "rank" value - they should be sorted by "name"
 */
export const sortProductCategoriesByNameAndRank = (a: ProductCategoryFragment, b: ProductCategoryFragment) => {
  if (a.rank === b.rank) {
    return a.name.localeCompare(b.name);
  }
  return a.rank - b.rank;
};

export const isProductCategoryAgeRestricted = (category: ProductCategory): boolean => {
  if (!category.categoryRestriction) {
    return false;
  }
  return [CategoryRestrictions.Age, CategoryRestrictions.Eaiv].includes(category.categoryRestriction);
};

/**
 *  Filter Restricted Categories:
 *  This functionality definitely should be implemented on API side,
 *  but since we need this functionality implemented ASAP it is okay to
 *  keep this filter as close to the API call(our hook) as possible
 */
export const filterCategoriesRestriction = (categories: DisplayCategoryFragment[], membership: Membership) => {
  const membershipRestriction = membership.fuelAndConvenienceProfile?.restrictionVerification;

  return categories.filter((category) => {
    const categoryRestriction = category.categoryRestrictions;

    if (!membershipRestriction) {
      // Show only unrestricted categories (null by default)
      return !categoryRestriction;
    }

    if (membershipRestriction === 'AGE') {
      // Show categories that are either unrestricted, as well as having 'AGE' restriction
      return categoryRestriction === 'AGE' || !categoryRestriction;
    }

    // A member that is EAIV verified can see ALL display categories
    return membershipRestriction === 'EAIV';
  });
};
