import { PrimaryTransactionStatus, SecondaryTransactionStatus } from '../../graphql/types.generated';
import { PrimaryInternalFuelingStatus } from './types';

export interface IUIToApiStatusMap {
  primaries: PrimaryTransactionStatus[];
  secondaries: SecondaryTransactionStatus[];
  internalStatus: PrimaryInternalFuelingStatus;
}

export const authorizingStatuses: IUIToApiStatusMap = {
  primaries: [PrimaryTransactionStatus.Active],
  secondaries: [
    SecondaryTransactionStatus.Started,
    SecondaryTransactionStatus.ReservingPump,
    SecondaryTransactionStatus.PumpReserved,
    SecondaryTransactionStatus.Authorizing,
    SecondaryTransactionStatus.Authorized,
  ],
  internalStatus: PrimaryInternalFuelingStatus.Authorizing,
};

export const beginFuelingStatuses: IUIToApiStatusMap = {
  primaries: [PrimaryTransactionStatus.Active],
  secondaries: [SecondaryTransactionStatus.PumpEnabling, SecondaryTransactionStatus.PumpEnabled],
  internalStatus: PrimaryInternalFuelingStatus.BeginFueling,
};

export const fuelingStartedStatuses: IUIToApiStatusMap = {
  primaries: [PrimaryTransactionStatus.Active],
  secondaries: [
    SecondaryTransactionStatus.Fueling,
    SecondaryTransactionStatus.Settling,
    SecondaryTransactionStatus.Settled,
    SecondaryTransactionStatus.GettingReceipt,
  ],
  internalStatus: PrimaryInternalFuelingStatus.Fueling,
};

export const fuelingCompleteStatuses: IUIToApiStatusMap = {
  primaries: [PrimaryTransactionStatus.Completed],
  secondaries: [SecondaryTransactionStatus.Closed, SecondaryTransactionStatus.UnableToGetReceipt],
  internalStatus: PrimaryInternalFuelingStatus.CompletedSuccessfully,
};

// region PrimaryInternalFuelingStatus.CompletedUnsuccessfully
export const fuelingCompletedUnsuccessfullyStatuses1: IUIToApiStatusMap = {
  primaries: [PrimaryTransactionStatus.Failed],
  secondaries: [
    SecondaryTransactionStatus.Settling,
    SecondaryTransactionStatus.PumpEnabling,
    SecondaryTransactionStatus.SiteUnavailable,
    SecondaryTransactionStatus.ReservingPump,
    SecondaryTransactionStatus.Authorizing,
  ],
  internalStatus: PrimaryInternalFuelingStatus.CompletedUnsuccessfully,
};

export const fuelingCompletedCancelledStatuses: IUIToApiStatusMap = {
  primaries: [PrimaryTransactionStatus.Completed],
  secondaries: [SecondaryTransactionStatus.Cancelled],
  internalStatus: PrimaryInternalFuelingStatus.CompletedCancelled,
};

// endregion PrimaryInternalFuelingStatus.CompletedUnsuccessfully

// region PrimaryInternalFuelingStatus.Cancelling
export const fuelingCancellingStatuses: IUIToApiStatusMap = {
  primaries: [PrimaryTransactionStatus.Active],
  secondaries: [
    SecondaryTransactionStatus.CancellingReservingPump,
    SecondaryTransactionStatus.CancellingPumpReserved,
    SecondaryTransactionStatus.CancellingAuthorizing,
    SecondaryTransactionStatus.CancellingAuthorized,
    SecondaryTransactionStatus.CancellingPumpEnabling,
    SecondaryTransactionStatus.CancellingPumpEnabled,
  ],
  internalStatus: PrimaryInternalFuelingStatus.Cancelling,
};
// endregion

export const externalStatusToInternalStatus = {
  authorizingStatuses,
  beginFuelingStatuses,
  fuelingStartedStatuses,
  fuelingCompleteStatuses,
  fuelingCompletedUnsuccessfullyStatuses1,
  fuelingCancellingStatuses,
  fuelingCompletedCancelledStatuses,
};
