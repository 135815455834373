import { useCallback } from 'react';

import { useCustomerPaymentInstrumentCreateMutation } from '../../../graphql/types.generated';
import { buildCreatePaymentInstrumentPayload } from '../../../modules/wallet/wallet.utils';
import { usePaymentProvider } from '../../../modules/transaction/hooks/usePaymentProvider';
import {
  TokenizeCardInput,
  type TokenizeCardResponse,
} from '../../../lib/payments/PaymentProvider/payment-provider.types';

export const useCreatePaymentInstrument = () => {
  const paymentProvider = usePaymentProvider();
  const [createPaymentInstrument] = useCustomerPaymentInstrumentCreateMutation({
    refetchQueries: ['getPaymentInstruments'],
  });

  return useCallback(
    async (input: TokenizeCardInput) => {
      if (!paymentProvider) {
        throw new Error('Payment Provider is not configured');
      }

      const tokenizedData: TokenizeCardResponse = await paymentProvider.tokenizeCard(input);
      const { data } = await createPaymentInstrument({
        variables: {
          input: buildCreatePaymentInstrumentPayload(input, tokenizedData),
        },
      });

      return { paymentInstrumentId: data?.customerPaymentInstrumentCreate.uuid || null };
    },
    [paymentProvider, createPaymentInstrument],
  );
};
