import React from 'react';
import { Stack, Theme, Divider } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

type ListProps = {
  children?: React.ReactNode;
  disabledPaddings?: boolean;
};

const disabledPaddingMx = (theme: Theme) => `${theme.spacing(-SizingMultiplier.md)}!important`;

export const List: React.FC<ListProps> = (props) => {
  const { children, disabledPaddings } = props;
  return (
    <Stack
      divider={<Divider variant="middle" />}
      mx={disabledPaddings ? disabledPaddingMx : undefined}
      color="text.primary">
      {children}
    </Stack>
  );
};
