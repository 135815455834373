import React from 'react';
import { useIntl } from 'react-intl';

import { Chip } from '../../../Chip/Chip';
import { TimerIcon } from '../../../Icons/TimerIcon';
import { messages } from './messages';

export interface OfferExpirationChipProps {
  time: string;
}

export const OfferExpirationChip: React.FC<OfferExpirationChipProps> = ({ time }) => {
  const intl = useIntl();

  return (
    <Chip variant="body2" elevated color="offerLabel.Expires.color" bgcolor="offerLabel.Expires.backgroundColor">
      <TimerIcon />
      {intl.formatMessage(messages.Expires, {
        time,
      })}
    </Chip>
  );
};
