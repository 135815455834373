import Payment from 'payment';

import { IStoreCarWashOption } from '../../modules/stores/types';
import { IPaymentItem, PAYMENT_STATUS } from './payments.types';
import { PaymentInstrumentType } from '../../graphql/types.generated';

export interface IGeneratePaymentItemsParams {
  selectedCarWashOption?: IStoreCarWashOption | null;
}

export const buildPaymentItems = (params: IGeneratePaymentItemsParams): IPaymentItem[] => {
  const { selectedCarWashOption = null } = params;

  const items: IPaymentItem[] = [
    {
      label: 'FUEL (TBD)',
      price: '1',
      status: PAYMENT_STATUS.PENDING,
    },
  ];

  if (selectedCarWashOption !== null) {
    items.push({
      label: `Car wash: ${selectedCarWashOption.name}`,
      price: selectedCarWashOption.unitPrice.toString(),
      status: PAYMENT_STATUS.FINAL,
    });
  }

  return items;
};

/**
 * Splits a string containing `MM/YY` into 'month' and 'year' fields.
 */
export const parseExpiryDate = (expiryDate: string) => {
  const { month, year } = Payment.fns.cardExpiryVal(expiryDate);
  return {
    month: String(month).padStart(2, '0'),
    year: String(year),
  };
};

// Key - string from Payment.fns.cardType
// node_modules/@types/payment/index.d.ts
export const cardTypeToPaymentInstrumentTypeMapping: { [key: string]: PaymentInstrumentType } = {
  visa: PaymentInstrumentType.Visa,
  mastercard: PaymentInstrumentType.Mastercard,
  discover: PaymentInstrumentType.Discover,
  amex: PaymentInstrumentType.AmericanExpress,
  jcb: PaymentInstrumentType.Jcb,
  dinersclub: PaymentInstrumentType.DinersClub,
  maestro: PaymentInstrumentType.Maestro,
  laser: PaymentInstrumentType.Laser,
  elo: PaymentInstrumentType.Elo,
  // unknowns
  unionpay: PaymentInstrumentType.Unknown,
};

/**
 * Get PaymentInstrumentType based on string
 */
export const getPaymentInstrumentTypeByCardType = (cardType: string): PaymentInstrumentType =>
  cardTypeToPaymentInstrumentTypeMapping[cardType.toLowerCase()] || PaymentInstrumentType.Unknown;
