import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import MarkerIcon from '@active-configuration/assets/map-marker.svg';

import { SizingMultiplier } from '../../theme/constants';
import { BorderedSection } from '../BorderedSection/BorderedSection';

type StoreAddressProps = {
  children: ReactNode;
};

export const StoreAddress: React.FC<StoreAddressProps> = ({ children }) => (
  <BorderedSection>
    <Box component="img" src={MarkerIcon} alt="marker" height={24} mr={SizingMultiplier.sm} />
    <Typography variant="caption">{children}</Typography>
  </BorderedSection>
);
