import React from 'react';

import { ScreenLoaderBackdrop } from '../../components/ScreenLoader/ScreenLoader';
import { ProductInterestsLayout } from './ProductInterestsLayout';
import { ReplaceToHome } from '../Home/ReplaceToHome';
import { ProductCategory } from '../../modules/loyalty/loyalty.types';
import { CategoryRestrictions } from '../../graphql/types.generated';
import { useProductCategories } from '../../modules/loyalty/useProductCategories';
import { useMembershipProductInterests } from '../../modules/membership/useMembershipProductInterests';
import { getIsVerified, getPendingVerification } from './product-interests.utils';
import { useMembership } from '../../modules/membership/use-membership.hook';

export type ProductInterestsGuardProps = {
  Component: React.ComponentType<{
    isMembershipLoading: boolean;
    membershipBirthdate: string | null;
    initialProductCategories: ProductCategory[];
    productCategories: ProductCategory[];
    pendingVerification: CategoryRestrictions | null;
    isVerified: boolean;
    membershipVerification: CategoryRestrictions | null;
  }>;
};

export const ProductInterestsGuard: React.FC<ProductInterestsGuardProps> = ({ Component }) => {
  const { membership, loading: isMembershipLoading } = useMembership();
  const { productCategories: membershipProductCategories, loading: isMembershipProductInterestsLoading } =
    useMembershipProductInterests();
  const { productCategories, loading: isProductCategoriesLoading } = useProductCategories();

  if (!productCategories.length && isProductCategoriesLoading) {
    return (
      <ProductInterestsLayout>
        <ScreenLoaderBackdrop open />
      </ProductInterestsLayout>
    );
  }

  if (!membershipProductCategories.length && isMembershipProductInterestsLoading) {
    return (
      <ProductInterestsLayout>
        <ScreenLoaderBackdrop open />
      </ProductInterestsLayout>
    );
  }

  if (!membership || !productCategories.length) {
    // TODO: log to sentry?
    return <ReplaceToHome />;
  }

  const pendingVerification = getPendingVerification({
    pendingVerification: membership.fuelAndConvenienceProfile.pendingVerification,
    membershipProductInterests: membershipProductCategories,
  });

  const isVerified = getIsVerified({
    pendingVerification,
    membershipRestrictionVerification: membership.fuelAndConvenienceProfile.restrictionVerification,
  });

  return (
    <Component
      pendingVerification={pendingVerification}
      isVerified={isVerified}
      initialProductCategories={membershipProductCategories}
      productCategories={productCategories}
      isMembershipLoading={isMembershipLoading}
      membershipBirthdate={membership.birthdate}
      membershipVerification={membership.fuelAndConvenienceProfile.restrictionVerification ?? null}
    />
  );
};
