import React, { CSSProperties } from 'react';
import palette from '@active-configuration/palette.json';

import styles from './PureCircularProgress.module.css';

const spinnerStyle: CSSProperties = {
  // @ts-ignore main does not exist
  borderColor: `${palette?.primary?.main || '#000'} transparent transparent transparent`,
};

export const PureCircularProgress: React.FC = () => (
  <div className={styles.spinner}>
    <div style={spinnerStyle} />
    <div style={spinnerStyle} />
    <div style={spinnerStyle} />
    <div style={spinnerStyle} />
  </div>
);
