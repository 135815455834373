import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { OfferActivationStatus } from '../../graphql/types.generated';
import { Offer } from '../../modules/offers/offer.types';
import { useActivateOfferHelpers } from '../Home/features/useGetOfferOnLoyaltyActionClick';

export const useOfferAutoActivator = (offer: Offer | null) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activate, activating } = useActivateOfferHelpers();

  useEffect(() => {
    if (offer?.activationStatus !== OfferActivationStatus.RequiresActivation || !searchParams.has('activate', 'true')) {
      return;
    }
    activate(offer);
  }, [searchParams, setSearchParams, offer?.activationStatus, offer, activate]);

  return { activating };
};
