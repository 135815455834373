import { ApolloClient, isApolloError, NormalizedCacheObject } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { addBreadcrumb } from '@sentry/react';

import {
  GetAccessTokenByRefreshTokenDocument,
  GetAccessTokenByRefreshTokenMutation,
  GetAccessTokenByRefreshTokenMutationVariables,
} from '../../graphql/types.generated';
import { accessTokenVar, isLoggedInVar, refreshTokenVar } from './authReactiveVariables';
import {
  getRudderStackInstance,
  getIsRudderStackEnabledFromApolloCache,
} from '../../lib/rudderStack/rudderStack.utils';
import { logInDev } from '../../lib/logger/logger';

export class AuthService {
  private apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

  public injectNetworkClient(client: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = client;
  }

  public setTokens = (accessToken: string, refreshToken: string): void => {
    if (!accessToken) {
      // WARN: this is a temporary solution to fix double magic links issue
      isLoggedInVar(false);
    }
    accessTokenVar(accessToken);
    refreshTokenVar(refreshToken);
  };

  public async refreshTokens(): Promise<void> {
    const accessToken = accessTokenVar();
    if (!accessToken || !this.apolloClient) {
      await this.clearSession();
      return;
    }

    const variables: GetAccessTokenByRefreshTokenMutationVariables = {
      input: {
        accessToken: accessTokenVar(),
        refreshToken: refreshTokenVar(),
      },
    };
    const isRudderStackEnabled = getIsRudderStackEnabledFromApolloCache(this.apolloClient);
    const { triggerEvent } = getRudderStackInstance(isRudderStackEnabled);

    try {
      const response = await this.apolloClient.mutate<GetAccessTokenByRefreshTokenMutation>({
        variables,
        mutation: GetAccessTokenByRefreshTokenDocument,
      });

      // Impossible scenario, handling types
      if (!response.data?.getAccessTokenByRefreshToken) {
        this.setTokens('', '');
        triggerEvent('auth_session_refresh_failed');
        return;
      }

      const { accessToken, refreshToken } = response.data.getAccessTokenByRefreshToken;
      this.setTokens(accessToken, refreshToken);
      triggerEvent('auth_session_refreshed');
    } catch (e: any) {
      this.setTokens('', '');

      if (!isApolloError(e)) {
        Sentry.captureException(e);
        return;
      }

      triggerEvent('auth_session_refresh_failed');

      if (e.graphQLErrors?.length && e.graphQLErrors[0].message === 'Refresh Token has expired') {
        triggerEvent('auth_session_refresh_token_expired');
        return;
      }

      if (e.graphQLErrors?.length && e.graphQLErrors[0].message === 'Invalid Refresh Token') {
        triggerEvent('auth_session_refresh_token_invalid');
      }
    }
  }

  public async clearSession(): Promise<void> {
    addBreadcrumb({
      category: 'logger_auth',
      message: `setTokens('', '')`,
      level: 'info',
    });
    this.setTokens('', '');

    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
    try {
      addBreadcrumb({
        category: 'logger_auth',
        message: `await this.apolloClient?.clearStore();`,
        level: 'info',
      });
      // https://www.apollographql.com/docs/react/api/core/ApolloClient/#resetStore
      // await this.apolloClient?.resetStore();
      // Remove all data from the store. Unlike resetStore, clearStore will not refetch any active queries.
      await this.apolloClient?.clearStore();
    } catch (e) {
      addBreadcrumb({
        category: 'logger_auth',
        message: `await this.apolloClient?.clearStore() throws an exception`,
        level: 'error',
      });
      logInDev(e);
    }
  }

  public getAccessToken(): string {
    return accessTokenVar();
  }
}
