import { Paper, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { messages } from '../../messages';
import { PointsStrategy } from '../../../../graphql/types.generated';
import { Points } from './Points';
import { SizingMultiplier } from '../../../../theme/constants';

export type PointsBoxProps = {
  points?: number;
  name?: string;
  loading: boolean;
  pointsStrategy: PointsStrategy;
  handlePointsClick?(): void;
};

export const PointsBox = (props: PointsBoxProps) => {
  const { points, name, loading, pointsStrategy, handlePointsClick } = props;
  const intl = useIntl();

  return (
    <Stack
      component={Paper}
      justifyContent="space-between"
      gap={SizingMultiplier.md}
      minHeight={64}
      direction="row"
      elevation={0}>
      <Typography alignSelf="center" pl={SizingMultiplier.md}>
        {name
          ? intl.formatMessage(messages['home:pointsBox:customerGreetingWithName'], { customerName: name })
          : intl.formatMessage(messages['home:pointsBox:customerGreeting'])}
      </Typography>

      {pointsStrategy !== PointsStrategy.NotUsed && points !== null && points !== undefined && (
        <Points points={points} pointsStrategy={pointsStrategy} loading={loading} onClick={handlePointsClick} />
      )}
    </Stack>
  );
};
