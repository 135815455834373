import { Stack, Typography as MUITypography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import { HowDoesItWorkPopupProps } from './how-does-it-work-popup.types';
import { ModalDrawer } from '../components';
import { BaseMechanicListItem } from './components';
import { SizingMultiplier } from '../../../theme/constants';
import { messages } from './how-does-it-work-popup.messages';
import { OfferDetailsTermsAndDisclaimer } from '../../OfferDetails/OfferDetailsTermsAndDisclaimer';

export const HowDoesItWorkPopupController: React.FC<HowDoesItWorkPopupProps> = (props) => {
  const { visible, hide, remove } = useModal();

  const { offers = [], terms = null, termsUrl = null, disclaimer = null, disclaimerUrl = null } = props;
  const showInfoSection = disclaimer || disclaimerUrl || terms || termsUrl;

  return (
    <ModalDrawer open={visible} onClose={hide} onExited={remove}>
      <Stack p={SizingMultiplier.lg} spacing={SizingMultiplier.lg}>
        <MUITypography textAlign="center" variant="h5">
          <FormattedMessage {...messages.title} />
        </MUITypography>
        {offers.map((offer) => (
          <BaseMechanicListItem
            key={offer.id}
            title={offer.marketingContent.title}
            description={offer.marketingContent.shortDescription}
            imageSrc={offer.marketingContent.imageUrl}
          />
        ))}
        {showInfoSection && (
          <OfferDetailsTermsAndDisclaimer
            terms={terms}
            termsUrl={termsUrl}
            disclaimer={disclaimer}
            disclaimerUrl={disclaimerUrl}
          />
        )}
      </Stack>
    </ModalDrawer>
  );
};

export default HowDoesItWorkPopupController;
