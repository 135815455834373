import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SizingMultiplier } from '../../theme/constants';

export interface IOfferDetailsTermsAndDisclaimerProps {
  disclaimer?: string | null;
  disclaimerUrl?: string | null;
  terms?: string | null;
  termsUrl?: string | null;
}

export const OfferDetailsTermsAndDisclaimer: React.FC<IOfferDetailsTermsAndDisclaimerProps> = (props) => {
  const { disclaimer, disclaimerUrl, terms, termsUrl } = props;

  return (
    <Box
      borderRadius={SizingMultiplier.md}
      p={SizingMultiplier.md}
      display="flex"
      flexDirection="column"
      bgcolor="background.box">
      {disclaimer && (
        <Typography variant="caption" color="textSecondary">
          {disclaimer}
        </Typography>
      )}
      {disclaimerUrl && (
        <Link href={disclaimerUrl} variant="caption" gutterBottom color="textSecondary" underline="always">
          <FormattedMessage
            id="offerDetailsPopup:disclaimerText"
            defaultMessage="Disclaimer"
            description="Disclaimer link title on the Offer Details popup"
          />
        </Link>
      )}
      {terms && (
        <Typography variant="caption" color="textSecondary">
          {terms}
        </Typography>
      )}
      {termsUrl && (
        <Link href={termsUrl} variant="caption" color="textSecondary" underline="always">
          <FormattedMessage
            id="offerDetailsPopup:termsAndConditionsText"
            defaultMessage="Terms & Conditions"
            description="Terms & Conditions link title on the Offer Details popup"
          />
        </Link>
      )}
    </Box>
  );
};
