import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

import { isIosPlatform } from '../../modules/device/utils/info';

export const useOpenSystemSettings = () => {
  return useCallback((section?: 'notification_id' | 'location' | 'application_details') => {
    // @ts-ignore
    if (!window?.cordova?.plugins?.settings) {
      Sentry.captureException(new Error('Open native settings not available.'));
    }
    if (section === 'location' && isIosPlatform()) {
      // @ts-ignore
      window.cordova.plugins.settings.open('locations');
    }
    // @ts-ignore
    window.cordova.plugins.settings.open(section);
  }, []);
};
