import { ApolloClient } from '@apollo/client';

import { GetDisplayCategoriesDocument } from '../../graphql/types.generated';
import { DISPLAY_CATEGORIES_CACHE_KEY } from './useCacheDisplayCategories';
import { cacheStorage } from '../cache-layer/cacheStorage';

export const restoreDisplayCategoriesCache = async (client: ApolloClient<any>) => {
  // Display categories cache
  const displayCategories = await cacheStorage.getItem(DISPLAY_CATEGORIES_CACHE_KEY);
  if (displayCategories) {
    client.writeQuery({
      query: GetDisplayCategoriesDocument,
      data: displayCategories,
    });
  }
};
