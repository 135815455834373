import { coerce, preprocess, object, string, boolean, ZodIssueCode } from 'zod';

import { intl } from '../../lib/translation/TranslationProvider';
import { makeValidateSync } from '../../lib/form/utils';
import { validationMessages } from '../../modules/localization/validationMessages';
import { calcDateTime, getDaysInMonth, isAfterDateTime } from '../../lib/date/date';

export type GetProfileValidationSchemaParams = {
  membershipMinAge: number;
  membershipMaxAge: number;
  isExplicitTermsAcceptanceRequired: boolean;
};

export const getSchema = (params: GetProfileValidationSchemaParams) => {
  const { membershipMinAge, membershipMaxAge, isExplicitTermsAcceptanceRequired } = params;

  const now = new Date();
  const maxDobDate = calcDateTime(now, 'sub', membershipMinAge, 'year');
  const maxPossibleYOB = calcDateTime(now, 'sub', membershipMinAge, 'year').getFullYear();
  const minPossibleYOB = calcDateTime(now, 'sub', membershipMaxAge, 'year').getFullYear();

  const birthdateErrorMessage = intl.formatMessage(validationMessages['validation:form:birthday:required']);
  const birthdateMinAgeErrorMessage = intl.formatMessage(validationMessages['validation:form:birthdate:min-age'], {
    minAge: membershipMinAge,
  });

  const firstNameErrorMessage = intl.formatMessage(validationMessages['validation:form:first-name:required']);
  const lastNameErrorMessage = intl.formatMessage(validationMessages['validation:form:last-name:required']);
  const emailErrorMessage = intl.formatMessage(validationMessages['validation:form:email:required']);

  const birthdateSchema = preprocess(
    (val) => val ?? {},
    object({
      month: coerce
        .number({ message: birthdateErrorMessage })
        .min(1, birthdateErrorMessage)
        .max(12, birthdateErrorMessage),
      date: coerce
        .number({ message: birthdateErrorMessage })
        .min(1, birthdateErrorMessage)
        .max(31, birthdateErrorMessage),
      year: coerce
        .number({ message: birthdateErrorMessage })
        .min(minPossibleYOB, birthdateErrorMessage)
        .max(maxPossibleYOB, birthdateErrorMessage),
    }).superRefine((data, ctx) => {
      const { month, date, year } = data;

      if (!date && !month && !year) {
        return;
      }

      const selectedDate = new Date(year, month - 1, date);
      const selectedYearAndMonth = new Date();
      selectedYearAndMonth.setMonth(month - 1);
      selectedYearAndMonth.setFullYear(year);
      const daysInMonth = getDaysInMonth(selectedYearAndMonth);

      if (!isAfterDateTime(maxDobDate, selectedDate)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: birthdateMinAgeErrorMessage,
          path: ['birthdateError'],
        });
      }

      if (date > daysInMonth) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: birthdateErrorMessage,
          path: ['date'],
        });
      }
    }),
  );

  return {
    schema: object({
      firstName: string({ message: firstNameErrorMessage }).trim().min(1, { message: firstNameErrorMessage }),
      lastName: string({ message: lastNameErrorMessage }).trim().min(1, { message: lastNameErrorMessage }),
      email: string({ message: emailErrorMessage }).email(emailErrorMessage),
      termsAccepted: isExplicitTermsAcceptanceRequired ? boolean().refine((value) => !!value) : boolean().optional(),
      birthdate: birthdateSchema,
      phoneNumber: string(),
    }).required(),
    maxPossibleYOB,
    minPossibleYOB,
  };
};

export const getProfileFormValidationSchema = (params: GetProfileValidationSchemaParams) => {
  const { schema, maxPossibleYOB, minPossibleYOB } = getSchema(params);
  return {
    validationSchema: makeValidateSync(schema),
    maxPossibleYOB,
    minPossibleYOB,
  };
};
