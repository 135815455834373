import React from 'react';
import { Container } from '@mui/material';
import { defineMessage } from 'react-intl';

import { ProfileButton } from '../../components/Layout/NavigationBar/ProfileButton/ProfileButton';
import { PageContent } from '../../components/Layout/PageContent/PageContent';
import { Page } from '../../components/Layout/Page/Page';
import { PoweredByOpenCommerce } from '../../components/Layout/PoweredByOpenCommerce/PoweredByOpenCommerce';
import { StoreLocatorButton } from '../../components/Layout/NavigationBar/StoreLocatorButton/StoreLocatorButton';
import { PullToRefresh } from '../../components/PullToRefresh/PullToRefresh';
import { SELECTORS } from '../../modules/tutorial/settings';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { NavigationBar } from '../../components/Layout/NavigationBar/NavigationBar';
import { VendorLogo } from '../../components/Layout/NavigationBar/VendorLogo/VendorLogo';

export interface IHomeLayoutProps {
  bottom?: React.ReactNode;
  onRefresh: () => Promise<any>;
  children: React.ReactNode;
}

export const HomeLayout: React.FC<IHomeLayoutProps> = ({ children, bottom, onRefresh }) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'home:htmlTitle',
          defaultMessage: 'Home',
          description: 'HTML title tag for Home Page',
        })}
      />
      <NavigationBar left={<StoreLocatorButton />} center={<VendorLogo />} right={<ProfileButton />} />
      <PageContent ref={contentRef}>
        <PullToRefresh onRefresh={onRefresh} scrollRef={contentRef}>
          <Container maxWidth="sm" id={SELECTORS.HOME_CONTAINER_ID}>
            {children}
          </Container>
          <PoweredByOpenCommerce />
        </PullToRefresh>
      </PageContent>
      {bottom}
    </Page>
  );
};
