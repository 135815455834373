import { createContext, useContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';

import { Membership } from '../membership.types';
import { CurrentCustomerQuery } from '../../../graphql/types.generated';
import { logInDev } from '../../../lib/logger/logger';

export interface IMembershipContextValue {
  loading: boolean;
  membership: Membership | null;
  refetch(): Promise<ApolloQueryResult<CurrentCustomerQuery>>;
}

const defaultMembershipContextValue: IMembershipContextValue = {
  loading: true,
  membership: null,
  // @ts-ignore
  refetch: () => {
    logInDev('MembershipContextValue.refetch is not implemented');
  },
};

export const MembershipContext = createContext<IMembershipContextValue>(defaultMembershipContextValue);
export const useMembershipContext = () => useContext(MembershipContext);
