import { AwardVouchersFragment, OfferEngagementType, OfferFragment } from '../../graphql/types.generated';
import { Award, AwardPoints, AwardPointsPer, AwardSweepstakeEntries, AwardType, AwardVouchers } from './award.types';
import { isSweepstake } from './offer.type-guards';

const getAwardFrom = ({ earningMechanism }: OfferFragment) => {
  switch (earningMechanism?.__typename) {
    case 'EarningMechanismProductDiscount':
    case 'EarningMechanismTransactionDiscount':
    case 'EarningMechanismVarietyDiscount':
      return null;

    case 'EarningMechanismExternalFulfillment':
    case 'EarningMechanismLoyaltyEvent':
      return earningMechanism.optionalAward;

    case 'EarningMechanismMembershipEvent':
    case 'EarningMechanismProductPurchase':
    case 'EarningMechanismTotalEligiblePurchase':
    case 'EarningMechanismTotalEligiblePurchaseOverTime':
    case 'EarningMechanismTotalSpendOverTime':
    case 'EarningMechanismVarietyPurchase':
    case 'EarningMechanismVarietyPurchaseOverTime':
    case 'EarningMechanismVisits':
      return earningMechanism.award;

    default:
      return null;
  }
};

export const mapAwardVouchersFragment = ({
  award,
  engagementType,
}: {
  award: AwardVouchersFragment;
  engagementType: OfferEngagementType | null;
}): AwardSweepstakeEntries | AwardVouchers => {
  if (isSweepstake(engagementType)) {
    const result: AwardSweepstakeEntries = {
      type: AwardType.SweepstakeEntries,
      entriesCount: award.voucherCount,
      entryExpirationDays: award.voucherExpirationDays,
      entriesOfferId: award.voucheredOffer.id,
      memberEntries: award.voucheredOffer.voucherCount,
    };
    return result;
  }

  const result: AwardVouchers = {
    type: AwardType.Vouchers,
    voucherCount: award.voucherCount,
    voucherExpirationDays: award.voucherExpirationDays,
    voucheredOfferId: award.voucheredOffer.id,
    memberVouchers: award.voucheredOffer.voucherCount,
  };
  return result;
};

export const mapAward = (fragment: OfferFragment): Award | null => {
  const award = getAwardFrom(fragment);

  if (award?.__typename === 'AwardPoints') {
    const result: AwardPoints = {
      type: AwardType.Points,
      points: award.points,
    };
    return result;
  }

  if (award?.__typename === 'AwardPointsPer') {
    const result: AwardPointsPer = {
      type: AwardType.PointsPer,
      points: award.points,
    };
    return result;
  }

  if (award?.__typename === 'AwardVouchers') {
    return mapAwardVouchersFragment({ award, engagementType: fragment.engagementType });
  }

  return null;
};
