import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../components/Button';
import { SizingMultiplier } from '../../theme/constants';
import { messages } from './contact-support.messages';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { phoneNumberFormatNationalForUs } from '../../lib/phone-number';
import { intl } from '../../lib/translation/TranslationProvider';
import { LoadingButton } from '../../components/LoadingButton';

type ContactSupportViewProps = {
  phoneNumber: string | null;
  email: string;
  onCancel(): void;
  isSupportWidgetEnabled: boolean;
  isSupportWidgetLoading: boolean;
  onSupportWidgetClick(): Promise<void>;
};

export const ContactSupportView: React.FC<ContactSupportViewProps> = (props) => {
  const { phoneNumber, email, onCancel, isSupportWidgetEnabled, isSupportWidgetLoading, onSupportWidgetClick } = props;
  const phoneNumberFormatted = useMemo(
    () => (phoneNumber ? phoneNumberFormatNationalForUs(phoneNumber) : ''),
    [phoneNumber],
  );

  return (
    <Stack p={SizingMultiplier.lg} spacing={SizingMultiplier.md}>
      <Stack spacing={SizingMultiplier.sm}>
        <Typography variant="h5">
          <FormattedMessage {...messages['contact-support-page:title']} />
        </Typography>
        <Typography>
          <FormattedMessage {...messages['contact-support-page:description']} />
        </Typography>
      </Stack>
      {isSupportWidgetEnabled && (
        <LoadingButton onClick={onSupportWidgetClick} loading={isSupportWidgetLoading}>
          {intl.formatMessage(messages['contact-support-page:chatButtonText'])}
        </LoadingButton>
      )}
      {phoneNumber && (
        <Button href={`tel:${phoneNumber}`} target="_blank">
          <FormattedMessage
            {...buttonTextMessages['button-text:call-phone-number']}
            values={{ phoneNumber: phoneNumberFormatted }}
          />
        </Button>
      )}
      <Button href={`mailto:${email}`} target="_blank">
        <FormattedMessage {...buttonTextMessages['button-text:send-email']} />
      </Button>
      <Button variant="text" onClick={onCancel}>
        <FormattedMessage {...buttonTextMessages['button-text:cancel']} />
      </Button>
    </Stack>
  );
};
