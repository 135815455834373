import { Button, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import { ConfirmationActionSheetProps } from './confirmation-action-sheet.types';
import { ModalDrawer } from '../components';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { SizingMultiplier } from '../../../theme/constants';
import { logInDev } from '../../../lib/logger/logger';

export const ConfirmationActionSheetController = (props: ConfirmationActionSheetProps) => {
  const intl = useIntl();
  const {
    title,
    description,
    confirmButtonText = intl.formatMessage(buttonTextMessages['button-text:confirm']),
    cancelButtonText = intl.formatMessage(buttonTextMessages['button-text:cancel']),
    cancelOnClickOutsideDisabled = false,
    onConfirm,
    onCancel,
    onClosed,
  } = props;

  const { visible, hide, remove } = useModal();

  const handleExited = useCallback(() => {
    onClosed?.();
    remove();
  }, [remove, onClosed]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    hide().catch(logInDev);
  }, [hide, onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel?.();
    hide().catch(logInDev);
  }, [hide, onCancel]);

  return (
    <ModalDrawer
      open={visible}
      onClose={cancelOnClickOutsideDisabled ? undefined : handleCancel}
      onExited={handleExited}>
      <Stack spacing={SizingMultiplier.lg} p={SizingMultiplier.lg} pt={SizingMultiplier.xl}>
        <Stack spacing={SizingMultiplier.md}>
          <Typography variant="h5" component="h1">
            {title}
          </Typography>
          {description && <Typography>{description}</Typography>}
        </Stack>
        <Stack spacing={SizingMultiplier.sm}>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            {confirmButtonText}
          </Button>
          <Button onClick={handleCancel} color="primary" variant="text">
            {cancelButtonText}
          </Button>
        </Stack>
      </Stack>
    </ModalDrawer>
  );
};
