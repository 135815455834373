import {
  LimitFragment,
  LimitMembershipAwardCountFragment,
  LimitMembershipAwardCountInTimeFragment,
} from '../../graphql/types.generated';

export const isMembershipAwardCountLimit = (
  limit: LimitFragment,
): limit is LimitMembershipAwardCountFragment | LimitMembershipAwardCountInTimeFragment =>
  ['LimitMembershipAwardCount', 'LimitMembershipAwardCountInTime'].includes(limit.__typename);
