import { v4 as uuidv4 } from 'uuid';
import { setContext } from '@apollo/client/link/context';
import { App } from '@capacitor/app';

import { EnvConfiguration } from '../../configuration/EnvConfiguration';
import { getDeviceId, getDevicePlatform } from '../../../lib/device/utils/info';
import { CapacitorPlatform } from '../../../lib/device/types/types';

export type TracingHeaders = {
  'x-app-version': string;
  'x-ocx-release': string;
  'x-ocx-git-revision': string;
  'x-ocx-platform': CapacitorPlatform;
  'x-ocx-native-build'?: string;
  'x-ocx-native-version'?: string;
  'x-client-trace-id': string;
  'x-device-id': string;
};

export const tracingMiddleware = setContext(async () => {
  const devicePlatform = getDevicePlatform();
  const deviceId = await getDeviceId();

  const tracingHeaders: TracingHeaders = {
    'x-app-version': EnvConfiguration.release,
    'x-ocx-release': EnvConfiguration.release,
    'x-ocx-git-revision': EnvConfiguration.gitRevision,
    'x-ocx-platform': devicePlatform,
    'x-client-trace-id': uuidv4(),
    'x-device-id': deviceId,
  };

  if (devicePlatform !== 'web') {
    const appInfo = await App.getInfo();
    tracingHeaders['x-ocx-native-build'] = appInfo.build;
    tracingHeaders['x-ocx-native-version'] = appInfo.version;
  }

  return {
    headers: tracingHeaders,
  };
});
