import React, { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { BackButton } from '../../components/Layout/NavigationBar/BackButton/BackButton';
import { PageContent } from '../../components/Layout/PageContent/PageContent';
import { Page } from '../../components/Layout/Page/Page';
import { PoweredByOpenCommerce } from '../../components/Layout/PoweredByOpenCommerce/PoweredByOpenCommerce';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { NavigationBar } from '../../components/Layout/NavigationBar/NavigationBar';
import { VendorLogo } from '../../components/Layout/NavigationBar/VendorLogo/VendorLogo';

type ProfileLayoutProps = {
  children: ReactNode;
};

export const ProfileLayout: React.FC<ProfileLayoutProps> = ({ children }) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'profile:htmlTitle',
          defaultMessage: 'Profile',
          description: 'HTML title tag for Profile Page',
        })}
      />
      <NavigationBar left={<BackButton />} center={<VendorLogo />} />
      <PageContent>
        {children}
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
