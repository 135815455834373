import { styled, Typography } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

export interface IChipProps {
  elevated?: boolean;
}

export const Chip = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'elevated',
})<IChipProps>(({ theme, elevated }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(SizingMultiplier.xs),
  padding: theme.spacing(SizingMultiplier.xs, SizingMultiplier.sm),
  borderRadius: theme.spacing(SizingMultiplier.md),
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxShadow: elevated ? theme.shadows[2] : undefined,
}));
