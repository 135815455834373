import { useCallback } from 'react';

import {
  CommunicationPreferenceUpdateMutationVariables,
  useCommunicationPreferenceUpdateMutation,
} from '../../graphql/types.generated';

interface IUpdatePushNotificationPreferenceParams {
  customerId?: string;
  isOptedIn: boolean;
}

export const useUpdatePushNotificationPreference = () => {
  const [communicationPreferenceUpdate] = useCommunicationPreferenceUpdateMutation();

  return useCallback(
    ({ isOptedIn, customerId }: IUpdatePushNotificationPreferenceParams) => {
      const variables: CommunicationPreferenceUpdateMutationVariables = {
        input: {
          pushOptInAt: isOptedIn ? new Date() : null,
          pushOptOutAt: isOptedIn ? null : new Date(),
        },
      };

      communicationPreferenceUpdate({
        variables,
        update(cache, { data }) {
          if (!data?.preference || !customerId) {
            return;
          }
          const cacheId = cache.identify({ __typename: 'Customer', uuid: customerId });
          cache.modify({
            id: cacheId,
            fields: { communicationPreference: () => data.preference },
          });
        },
      });
    },
    [communicationPreferenceUpdate],
  );
};
