import { useCallback } from 'react';

import { useCustomerUpdateMutation } from '../../../graphql/types.generated';
import { logInDev } from '../../../lib/logger/logger';
import { useHandleUpdateProfileError } from '../../../pages/EditProfile/edit-profile.utils';

type UseCompleteEmailActionSheetControllerParams = {
  onCompleted?(): void;
};

export const useCompleteEmailActionSheetController = (params: UseCompleteEmailActionSheetControllerParams = {}) => {
  const handleError = useHandleUpdateProfileError();
  const { onCompleted } = params;
  const [updateCustomerMutation] = useCustomerUpdateMutation({
    onCompleted,
    onError: handleError,
    refetchQueries: ['currentCustomer'],
  });

  const handleUpdateEmail = useCallback(
    async (values: { email: string }) => {
      try {
        await updateCustomerMutation({ variables: { customer: { membership: { email: values.email } } } });
      } catch (e: any) {
        logInDev(e);
      }
    },
    [updateCustomerMutation],
  );

  return {
    handleUpdateEmail,
  };
};
