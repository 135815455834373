import { Offer } from '../../../../modules/offers/offer.types';
import { DisplayCategoryFragment, ExternalOfferFragment } from '../../../../graphql/types.generated';
import { OffersCarouselCategory } from './offers-carousel.types';

const getOffersForCategory = (offers: Offer[], categoryId: string) => {
  return offers.filter((offer) => offer.displayCategories.some((offerCategory) => offerCategory.id === categoryId));
};

const getFlattenBannersForBannerIds = (banners: ExternalOfferFragment[], bannerIds?: string[] | null) => {
  if (!bannerIds) {
    return [];
  }
  return bannerIds.reduce<ExternalOfferFragment[]>(
    (result, current) => [
      ...result,
      ...banners.filter(({ bannerConfigurationUuid }) => bannerConfigurationUuid === current),
    ],
    [],
  );
};

export const groupOfferAndCategories = (
  categories: DisplayCategoryFragment[] = [],
  offers: Offer[] = [],
  banners: ExternalOfferFragment[] = [],
): OffersCarouselCategory[] => {
  return categories.reduce<OffersCarouselCategory[]>((result, category) => {
    const categoryOffers = getOffersForCategory(offers, category.id);
    const categoryBanners = getFlattenBannersForBannerIds(banners, category.bannerIds);

    if (!categoryOffers.length && !categoryBanners.length) {
      // We do not show empty categories
      return result;
    }

    return [
      ...result,
      {
        id: category.id,
        title: category.name,
        offers: categoryOffers,
        banners: categoryBanners,
      },
    ];
  }, []);
};
