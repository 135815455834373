import React, { ReactNode, Suspense } from 'react';
import { Form } from 'react-final-form';
import { Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { messages } from '../../../modules/survey/survey.messages';
import { SizingMultiplier } from '../../../theme/constants';
import {
  SurveyQuestion,
  SurveyFormValues,
  SurveyAward,
  SurveyRFFFormProps,
  AwardVouchersSurvey,
} from '../../../modules/survey/survey.types';
import { AwardType } from '../../../modules/offers/award.types';
import { SurveySubmitButton } from '../../Survey/SurveySubmitButton';
import { PointsStrategy } from '../../../graphql/types.generated';
import { PointsChange } from '../../PointsChange/PointsChange';
import { getIsSurveySubmitDisabled } from '../../../modules/survey/survey.utils';
import { AwardGiftBoxButton } from '../../AwardGiftBoxButton/AwardGiftBoxButton';

// It should be lazy because animation library is about 40KB more which slows down initial render
const SurveySubmitAwardAnimationLazy = React.lazy(() => import('../../Survey/SurveySubmitAwardAnimation'));

export interface SurveyPopupFormProps {
  initialValues: SurveyFormValues;
  control: ReactNode;
  question: SurveyQuestion;
  award: SurveyAward;
  handleClose?(): void;
  hideCloseButton?: boolean;
  onSubmit: SurveyRFFFormProps['onSubmit'];
  pointsStrategy: PointsStrategy;
  onAnimationComplete: () => void;
  onAwardClick(offer: AwardVouchersSurvey['offer']): void;
}

export const SurveyPopupForm = ({
  handleClose,
  control,
  question,
  award,
  hideCloseButton,
  onAnimationComplete,
  initialValues,
  onSubmit,
  pointsStrategy,
  onAwardClick,
}: SurveyPopupFormProps) => {
  const hasAward = !!award;
  const isAwardPoints = hasAward && award.type === AwardType.Points;
  const isAwardVoucher = hasAward && award.type === AwardType.Vouchers;
  const arePointsUsed = pointsStrategy !== PointsStrategy.NotUsed;

  return (
    <Form<SurveyFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formProps) => {
        const { submitting, handleSubmit, submitSucceeded } = formProps;

        const isSurveyWithAwardSubmittedSuccessfully = hasAward && submitSucceeded;
        const isSubmitDisabled = getIsSurveySubmitDisabled(question, formProps);

        return (
          <form onSubmit={handleSubmit}>
            <Stack p={SizingMultiplier.lg} spacing={SizingMultiplier.md}>
              <Typography variant="h5" display="flex" justifyContent="space-between" alignItems="center">
                <FormattedMessage {...messages['survey-single-question:title']} />
                {isAwardPoints && arePointsUsed && (
                  <PointsChange
                    points={award.points}
                    pointsStrategy={pointsStrategy}
                    iconSize="md"
                    typographyProps={{ variant: 'h5' }}
                  />
                )}

                {isAwardVoucher && (
                  <AwardGiftBoxButton<AwardVouchersSurvey['offer']> entity={award.offer} onClick={onAwardClick} />
                )}
              </Typography>
              <Typography variant="body1">{question.question}</Typography>
              {/* Fallback should be null because we play animation only after successful form submit. */}
              {hasAward && (
                <Suspense fallback={null}>
                  <SurveySubmitAwardAnimationLazy
                    isDisplayed={isSurveyWithAwardSubmittedSuccessfully}
                    pointsStrategy={pointsStrategy}
                    award={award}
                    onAnimationComplete={onAnimationComplete}
                  />
                </Suspense>
              )}

              {!isSurveyWithAwardSubmittedSuccessfully && (
                <>
                  {control}
                  <SurveySubmitButton
                    award={award}
                    submitting={submitting}
                    disabled={isSubmitDisabled}
                    pointsStrategy={pointsStrategy}
                  />
                  {!hideCloseButton && handleClose ? (
                    <Button variant="text" onClick={handleClose} fullWidth>
                      <FormattedMessage {...buttonTextMessages['button-text:close']} />
                    </Button>
                  ) : null}
                </>
              )}
            </Stack>
          </form>
        );
      }}
    />
  );
};
