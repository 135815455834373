import { onError } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { isGlobalErrorCode, globalErrorHandler } from '../../global-error-handler';

export const globalErrorHandlerMiddleware = onError(({ graphQLErrors, networkError }) => {
  if ((networkError as ServerError)?.statusCode && (networkError as ServerError).statusCode !== 200) {
    Sentry.captureException(networkError);
  }

  graphQLErrors?.forEach(({ extensions }) => {
    const code = extensions?.code;
    const exceptionCode = extensions?.exception?.code;
    if (isGlobalErrorCode(code)) {
      globalErrorHandler[code]();
    }
    if (isGlobalErrorCode(exceptionCode)) {
      globalErrorHandler[exceptionCode]();
    }
  });
});
