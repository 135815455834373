import React from 'react';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SelectPumpCarousel } from './SelectPumpCarousel';
import { SizingMultiplier } from '../../../../../theme/constants';
import { ModalPaper } from '../../../../../components/modals';

export interface IPumpSelectorProps {
  pumps: string[];
  selectPump(pump: string): void;
}

export const PumpSelector: React.FC<IPumpSelectorProps> = ({ pumps, selectPump }) => {
  return (
    <ModalPaper>
      <Stack py={5} spacing={SizingMultiplier.xl}>
        <Typography variant="h6" color="textSecondary" textAlign="center">
          <FormattedMessage
            id="selectPump:selectPumpCarouselTitle"
            defaultMessage="Select your pump"
            description="Select Pump: title for pump selector carousel"
          />
        </Typography>
        <SelectPumpCarousel pumps={pumps} onPumpSelect={selectPump} />
      </Stack>
    </ModalPaper>
  );
};
