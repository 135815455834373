import React, { useCallback } from 'react';
import { Divider, Paper, Stack, Typography, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SizingMultiplier } from '../../theme/constants';
import { Offer } from '../../modules/offers/offer.types';
import { getOfferCardMarketingContent } from '../../components/OfferCard/offer-card.utils';
import { OfferCard, OfferLabel } from '../../components/OfferCard';
import { messages } from './messages';

export interface IReadyToUseProps {
  offers: Offer[];
  getOfferOnClick: (offer: Offer) => (() => unknown) | undefined;
  loading: boolean;
}

export const ReadyToUse: React.FC<IReadyToUseProps> = ({ offers, getOfferOnClick, loading }) => {
  const renderOffer = useCallback(
    (offer: Offer) => {
      const onClick = getOfferOnClick(offer);
      const label: OfferLabel = {
        type: 'Reward',
        voucherCount: offer.voucherCount,
      };
      const marketingContent = getOfferCardMarketingContent(offer);

      return (
        <OfferCard
          key={offer.id}
          disabled={false}
          marketingContent={marketingContent}
          onClick={onClick}
          label={label}
          accessoryType={onClick ? 'disclosureIndicator' : 'none'}
          // Ready to use is not technically designed to show progress or diff
          pointsDiff={null}
          progress={null}
        />
      );
    },
    [getOfferOnClick],
  );

  return (
    <Stack minHeight="100%" sx={{ background: ({ palette }) => palette.readyToUse.background }}>
      <Stack
        gap={SizingMultiplier.sm}
        px={SizingMultiplier.md}
        py={({ mixins }) => `${mixins.toolbar.minHeight}px`}
        textAlign="center"
        color="common.white">
        <Typography variant="h3" component="h2" textTransform="none">
          <FormattedMessage {...messages.headerTitle} />
        </Typography>
        <Typography variant="body2" maxWidth={296} alignSelf="center">
          <FormattedMessage {...messages.headerSubtitle} />
        </Typography>
      </Stack>
      <Paper
        sx={{
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          flex: 1,
          overflowY: 'auto',
        }}>
        {!offers.length && !loading ? (
          <Box textAlign="center" p={SizingMultiplier.md}>
            <Typography variant="subtitle1" gutterBottom>
              <FormattedMessage {...messages.noOffersTitle} />
            </Typography>
            <Typography color="textSecondary">
              <FormattedMessage {...messages.noOffersDescription} />
            </Typography>
          </Box>
        ) : (
          <Stack divider={<Divider sx={{ mx: SizingMultiplier.md }} />}>{offers.map(renderOffer)}</Stack>
        )}
      </Paper>
    </Stack>
  );
};
