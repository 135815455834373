export enum AppRoutePath {
  EventTracker = '/event/:id',
  RequestLoginLink = '/auth/request-magic-link',
  LoginsWithLink = '/login/:token',
  Auth = '/auth/*',
  AuthOtpSms = '/auth/otp/sms',
  AuthOtpPhoneCall = '/auth/otp/phone-call',
  AuthOtpSmsRelative = 'otp/sms',
  AuthOtpPhoneCallRelative = 'otp/phone-call',
  AuthWaitMessage = '/auth/wait-message',
  Home = '/home',
  ReadyToUseOffers = '/home/ready-to-use',
  TotalAvailableRollbackOffers = '/home/total-available-rollback',
  PayAtPump = '/pay-at-pump',
  PayAtPumpSelectPump = '/pay-at-pump/select-pump/:storeId',
  PayAtPumpFuelingStatus = '/pay-at-pump/fueling-status/:transactionId',
  PayAtPumpReceipt = '/pay-at-pump/receipt/:transactionId',
  PayInside = '/pay-inside',
  Profile = '/profile',
  CommunicationPreferences = '/profile/communication',
  ProductInterests = '/profile/product-interests',
  TransactionHistory = '/transactions',
  TransactionActivateDetails = '/transactions/loyalty/:transactionId',
  TransactionActivateDetailsNested = 'loyalty/:transactionId',
  TransactionMobileDetailsNested = 'mobile/:transactionId',
  Wallet = '/profile/wallet',
  StoreLocator = '/store-locator',
  StoreLocatorList = '/store-locator/list',
  StoreDetails = '/store-details/:storeId',
  DeleteAccount = '/delete-account',
  IdentityVerification = 'identity-verification',
  AgeVerification = 'age-verification',
  CrashMe = '/crash-me',
  Surveys = '/surveys',
  GuestSurvey = '/s/:id',
  OfferDetails = 'offers/:offerId',
  PreferredFuelGrade = '/profile/preferred-fuel-grade',
  SurveyDetails = '/surveys/:surveyUuid',
}
