import React, { useCallback, useState } from 'react';
import { Tab, Divider, Tabs, Box, TabsProps } from '@mui/material';
import { defineMessage, useIntl } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

import { Page } from '../../components/Layout/Page/Page';
import { BackButton } from '../../components/Layout/NavigationBar/BackButton/BackButton';
import { NavigationBar } from '../../components/Layout/NavigationBar/NavigationBar';
import { VendorLogo } from '../../components/Layout/NavigationBar/VendorLogo/VendorLogo';
import { messages } from './transaction-history.messages';
import { MobileTransactionsListController } from './mobile-transactions-list.controller';
import { LoyaltyActionsListController } from './loyalty-actions-list.controller';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { TransactionReceiptController } from '../TransactionReceipt/TransactionReceiptController';
import { AppRoutePath } from '../AppRoutePath';
import { ReceiptController } from '../PayAtPump/Receipt/ReceiptController';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';

const TabPanel = (props: any) => {
  const { children, value, name } = props;

  return (
    <Box
      display={value === name ? 'flex' : undefined}
      flex="1"
      flexDirection="column"
      role="tabpanel"
      hidden={value !== name}
      id={`transaction-history-tabpanel-${name}`}
      aria-labelledby={`transaction-history-tabpanel-${name}`}>
      {value === name && children}
    </Box>
  );
};

export type TransactionHistoryTab = 'mobile' | 'loyalty';

export const TransactionHistory: React.FC = () => {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = useState<TransactionHistoryTab>('mobile');
  const { triggerEvent } = useRudderStack();

  const triggerAnalytics = useCallback(
    (tab: TransactionHistoryTab) => {
      if (tab === 'mobile') {
        triggerEvent('transaction_history_receipts_tab_clicked');
      }
      if (tab === 'loyalty') {
        triggerEvent('transaction_history_loyalty_transactions_tab_clicked');
      }
    },
    [triggerEvent],
  );

  const handleTabChange = useCallback<NonNullable<TabsProps['onChange']>>(
    (event, value) => {
      triggerAnalytics(value);
      setSelectedTab(value);
    },
    [triggerAnalytics],
  );

  return (
    <Page>
      <NavigationBar left={<BackButton />} center={<VendorLogo />} />

      <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
        <Tab value="mobile" label={intl.formatMessage(messages['transaction-history:transactions:tab-title'])} />
        <Tab value="loyalty" label={intl.formatMessage(messages['transaction-history:loyalty-activities:tab-title'])} />
      </Tabs>
      <Divider />
      <TabPanel value={selectedTab} name="mobile">
        <HtmlTitle title={defineMessage(messages['transaction-history:transactions:html-title'])} />

        <MobileTransactionsListController />
      </TabPanel>
      <TabPanel value={selectedTab} name="loyalty">
        <HtmlTitle title={defineMessage(messages['transaction-history:loyalty-activities:html-title'])} />
        <LoyaltyActionsListController />
      </TabPanel>
      <Routes>
        <Route path={AppRoutePath.TransactionActivateDetailsNested} element={<TransactionReceiptController />} />
        <Route
          path={AppRoutePath.TransactionMobileDetailsNested}
          element={<ReceiptController modal gratitudeLabelHidden />}
        />
      </Routes>
    </Page>
  );
};
