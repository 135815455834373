import { ApolloClient } from '@apollo/client';

import { IPaymentProvider, TokenizeCardInput, TokenizeCardResponse } from '../../payment-provider.types';
import {
  GetFiservClientAccessTokenDocument,
  GetFiservClientAccessTokenQuery,
  PaymentAccountType,
  PaymentInstrumentType,
} from '../../../../../graphql/types.generated';
import { getPaymentInstrumentTypeByCardType } from '../../../payments.utils';
import { PaymentGatewayTokenizationParameters } from '../../../GooglePay/types';
import { createToken, mapCardDataToAccountInput } from './fiserv-client.utils';
import { FISERV_TOKEN_TYPE_CLAIM_CHECK_NONCE } from './fiserv.constants';
import { encryptUsing } from './fiserv-encryption.utils';

export class PaymentProviderFiserv implements IPaymentProvider {
  private apolloClient: ApolloClient<unknown>;
  private googlePayGatewayParameters: PaymentGatewayTokenizationParameters | null = null;

  constructor(
    apolloClient: ApolloClient<unknown>,
    googlePayGatewayParameters: { gateway: string; gatewayMerchantId: string } | null,
  ) {
    this.apolloClient = apolloClient;
    this.googlePayGatewayParameters = googlePayGatewayParameters;
  }

  tokenizeCard = async (params: TokenizeCardInput): Promise<TokenizeCardResponse> => {
    const { data } = await this.apolloClient.query<GetFiservClientAccessTokenQuery>({
      query: GetFiservClientAccessTokenDocument,
      fetchPolicy: 'network-only',
    });
    const { algorithm, publicKey, baseUrl, apiKey, oAuthToken, fdCustomerId } = data.getFiservClientAccessToken;

    const encrypt = encryptUsing(algorithm, publicKey);

    const tokenizeResponse = await createToken({
      baseUrl,
      apiKey,
      oAuthToken,
      body: {
        fdCustomerId,
        referenceToken: { tokenType: FISERV_TOKEN_TYPE_CLAIM_CHECK_NONCE },
        account: await mapCardDataToAccountInput(encrypt, params),
      },
    });

    return {
      oAuthToken,
      nonce: JSON.stringify((await tokenizeResponse.json()).token),
      accountType: params.accountType,
      paymentInstrumentType:
        params.accountType === PaymentAccountType.Credit
          ? getPaymentInstrumentTypeByCardType(params.cardType)
          : PaymentInstrumentType.Prepaid,
      externalRequestData: null,
    };
  };

  tokenizeApplePay = async () => null;

  getGooglePayGatewayParameters = () => {
    if (!this.googlePayGatewayParameters) {
      throw new Error('Payment provider GooglePay Gateway is not specified');
    }
    return this.googlePayGatewayParameters;
  };
  // Fiserv does not use "nonce" for transaction start
  getGooglePayTokenNonce = () => null;
}
