import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';

import { PoweredByOpenCommerce } from '../../components/Layout/PoweredByOpenCommerce/PoweredByOpenCommerce';
import { ScreenLoaderBackdrop } from '../../components/ScreenLoader/ScreenLoader';

export type AddCardLayoutProps = {
  loading: boolean;
  children: ReactNode;
};

export const AddCardLayout = ({ loading, children }: AddCardLayoutProps) => {
  return (
    <>
      <ScreenLoaderBackdrop open={loading} />
      <Stack justifyContent="space-between" flexGrow="1" overflow="auto">
        {children}
        <PoweredByOpenCommerce />
      </Stack>
    </>
  );
};
