import React from 'react';

import { PureBackdrop } from '../../PureBackdrop/PureBackdrop';
import { PureCircularProgress } from '../../PureCircularProgress/PureCircularProgress';

export const RouteLoading: React.FC = () => {
  return (
    <PureBackdrop>
      <PureCircularProgress />
    </PureBackdrop>
  );
};
