import { TypePolicies } from '@apollo/client';

import { awardTypePolicy } from '../offers/award.type-policy';
import { membershipTypePolicy } from '../membership/membership.type-policy';
import { externalOffersTypePolicy } from '../external-loyalty/external-offers.type-policy';
import { surveyTypePolicy } from '../survey/survey.type-policy';

export const apolloCacheTypePolicies: TypePolicies = {
  Customer: {
    // Needed to make cache working properly
    keyFields: ['uuid'],
  },
  CommunicationPreference: {
    keyFields: false,
  },
  SiteSystemCarWashItem: {
    // SiteSystemCarWashItem does not have any ID fields for caching
    keyFields: ['productCode', 'posCode', 'posCodeModifier'],
  },
  LoyaltyEvent: { keyFields: ['eventType', 'createdAt'] },
  TotalAvailableRollback: { keyFields: ['locationUuid', 'fuelProductGroup', ['id']] },
  ...awardTypePolicy,
  ...membershipTypePolicy,
  ...externalOffersTypePolicy,
  ...surveyTypePolicy,
};
