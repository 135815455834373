/* eslint-disable no-undef */
import { IPaymentItem } from '../payments.types';
import { ApplePayCardNetwork } from '../../../graphql/types.generated';

// TODO: probably we should not export it?
// @ts-ignore
export const { ApplePaySession, ApplePayJS } = window;

export type ApplePayMerchantCapability = ApplePayJS.ApplePayMerchantCapability;
export type ApplePayPaymentRequest = ApplePayJS.ApplePayPaymentRequest;
export type ApplePayLineItem = ApplePayJS.ApplePayLineItem;
export type ApplePayLineItemType = ApplePayJS.ApplePayLineItemType;
export type ApplePayPaymentToken = ApplePayJS.ApplePayPaymentToken;

export interface IApplePayPlugin {
  configure(options: { merchantIdentifier: string }): Promise<void>;
  canMakePayments(options: IGetApplePayCanMakeRequestData): Promise<{ available: boolean }>;
  showPaymentRequest(
    request: ApplePayPaymentRequest,
    getApplePaySession?: () => Promise<ApplePaySessionObject | null>,
  ): Promise<{ requestId: string; transactionId: string; transactionData: string }>;
  updateTransactionStatus(options: {
    requestId: string;
    statusCode: number;
    errors?: Record<string, string>[];
  }): Promise<void>;
}

export interface IGetApplePayPaymentRequestParams {
  merchantName: string;
  supportedNetworks: ApplePayCardNetwork[];
  paymentItems: IPaymentItem[];
}

export interface IGetApplePayCanMakeRequestParams {
  merchantId: string;
  supportedNetworks: ApplePayCardNetwork[];
}

export interface IGetApplePayCanMakeRequestData {
  merchantId: string;
  merchantCapabilities: string[];
  supportedNetworks: string[];
}

export enum APPLE_PAYMENT_STATUS {
  SUCCESS = 0,
  FAILURE = 1,
}

export enum ApplePayExternalCardNetwork {
  Amex = 'AMEX',
  CartesBancaires = 'CARTES_BANCAIRES',
  ChinaUnionPay = 'CHINA_UNION_PAY',
  Discover = 'DISCOVER',
  Eftpos = 'EFTPOS',
  Electron = 'ELECTRON',
  Elo = 'ELO',
  Interac = 'INTERAC',
  Jcb = 'JCB',
  Mada = 'MADA',
  Maestro = 'MAESTRO',
  MasterCard = 'MASTER_CARD',
  PrivateLabel = 'PRIVATE_LABEL',
  Visa = 'VISA',
  VPay = 'V_PAY',
}

export enum APPLE_PAY_ERROR_CODE {
  CANCELED = '100',
}

export type ApplePaySessionObject = object;
