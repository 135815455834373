import {
  CustomerPaymentInstrumentCreate,
  PaymentAccountType,
  PaymentInstrumentFragment,
  PaymentInstrumentType,
} from '../../graphql/types.generated';
import { PaymentInstrument, PaymentInstrumentOrder } from './wallet.types';
import { paymentTypeToPaymentIconMap } from './wallet.constants';
import { TokenizeCardInput, TokenizeCardResponse } from '../../lib/payments/PaymentProvider/payment-provider.types';

export const isDefaultPaymentInstrument = ({ isDefault }: PaymentInstrument): boolean => isDefault;

export const isApplePayFragment = (fragment: PaymentInstrumentFragment | null): boolean => {
  if (!fragment) {
    return false;
  }
  return (
    fragment.accountType === PaymentAccountType.ApplePay || fragment.paymentType === PaymentInstrumentType.ApplePay
  );
};

export const isGooglePayFragment = (fragment: PaymentInstrumentFragment | null): boolean => {
  if (!fragment) {
    return false;
  }
  return (
    fragment.accountType === PaymentAccountType.GooglePay ||
    (!!fragment.paymentType &&
      [PaymentInstrumentType.GooglePay, PaymentInstrumentType.GoogleWallet].includes(fragment.paymentType))
  );
};

export type SanitizePaymentInstrumentFragmentContext = {
  availableAccountTypes: Readonly<PaymentAccountType[]>;
};
export const sanitizePaymentInstrumentFragment = (
  fragment: PaymentInstrumentFragment,
  context: SanitizePaymentInstrumentFragmentContext,
): PaymentInstrument => {
  const canBeUsedToPay = context.availableAccountTypes.includes(fragment.accountType);

  return {
    alias: fragment.alias,
    accountType: fragment.accountType,
    paymentType: fragment.paymentType,
    accountNumber: fragment.accountNumber,
    providerNickName: fragment.providerNickName,
    last4: fragment.displayNumber,
    address: fragment?.address?.zipCode
      ? {
          zipCode: fragment.address.zipCode,
        }
      : null,
    uuid: fragment.uuid,
    displayName: fragment.displayNumber || '',
    // balance: fragment.balance,
    balance: null,
    isDefault: !!fragment.isDefault,
    canBeDeleted: true,
    canBeUsedToPay,
    order: canBeUsedToPay ? PaymentInstrumentOrder.Default : PaymentInstrumentOrder.Disabled,
  };
};

const FIRST6_LENGTH = 6;
const LAST4_LENGTH = 4;
export const getCardNumberAttributes = (cardNumber: string) => {
  const first6 = cardNumber.slice(0, FIRST6_LENGTH);
  const last4 = cardNumber.slice(cardNumber.length - LAST4_LENGTH);
  return { first6, last4 };
};

export const buildCreatePaymentInstrumentPayload = (
  input: TokenizeCardInput,
  tokenizationData: TokenizeCardResponse,
): CustomerPaymentInstrumentCreate => {
  const { first6, last4 } = getCardNumberAttributes(input.cardNumber);

  if (input.accountType === PaymentAccountType.Prepaid) {
    return {
      accountType: tokenizationData.accountType,
      paymentType: PaymentInstrumentType.Prepaid,
      binRange: first6,
      last4,
      nonce: tokenizationData.nonce,
      fiservOAuthToken: tokenizationData.oAuthToken,
      name: null,
      alias: input.alias,
      accountNumber: null,
      encryptedPaymentInstrumentDetails: null,
      // Passing `setAsDefault: null` persists default logic on API side
      setAsDefault: null,
      externalRequestData: null,
    };
  }

  return {
    accountType: tokenizationData.accountType,
    last4,
    binRange: first6,
    nonce: tokenizationData.nonce,
    paymentType: tokenizationData.paymentInstrumentType,
    fiservOAuthToken: tokenizationData.oAuthToken,
    name: null,
    alias: input.alias || null,
    accountNumber: null,
    address: {
      zipCode: input.zipCode,
      alias: null,
      street1: null,
      street2: null,
      city: null,
      state: null,
      geoLocation: null,
    },
    expiration: {
      expirationMonth: input.expirationDateMonth,
      expirationYear: input.expirationDateYear,
    },
    encryptedPaymentInstrumentDetails: null,
    // Passing `setAsDefault: null` persists default logic on API side
    setAsDefault: null,
    externalRequestData: tokenizationData.externalRequestData ?? null,
  };
};

// TODO: get reid of "component" and start using "rendered" element
// TODO: start using all over the project
// TODO: add typing
export const getPaymentInstrumentListItemInfo = (paymentInstrument: PaymentInstrument) => {
  const { paymentType, displayName, last4 } = paymentInstrument;
  const PaymentInstrumentTypeIcon = paymentType ? paymentTypeToPaymentIconMap[paymentType] : null;
  const paymentInstrumentName = last4 ? `•••• ${last4}` : displayName || 'Unknown';
  return { PaymentInstrumentTypeIcon, paymentInstrumentName };
};
