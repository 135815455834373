import {
  OffersQueryVariables,
  BaseOffersQueryVariables,
  OfferEngagementType,
  OfferPresentationType,
  OfferVisibleStatus,
  PublicationStatusType,
  VoucherSourceType,
  VoucherState,
} from '../../graphql/types.generated';

/**
 * Determines when the UI should show
 * a progress bar [------    ]
 * instead of punches (*)(*)(*)()
 */
export const OFFER_MAX_PUNCH_CARD_REQUIRED_PROGRESS = 10 as const;

export const OPTIMIZED_OFFERS_FILTER = Object.freeze({
  first: 100,
  deleted: false,
  publicationStatusEquals: PublicationStatusType.Published,
  visibleStatusEquals: OfferVisibleStatus.Visible,
  presentationTypeIncludes: [OfferPresentationType.Banner, OfferPresentationType.Default],
});

export const OPTIMIZED_HOME_OFFERS_FILTER: OffersQueryVariables = Object.freeze({
  ...OPTIMIZED_OFFERS_FILTER,
  engagementTypeEquals: null,
  engagementTypeExcludes: [OfferEngagementType.Base],
});

export const OPTIMIZED_BASE_OFFERS_FILTER: BaseOffersQueryVariables = Object.freeze({
  ...OPTIMIZED_OFFERS_FILTER,
  engagementTypeEquals: OfferEngagementType.Base,
});

export const OPTIMIZED_VOUCHERS_FILTER = Object.freeze({
  returnable: true,
  state: VoucherState.Unfulfilled,
  sourceType: VoucherSourceType.OfferPurchase,
});
