import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  PrimaryTransactionStatus,
  StartTransactionMutation,
  TransactionType,
  useStartTransactionMutation,
  useStorePumpInfoQuery,
} from '../../../../graphql/types.generated';
import { sanitizeStorePumpInfo } from '../../../../modules/stores/utils';
import { IStoreCarWashOption, IStorePumpInfo } from '../../../../modules/stores/types';
import { useGetNavigationActions } from '../../../../hooks/useGetNavigationActions';
import { useLoader } from '../../../../lib/loader/LoaderProvider';
import { buildPaymentItems } from '../../../../lib/payments/payments.utils';
import { IPaymentItem, PAYMENT_INSTRUMENT_TYPE } from '../../../../lib/payments/payments.types';
import { useCreateDigitalWalletPaymentRequest } from '../../../../lib/payments/hooks/useCreateDigitalWalletPaymentRequest';
import { useRudderStack } from '../../../../lib/rudderStack/useRudderStack';
import { buildCarWashInput } from './utils';
import { useShowGenericErrorSnackbar } from '../../../../hooks/useShowGenericErrorSnackbar';
import { useCarWashController } from './useCarWashController';
import { usePaymentInstrumentsController } from './usePaymentInstrumentsController';
import { buildTransactionStartInput, getKountSessionId } from '../../../../modules/transaction/utils';
import { PaymentInstrument } from '../../../../modules/wallet/wallet.types';
import { ListSelector } from '../../../../hooks/useListController';
import { useWalletPinCodePopup } from '../../../../components/modals';

export interface IUseSelectPumpControllerReturns {
  // region | Pump info
  selectedPump: string | null;
  shouldPrintReceipt: boolean;
  carWashListController: ListSelector<IStoreCarWashOption>;
  selectPump(pump: string): void;
  deselectPump(): void;
  togglePrintReceipt(value?: boolean): void;
  // endregion
  // region | Payment instruments
  paymentInstrumentsListSelector: ListSelector<PaymentInstrument>;
  isCardsAvailable: boolean;
  isPaymentInstrumentsLoading: boolean;
  selectedPaymentInstrumentType: PAYMENT_INSTRUMENT_TYPE | null;
  handlePurchase(type: PAYMENT_INSTRUMENT_TYPE, paymentInstrumentId: string): void;
  isWalletSizeLimitReached: boolean;
  isWalletEnabled: boolean;
  // endregion
  // region | Common
  loading: boolean;
  store: IStorePumpInfo | null;
  // endregion
}

export interface IUseSelectPumpControllerParams {
  onSiteLoadFailed: () => void;
}

export const useSelectPumpController = ({
  onSiteLoadFailed,
}: IUseSelectPumpControllerParams): IUseSelectPumpControllerReturns => {
  const intl = useIntl();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { turnLoader } = useLoader();
  const { triggerEvent } = useRudderStack();
  const { replaceToFuelingStatus } = useGetNavigationActions();
  const { open: openWalletPinCodePopup } = useWalletPinCodePopup();

  const { storeId = '' } = useParams<{ storeId: string }>();
  const createWalletPaymentRequest = useCreateDigitalWalletPaymentRequest();

  const [selectedPump, setSelectedPump] = useState<string | null>(null);
  const [shouldPrintReceipt, setShouldPrintReceipt] = useState(true);

  const togglePrintReceipt = useCallback(
    (value = !shouldPrintReceipt) => setShouldPrintReceipt(value),
    [shouldPrintReceipt],
  );

  const onTransactionStarted = useCallback(
    (data: StartTransactionMutation) => {
      if (data.transaction === null || data.transaction.primaryStatus === PrimaryTransactionStatus.Failed) {
        showGenericErrorSnackbar();
        return;
      }
      replaceToFuelingStatus(data.transaction.uuid);
    },
    [replaceToFuelingStatus, showGenericErrorSnackbar],
  );

  const { data, loading } = useStorePumpInfoQuery({ variables: { id: storeId }, onError: onSiteLoadFailed });
  const [startTransaction] = useStartTransactionMutation({ onCompleted: onTransactionStarted });
  const store = data?.stores?.edges?.[0]?.node;

  const storePumpInfo = useMemo<IUseSelectPumpControllerReturns['store']>(
    () => sanitizeStorePumpInfo(intl, store),
    [intl, store],
  );

  const rawCarWashOptions = useMemo(() => storePumpInfo?.carWashOptions || [], [storePumpInfo?.carWashOptions]);
  const { carWashListController } = useCarWashController({
    rawCarWashOptions,
  });

  const paymentItems = useMemo<IPaymentItem[]>(
    () => buildPaymentItems({ selectedCarWashOption: carWashListController.selectedListItem }),
    [carWashListController.selectedListItem],
  );

  const selectPump = useCallback<IUseSelectPumpControllerReturns['selectPump']>(
    (pumpNumber) => {
      triggerEvent('pay_at_pump_select_pump');
      setSelectedPump(pumpNumber);
    },
    [triggerEvent],
  );
  const deselectPump = useCallback<IUseSelectPumpControllerReturns['deselectPump']>(
    () => setSelectedPump(null),
    [setSelectedPump],
  );

  const {
    paymentInstruments,
    paymentInstrumentsListSelector,
    selectedPaymentInstrumentType,
    isPaymentInstrumentsLoading,
    isCardsAvailable,
    isWalletSizeLimitReached,
    isWalletEnabled,
  } = usePaymentInstrumentsController();

  const handlePurchase = useCallback<IUseSelectPumpControllerReturns['handlePurchase']>(
    async (type, paymentInstrumentId) => {
      try {
        // TODO: handle if (!storePumpInfo) return;
        if (!storePumpInfo) {
          return;
        }

        const paymentInstrument = paymentInstruments.find(({ uuid }) => uuid === paymentInstrumentId);
        if (!paymentInstrument) {
          return;
        }

        turnLoader(true);

        const { token, nonce, completePayment, cancelPayment, canceled } = await createWalletPaymentRequest(
          type,
          paymentItems,
        );

        if (canceled) {
          turnLoader(false);
          return;
        }

        const kountSessionId = await getKountSessionId();

        let pinCode: string | null = null;
        if (type === PAYMENT_INSTRUMENT_TYPE.CARD) {
          turnLoader(false);
          pinCode = await openWalletPinCodePopup();
          if (!pinCode) {
            return;
          }
        }

        try {
          turnLoader(true);
          const transactionInput = buildTransactionStartInput({
            transactionType: TransactionType.AtPump,
            // Fueling section
            siteId: storePumpInfo.siteId,
            locationId: storePumpInfo.id,
            fuelingPosition: selectedPump,
            carWash: buildCarWashInput(carWashListController.selectedListItem),
            // Payment sections
            token,
            nonce,
            pinCode,
            kountSessionId,
            paymentType: type,
            paymentInstrument,
            // Default car wash option is "No Car Wash".
            // We must always print receipt when car-wash is not "No Car Wash". Receipt is going to be printed even if we send false
            printReceipt: carWashListController.selectedListItem ? true : shouldPrintReceipt,
          });
          await startTransaction({
            variables: {
              input: transactionInput,
            },
          });
        } catch (e) {
          if (cancelPayment !== undefined) {
            await cancelPayment();
          }
          throw e;
        }

        if (completePayment !== undefined) {
          await completePayment();
        }
      } catch {
        showGenericErrorSnackbar();
      } finally {
        turnLoader(false);
      }
    },
    [
      storePumpInfo,
      paymentInstruments,
      turnLoader,
      createWalletPaymentRequest,
      paymentItems,
      openWalletPinCodePopup,
      selectedPump,
      carWashListController.selectedListItem,
      shouldPrintReceipt,
      startTransaction,
      showGenericErrorSnackbar,
    ],
  );

  return {
    // region | Pump info
    selectedPump,
    shouldPrintReceipt,
    carWashListController,
    selectPump,
    deselectPump,
    togglePrintReceipt,
    // endregion
    // region | Payment instruments
    paymentInstrumentsListSelector,
    selectedPaymentInstrumentType,
    isPaymentInstrumentsLoading,
    isCardsAvailable,
    handlePurchase,
    isWalletSizeLimitReached,
    isWalletEnabled,
    // endregion
    // region | Common
    loading,
    store: storePumpInfo,
    // endregion
  };
};
