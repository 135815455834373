import React, { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { Page } from '../../../components/Layout/Page/Page';
import { PageContent } from '../../../components/Layout/PageContent/PageContent';
import { BackButton } from '../../../components/Layout/NavigationBar/BackButton/BackButton';
import { HtmlTitle } from '../../../components/router/HtmlTitle';
import { NavigationBar } from '../../../components/Layout/NavigationBar/NavigationBar';
import { VendorLogo } from '../../../components/Layout/NavigationBar/VendorLogo/VendorLogo';

type SelectPumpLayoutProps = {
  children: ReactNode;
};

export const SelectPumpLayout: React.FC<SelectPumpLayoutProps> = ({ children }) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'selectPump:htmlTitle',
          defaultMessage: 'Select pump',
          description: 'HTML title tag for Select pump page',
        })}
      />
      <NavigationBar left={<BackButton />} center={<VendorLogo />} />
      <PageContent bgcolor="background.paper">{children}</PageContent>
    </Page>
  );
};
