import { PrimaryTransactionStatus, SecondaryTransactionStatus } from '../../../graphql/types.generated';

const POST_AUTHORIZING_PRIMARY_STATES: Array<PrimaryTransactionStatus | null> = [
  PrimaryTransactionStatus.Active,
  PrimaryTransactionStatus.Completed,
];

const POST_AUTHORIZING_SECONDARY_STATES: Array<SecondaryTransactionStatus | null> = [
  SecondaryTransactionStatus.Authorized,
  SecondaryTransactionStatus.PumpEnabling,
  SecondaryTransactionStatus.PumpEnabled,
  SecondaryTransactionStatus.Fueling,
  SecondaryTransactionStatus.Settling,
  SecondaryTransactionStatus.Settled,
  SecondaryTransactionStatus.GettingReceipt,
  SecondaryTransactionStatus.CancellingAuthorized,
  SecondaryTransactionStatus.CancellingPumpEnabling,
  SecondaryTransactionStatus.CancellingPumpEnabled,
  SecondaryTransactionStatus.UnableToGetReceipt,
  SecondaryTransactionStatus.Closed,
];

export const isPumpAuthorized = (
  primaryStatus: PrimaryTransactionStatus | null,
  secondaryStatus: SecondaryTransactionStatus | null,
): boolean => {
  return (
    POST_AUTHORIZING_PRIMARY_STATES.includes(primaryStatus) &&
    POST_AUTHORIZING_SECONDARY_STATES.includes(secondaryStatus)
  );
};
