import React, { ReactEventHandler } from 'react';
import { Box, BoxProps } from '@mui/material';

export interface IPageContentProps {
  children?: React.ReactNode;
  onScroll?: ReactEventHandler<HTMLDivElement>;
  overflow?: BoxProps['overflow'];
  bgcolor?: BoxProps['bgcolor'];
  safeAreaBottomOffsetEnabled?: boolean;
}

export const PageContent = React.forwardRef<HTMLDivElement, IPageContentProps>((props, ref) => {
  const { children, onScroll, overflow = 'auto', bgcolor = 'background.default', safeAreaBottomOffsetEnabled } = props;
  return (
    <Box
      ref={ref}
      onScroll={onScroll}
      display="flex"
      flex="1 1 0"
      flexDirection="column"
      position="relative"
      overflow={overflow}
      bgcolor={bgcolor}
      // https://developer.mozilla.org/en-US/docs/Web/CSS/contain
      sx={{
        contain: 'size layout style',
        mb: safeAreaBottomOffsetEnabled ? 'var(--ion-safe-area-bottom)' : undefined,
      }}>
      {children}
    </Box>
  );
});
