import { cacheStorage } from '../cache-layer/cacheStorage';
import { OfferFragment } from '../../graphql/types.generated';

const MEMBERSHIP_KEY = 'current_customer';
const MEMBERSHIP_OFFERS_KEY = `${MEMBERSHIP_KEY}_offers`;

export const currentCustomerCache = Object.freeze({
  getOffers: () => cacheStorage.getItem<OfferFragment[] | null>(MEMBERSHIP_OFFERS_KEY),
  cacheOffers: (offers: OfferFragment[] | null) => cacheStorage.setItem(MEMBERSHIP_OFFERS_KEY, offers),
});
