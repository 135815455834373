import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import { useGetTransactionQuery } from '../../../../graphql/types.generated';
import { IReceipt, IReceiptOption } from '../../../../modules/transaction/types';
import { mapReceipt } from '../../../../modules/transaction/utils';
import { useGetTransactionReceiptOptions } from '../../../../modules/transaction/hooks/useGetTransactionReceiptOptions';
import { useShowGenericErrorSnackbar } from '../../../../hooks/useShowGenericErrorSnackbar';

export interface IUseReceiptControllerReturns {
  loading: boolean;
  receipt: IReceipt | null;
  options: IReceiptOption[];
}

export const useReceiptController = (): IUseReceiptControllerReturns => {
  const handleError = useShowGenericErrorSnackbar();
  const { transactionId = '' } = useParams<{ transactionId: string }>();

  const { data, loading } = useGetTransactionQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      uuid: transactionId,
    },
  });

  const receipt = mapReceipt(data?.transaction);
  const getTransactionReceiptOptions = useGetTransactionReceiptOptions();
  const options = useMemo(() => getTransactionReceiptOptions(receipt), [getTransactionReceiptOptions, receipt]);

  useEffect(() => {
    if (data?.transaction?.uuid && !receipt) {
      handleError();
    }
  }, [receipt, data?.transaction?.uuid, handleError]);

  return {
    loading,
    receipt,
    options,
  };
};
