import React, { ReactNode } from 'react';

import classes from './Page.module.css';

export type PageProps = {
  children: ReactNode;
};

export const Page: React.FC<PageProps> = ({ children }) => {
  return <div className={classes.page}>{children}</div>;
};
