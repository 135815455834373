import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ProfileIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 2a10 10 0 00-7.35 16.76 10.001 10.001 0 0014.7 0A10 10 0 0012 2zm0 18a8 8 0 01-5.55-2.25 6 6 0 0111.1 0A8 8 0 0112 20zm-2-10a2 2 0 114 0 2 2 0 01-4 0zm8.91 6A8 8 0 0015 12.62a4 4 0 10-6 0A8 8 0 005.09 16 7.92 7.92 0 014 12a8 8 0 0116 0 7.921 7.921 0 01-1.09 4z" />
    </SvgIcon>
  );
};
