import React from 'react';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { messages } from '../messages';

export interface IAppVersionProps {
  version: string;
}

export const AppVersion: React.FC<IAppVersionProps> = ({ version }) => {
  const intl = useIntl();
  return (
    <Typography variant="body2" align="center" color="text.disabled">
      {intl.formatMessage(messages['profile:appVersionTitle'], { version })}
    </Typography>
  );
};
