import { Offer } from './offer.types';

/**
 *  Offers with "rank" value display before offers without "rank" field
 *  Offers with lower "rank" display first
 *      Offer.rank=1 -> Offer.rank=2 -> Offer.rank=3
 *
 *  If offers have the same "rank" value - they should be sorted by "expiration date"
 *      Offer(rank=1 expire=15.10) -> Offer(rank=1 expire=17.10)
 *
 *  After sorted offers by rank - display rest offers sorted by expiration date
 *      Offer.expire=15.10.22 -> Offer.expire=17.10.22 -> Offer.expire=19.10.22
 */
export const sortOffersByExpirationDateAndRank = (a: Offer, b: Offer) => {
  const rankA = a.customData?.rank;
  const rankB = b.customData?.rank;

  // In case if no "rank" was provided or "rank" is the same
  if (rankA === rankB) {
    if (!a.expireAt || !b.expireAt) {
      return 0;
    }

    return +new Date(a.expireAt) - +new Date(b.expireAt);
  }

  if (rankB === undefined) {
    return -1;
  }
  if (rankA === undefined) {
    return 1;
  }

  return rankA - rankB;
};
