import React, { ReactNode } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { InfoActionSheetProps } from './info-action-sheet.types';
import { ModalDrawer, AchievementDecoration, NotVerifiedDecoration, VerifiedDecoration } from '../components';
import { ServiceLink } from '../components/ServiceLink';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { SizingMultiplier } from '../../../theme/constants';

export const InfoActionSheet: React.FC<
  InfoActionSheetProps & {
    isVisible?: boolean;
    onClose?: () => void;
    children?: ReactNode;
  }
> = (props) => {
  const intl = useIntl();
  const {
    title,
    description,
    variant,
    actionButtonText = intl.formatMessage(buttonTextMessages['button-text:close']),
    actionButtonVariant = 'contained',
    isServiceLinkEnabled,
    isVisible = true,
    onActionButtonClick,
    onExited,
    onClose,
    children,
  } = props;

  return (
    <ModalDrawer open={isVisible} onClose={onClose} onExited={onExited} isDecorated={!!variant}>
      {variant === 'congrats' && <AchievementDecoration />}
      {variant === 'not-verified' && <NotVerifiedDecoration />}
      {variant === 'verified' && <VerifiedDecoration />}

      <Stack spacing={SizingMultiplier.lg} p={SizingMultiplier.lg} pt={SizingMultiplier.xl}>
        <Stack spacing={SizingMultiplier.md}>
          {title && (
            <Typography variant="h5" component="h1">
              {title}
            </Typography>
          )}
          {description && <Typography>{description}</Typography>}
          {children}
        </Stack>
        {onActionButtonClick && (
          <Button onClick={onActionButtonClick} color="primary" variant={actionButtonVariant}>
            {actionButtonText}
          </Button>
        )}
        {isServiceLinkEnabled && <ServiceLink />}
      </Stack>
    </ModalDrawer>
  );
};
