import { useMemo } from 'react';

import { OfferActivationStatus, useActivateOfferMutation } from '../../../graphql/types.generated';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { OfferActivateStartType } from '../../../lib/rudderStack/rudderStack.types';
import { Offer } from '../../../modules/offers/offer.types';

export type UseActivateOffer = (params: { onError: (error: unknown) => void; onCompleted?: () => void }) => {
  activate: (offer: Offer) => Promise<{ id: string; activationStatus: OfferActivationStatus } | null>;
  activating: boolean;
};

export const useActivateOffer: UseActivateOffer = ({ onError, onCompleted }) => {
  const { triggerEvent } = useRudderStack();
  const [offerActivate, { loading: activating }] = useActivateOfferMutation({
    refetchQueries: ['currentCustomer', 'offers', 'getTotalAvailableRollbackMoneyAmount'],
    onError,
    onCompleted,
  });

  return useMemo(
    () => ({
      activate: async (offer: Offer) => {
        try {
          triggerEvent('offer_activate_start', { type: OfferActivateStartType.Activate, uuid: offer.id });
          const result = await offerActivate({ variables: { input: { offerId: offer.id } } });
          if (result.data?.offerActivate?.activationStatus !== OfferActivationStatus.Activated) {
            throw new Error('Offer activationStatus is not OfferActivationStatus.Activated');
          }
          return result.data.offerActivate;
        } catch (e: unknown) {
          onError(e);
          return null;
        }
      },
      activating,
    }),
    [activating, offerActivate, onError, triggerEvent],
  );
};
