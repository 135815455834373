import { useEffect } from 'react';

import { useAppRatePendingState } from './useAppRatePendingState';
import { isDiscountEarningMechanism } from '../offers/offer.type-guards';
import { useTransactionsWithOfferFulfillmentLazyQuery } from '../../graphql/types.generated';
import { useAppRateDialog } from './useAppRateDialog';
import { useAppRateDialogController } from './useAppRateDialogController';
import { APP_RATE_RECENT_TRANSACTION_COUNT_TO_CONSIDER } from './app-rate.constants';
import { useAppRateUtils } from './app-rate.utils';

export const useShowAppRatingOnDiscountOfferFulfilled = () => {
  const [getIsAppRatePendingRequest] = useAppRatePendingState();
  const { getIsAppRateAvailable } = useAppRateDialogController();
  const { getIsRecentTransaction } = useAppRateUtils();
  const { open } = useAppRateDialog();

  const [getTransactions] = useTransactionsWithOfferFulfillmentLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      size: APP_RATE_RECENT_TRANSACTION_COUNT_TO_CONSIDER,
    },
  });

  useEffect(() => {
    (async () => {
      const isAppRateAvailable = await getIsAppRateAvailable();
      const isAppRatePendingRequest = await getIsAppRatePendingRequest();

      if (isAppRatePendingRequest || !isAppRateAvailable) {
        return;
      }

      const { data } = await getTransactions();
      const transactions = data?.customer?.membership?.transactions?.edges?.map((edge) => edge?.node);
      if (!transactions?.length) {
        return;
      }
      const hasFulfilledDiscountOffer = !!transactions.find((transaction) => {
        const hasFulfilledDiscounts = !!transaction?.offerFulfillments
          ?.map((ruleFulfillment) => ruleFulfillment.fulfilledOffer?.earningMechanism?.__typename)
          .find((typename) => (typename ? isDiscountEarningMechanism(typename) : false));
        return hasFulfilledDiscounts && getIsRecentTransaction(transaction?.createdAt);
      });

      if (!hasFulfilledDiscountOffer) {
        return;
      }

      open();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
