import { PAYMENT_PROVIDER_TYPE, PaymentProviderConfiguration } from '../app-config.types';
import { FiservConfiguration, WorldpayConfiguration } from '../../../graphql/types.generated';

type PaymentProviderConfigurationFragment =
  | ({ __typename: 'FiservConfiguration' } & FiservConfiguration)
  | ({ __typename: 'WorldpayConfiguration' } & WorldpayConfiguration);

export const mapPaymentProviderConfigurationFragment = (
  paymentProviderFragment: PaymentProviderConfigurationFragment,
): PaymentProviderConfiguration => {
  if (paymentProviderFragment.__typename === 'WorldpayConfiguration') {
    return {
      type: PAYMENT_PROVIDER_TYPE.WORLDPAY,
      eProtectPayPageId: paymentProviderFragment.eProtectPayPageId,
      eProtectGooglePayPageId: paymentProviderFragment.eProtectGooglePayPageId,
      eProtectWebSdkApiUrl: paymentProviderFragment.eProtectWebSdkApiUrl,
      eProtectWebSdkUrl: paymentProviderFragment.eProtectWebSdkUrl,
    };
  }

  if (paymentProviderFragment.__typename === 'FiservConfiguration') {
    return {
      type: PAYMENT_PROVIDER_TYPE.FISERV,
    };
  }

  return null;
};
