import React from 'react';
import { ButtonBase } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

type ListItemLinkProps = {
  href: string;
  target?: HTMLAnchorElement['target'];
  children?: React.ReactNode;
  onClick?: () => void;
};

export const ListItemLink: React.FC<ListItemLinkProps> = (props) => {
  const { children, href, target, onClick } = props;
  return (
    <ButtonBase
      onClick={onClick}
      href={href}
      component="a"
      rel="noreferrer"
      target={target}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: SizingMultiplier.md,
        minHeight: 56,
      }}>
      {children}
    </ButtonBase>
  );
};
