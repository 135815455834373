import React from 'react';

import { PointsChange } from '../../../components/PointsChange/PointsChange';
import { DollarIcon } from '../../../components/Icons/DollarIcon';
import { PointsStrategy } from '../../../graphql/types.generated';
import { DynamicCardAccessory } from '../../../components/DynamicCard';
import { ListItemButton, ListItemStartIcon, ListItemText } from '../../../components/List_v2';

export interface ITransactionHistoryItemProps {
  date: string;
  title: string;
  points?: number | null;
  icon?: React.ReactNode | null;
  onClick?(): void;
  showArrow?: boolean;
  pointsStrategy: PointsStrategy;
}

export const TransactionHistoryItem: React.FC<ITransactionHistoryItemProps> = (props) => {
  const { icon = <DollarIcon />, date, title, points = null, onClick, showArrow = false, pointsStrategy } = props;

  return (
    <ListItemButton disableRipple={!onClick} onClick={onClick}>
      <ListItemStartIcon>{icon}</ListItemStartIcon>
      <ListItemText primary={date} secondary={title} />
      {pointsStrategy !== PointsStrategy.NotUsed && points !== null && (
        <PointsChange pointsStrategy={pointsStrategy} points={points} allowPlus={false} position="right" />
      )}
      {showArrow && <DynamicCardAccessory type="disclosureIndicator" />}
    </ListItemButton>
  );
};
