import React, { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { PageContent } from '../../components/Layout/PageContent/PageContent';
import { Page } from '../../components/Layout/Page/Page';
import { PoweredByOpenCommerce } from '../../components/Layout/PoweredByOpenCommerce/PoweredByOpenCommerce';
import { BackButton } from '../../components/Layout/NavigationBar/BackButton/BackButton';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { NavigationBar } from '../../components/Layout/NavigationBar/NavigationBar';
import { VendorLogo } from '../../components/Layout/NavigationBar/VendorLogo/VendorLogo';

export type TransactionReceiptLayoutProps = {
  children: ReactNode;
};

export const TransactionReceiptLayout: React.FC<TransactionReceiptLayoutProps> = ({ children }) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'transactionReceipt:htmlTitle',
          defaultMessage: 'Receipt',
          description: 'HTML title tag for Transaction Receipt Page',
        })}
      />
      <NavigationBar left={<BackButton />} center={<VendorLogo />} />
      <PageContent bgcolor="background.paper">
        {children}
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
