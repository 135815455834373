import React, { useMemo } from 'react';

import { EditProfile } from './EditProfile';
import { useEditProfileController } from './hooks/useEditProfileController';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { getProfileFormValidationSchema } from './formValidationSchema';
import { useHandleUpdateProfileError } from './edit-profile.utils';

export type EditProfileControllerProps = {
  onCancelClick(): void;
  onProfileUpdated(params: { isProfileCompletionTriggered?: boolean }): void;
};

export const EditProfileController = ({ onCancelClick, onProfileUpdated }: EditProfileControllerProps) => {
  const {
    config: {
      loyaltyProgram: { membershipMinAge, membershipMaxAge, isExplicitTermsAcceptanceRequired },
      termsConditions: { url: termsConditionsLink },
      privacyPolicy: { url: privacyPolicyLink },
    },
  } = useConfiguration();

  const handleError = useHandleUpdateProfileError();
  const { loading, updateCustomer, initialValues, membership, isExplicitTermsAcceptanceEnabled } =
    useEditProfileController({
      onComplete: onProfileUpdated,
      onError: handleError,
      isExplicitTermsAcceptanceRequired,
    });

  const { validationSchema, maxPossibleYOB, minPossibleYOB } = useMemo(
    () => getProfileFormValidationSchema({ membershipMinAge, membershipMaxAge, isExplicitTermsAcceptanceRequired }),
    [isExplicitTermsAcceptanceRequired, membershipMaxAge, membershipMinAge],
  );

  return (
    <EditProfile
      loading={loading}
      initialValues={initialValues}
      updateCustomer={updateCustomer}
      isProfileCompleted={membership.isCompleted}
      onCloseClick={onCancelClick}
      validationSchema={validationSchema}
      maxPossibleYOB={maxPossibleYOB}
      minPossibleYOB={minPossibleYOB}
      termsConditionsLink={termsConditionsLink}
      privacyPolicyLink={privacyPolicyLink}
      isExplicitTermsAcceptanceEnabled={isExplicitTermsAcceptanceEnabled}
    />
  );
};
