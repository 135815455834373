import { LimitFragment } from '../../graphql/types.generated';
import { isMembershipAwardCountLimit } from './limit.type-guards';

export const getAvailableAwardCount = (limits: LimitFragment[]): number => {
  if (!limits.length) {
    return 0;
  }

  const membershipAwardCountLimitLimits = limits.filter(isMembershipAwardCountLimit);
  if (!membershipAwardCountLimitLimits.length) {
    return 0;
  }

  const availableAwardCounts = membershipAwardCountLimitLimits.map((limit) => limit.membershipQuantityAvailable || 0);
  return Math.min(...availableAwardCounts);
};
