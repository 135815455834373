import { ReactNode } from 'react';

import { FuelReceipt, ItemPrice, ReceiptLine, StoreAddressFragment, Transaction } from '../../graphql/types.generated';
import { PaymentInstrument } from '../wallet/wallet.types';

export interface IReceiptCarWash {
  name: string;
  code: string;
  price: number;
}
export interface IReceipt {
  total: number;
  receiptLines: string[];
  gallons: number | null;
  pricePerGallon: number | null;
  fuelTotal: number | null;
  tax: number | null;
  date: string;
  carWash: IReceiptCarWash | null;
  address: string;
  paymentInstrument: PaymentInstrument | null;
}

export interface IReceiptOption {
  title: string;
  value?: string;
  icon?: ReactNode;
}

export type SanitizedReceipt = Pick<
  Transaction,
  'uuid' | 'primaryStatus' | 'secondaryStatus' | 'fuelingPosition' | 'tax' | 'paymentInstrument'
> & {
  amount: ItemPrice;
  completedAt: string;
  fuelReceipt: FuelReceipt | null;
  location: { address: StoreAddressFragment };
  receiptLines: ReceiptLine[];
};

export interface IActivateTransactionReceiptLineItem {
  id: string;
  name: string | null;
  amount: number | null;
  quantity: number | null;
}
export interface IActivateTransactionReceipt {
  id: string;
  date: string;
  address: string | null;
  lineItems: IActivateTransactionReceiptLineItem[];
}

export enum PrimaryInternalFuelingStatus {
  Authorizing = 'Authorizing',
  BeginFueling = 'BeginFueling',
  Fueling = 'Fueling',
  Cancelling = 'Cancelling',
  CompletedCancelled = 'CompletedCancelled',
  CompletedSuccessfully = 'CompletedSuccessfully',
  CompletedUnsuccessfully = 'CompletedUnsuccessfully',
  Unknown = 'Unknown',
}
