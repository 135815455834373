import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useCallback, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import {
  AddPaymentInstrumentPopupController,
  AddPaymentInstrumentPopupProps,
} from './add-payment-instrument-popup.controller';
import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { logInDev } from '../../../lib/logger/logger';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { PaymentAccountType } from '../../../graphql/types.generated';

const AddPaymentInstrumentPopupModal = NiceModal.create(AddPaymentInstrumentPopupController);

type PaymentInstrumentSelectorModalProps = {
  onCancel(choice: Extract<PaymentAccountType, 'PREPAID' | 'CREDIT'> | 'cancel'): void;
};
const PaymentInstrumentSelectorModal = NiceModal.create(({ onCancel }: PaymentInstrumentSelectorModalProps) => {
  const { visible, hide, remove } = useModal();
  const handleExited = useCallback(() => {
    // onClosed?.();
    remove();
  }, [remove]);

  const handleCancel = useCallback(() => {
    onCancel('cancel');
    hide().catch(logInDev);
  }, [hide, onCancel]);

  const handleJustCard = useCallback(() => {
    onCancel(PaymentAccountType.Credit);
    hide().catch(logInDev);
  }, [hide, onCancel]);

  const handlePrepaidCard = useCallback(() => {
    onCancel(PaymentAccountType.Prepaid);
    hide().catch(logInDev);
  }, [hide, onCancel]);

  return (
    <ModalDrawer open={visible} onClose={handleCancel} onExited={handleExited}>
      <Stack spacing={SizingMultiplier.md} p={SizingMultiplier.lg} pt={SizingMultiplier.xl}>
        <Button onClick={handleJustCard} color="primary" variant="contained">
          <FormattedMessage
            id="payment-instrument-account-type-selector:credit:button-text"
            defaultMessage="credit or debit card"
            description="Button text for Credit card"
          />
        </Button>
        <Button onClick={handlePrepaidCard} color="primary" variant="contained">
          <FormattedMessage
            id="payment-instrument-account-type-selector:prepaid:button-text"
            defaultMessage="gift card"
            description="Button text for Prepaid card"
          />
        </Button>
        <Button onClick={handleCancel} color="primary" variant="text">
          <FormattedMessage {...buttonTextMessages['button-text:close']} />
        </Button>
      </Stack>
    </ModalDrawer>
  );
});

export const useAddPaymentInstrumentPopup = () => {
  const isPrepaidCardEnabled = useConfiguration('wallet.prepaidAccountTypeEnabled');
  const { show: showAddPaymentInstrument } = useModal(AddPaymentInstrumentPopupModal);
  const { show: showPreselect } = useModal(PaymentInstrumentSelectorModal);

  const open = useCallback(
    (props: Omit<AddPaymentInstrumentPopupProps, 'type'> = {}) => {
      if (!isPrepaidCardEnabled) {
        showAddPaymentInstrument({ onCreated: props.onCreated, type: PaymentAccountType.Credit });
        return;
      }

      showPreselect({
        onCancel: (choice) => {
          if (choice !== 'cancel') {
            showAddPaymentInstrument({ onCreated: props.onCreated, type: choice });
          }
        },
      });
    },
    [isPrepaidCardEnabled, showAddPaymentInstrument, showPreselect],
  );
  return useMemo(() => ({ open }), [open]);
};
