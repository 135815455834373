import React from 'react';
import { ButtonBase } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

import { SizingMultiplier } from '../../theme/constants';

type ListItemNavLinkProps = { to: LinkProps['to']; children?: React.ReactNode };

export const ListItemNavLink: React.FC<ListItemNavLinkProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <ButtonBase
      component={Link}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: SizingMultiplier.md,
        minHeight: 56,
      }}
      {...rest}>
      {children}
    </ButtonBase>
  );
};
