import { useCallback, useState } from 'react';

import {
  buildSurveyFormInitialValues as buildQuestionFormInitialValues,
  getQuestionsFromSurvey,
  getNextQuestionUUID,
  getSurveyAward,
} from '../../../../modules/survey/survey.utils';
import { SubmitAnswerParams, useGetSubmitAnswer } from '../../../../modules/survey/survey.submit-answer';
import {
  SurveyQuestion,
  SanitizedSurvey,
  SurveyFormValues,
  SurveyAward,
} from '../../../../modules/survey/survey.types';
import { useShowGenericErrorSnackbar } from '../../../../hooks/useShowGenericErrorSnackbar';
import {
  PointsStrategy,
  useCreateSurveyViewsMutation,
  useDismissSurveyMutation,
} from '../../../../graphql/types.generated';
import { useGetSubmitSurvey } from './use-get-submit-survey';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';
import { useAnimationHandler } from '../../../../hooks/useAnimationHandler';
import { AwardType } from '../../../../modules/offers/award.types';

export interface UseSurveyPopupControllerParams {
  onAllQuestionsAnswered(): void;
  onDismissError(): void;
  sanitizedSurvey: SanitizedSurvey;
}

export interface UseSurveyPopupControllerReturns {
  questions: SurveyQuestion[];
  initialValues: SurveyFormValues;
  award: SurveyAward;
  dismiss(): void;
  createSurveyView(): void;
  handleAnimationComplete: () => void;
  submit({ questionUUID, values }: Pick<SubmitAnswerParams, 'questionUUID' | 'values'>): Promise<void>;
}

export const useSurveyPopupController = ({
  onAllQuestionsAnswered,
  sanitizedSurvey,
  onDismissError,
}: UseSurveyPopupControllerParams): UseSurveyPopupControllerReturns => {
  const questions = getQuestionsFromSurvey(sanitizedSurvey);
  const [lastAnsweredQuestionUUID, setLastAnsweredQuestionUUID] = useState<string | null>(null);
  const handleError = useShowGenericErrorSnackbar();
  const [dismissSurveyMutation] = useDismissSurveyMutation({
    variables: { input: { surveyUuid: sanitizedSurvey.uuid } },
    onError: onDismissError,
  });
  const [createSurveyViewsMutation] = useCreateSurveyViewsMutation({
    variables: { input: { surveyUuids: [sanitizedSurvey.uuid] } },
  });
  const { handleAnimation, handleAnimationComplete } = useAnimationHandler();
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  const onSubmitSuccess = useCallback(
    (isSurveyAnswered: boolean) => {
      if (isSurveyAnswered) {
        onAllQuestionsAnswered();
      }
    },
    [onAllQuestionsAnswered],
  );

  const { uuid } = getNextQuestionUUID({ uuid: lastAnsweredQuestionUUID, questions });

  const award = getSurveyAward(sanitizedSurvey);

  const isAwardPoints = !!award && award.type === AwardType.Points;
  const isAwardVouchers = !!award && award.type === AwardType.Vouchers;
  const arePointsUsed = pointsStrategy !== PointsStrategy.NotUsed;
  const isAnimationOnSuccess = isAwardVouchers || (isAwardPoints && arePointsUsed);

  const handleSubmitAnswerSuccess = useCallback(async () => {
    const { uuid: nextQuestionUUID, reason } = getNextQuestionUUID({ uuid, questions });
    setLastAnsweredQuestionUUID(nextQuestionUUID);

    if (isAnimationOnSuccess) {
      await handleAnimation;
    }

    if (reason === 'no-next-question') {
      onSubmitSuccess(true);
    }
  }, [onSubmitSuccess, questions, uuid, isAnimationOnSuccess, handleAnimation]);

  const { submit: submitAnswer } = useGetSubmitAnswer();
  const submit = useGetSubmitSurvey({
    submit: submitAnswer,
    handleSubmitAnswerSuccess,
    handleError,
    sanitizedSurvey,
  });
  const dismissSurvey = useCallback(() => {
    if (sanitizedSurvey.type === 'guest') {
      // The guest survey should remain accessible to users, even if user have previously cancelled it
      // Because user intentionally presses on the link
      return null;
    }

    return dismissSurveyMutation();
  }, [dismissSurveyMutation, sanitizedSurvey.type]);

  const createSurveyView = useCallback(() => {
    if (sanitizedSurvey.type === 'guest') {
      // survey view has not been implemented for guest surveys
      return null;
    }

    return createSurveyViewsMutation();
  }, [createSurveyViewsMutation, sanitizedSurvey.type]);

  const initialValues: SurveyFormValues = buildQuestionFormInitialValues(questions);

  return {
    questions,
    initialValues,
    award,
    handleAnimationComplete,
    dismiss: dismissSurvey,
    submit,
    createSurveyView,
  };
};
