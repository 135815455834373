import { useMemo } from 'react';

import { useOffer } from '../../modules/offers/use-offer.hook';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { isReadyToUseOffer as getIsReadyToUseOffer } from '../../modules/offers/offer.type-guards';
import { GetOfferVoucherQuery, useGetOfferVoucherQuery } from '../../graphql/types.generated';
import { OPTIMIZED_VOUCHERS_FILTER } from '../../modules/offers/offers.constants';
import { useReturnReward } from '../Home/features/useReturnReward';
import { useGetOfferLoyaltyAction } from '../Home/features/useGetOfferOnLoyaltyActionClick';
import { OfferDetailsAction } from '../../components/OfferDetails/OfferDetails';
import { messages } from '../../components/OfferDetails/offer-details.messages';
import { messages as offerMessages } from '../../modules/offers/offer.messages';
import { useMembership } from '../../modules/membership/use-membership.hook';

const getPointsSpendOfferIdToReturn = (response?: GetOfferVoucherQuery) => {
  // Query is limited to have just one voucher and just one offer
  const voucher = response?.customer?.membership?.offers?.edges?.[0]?.node.vouchers.edges?.[0]?.node || null;
  if (voucher?.source?.__typename !== 'OfferPurchase') {
    return null;
  }
  return voucher.source.purchasedWith.id;
};

export const useOfferDetailsController = (params: { offerId: string; onOfferDetailsClose(): void }) => {
  const { offerId, onOfferDetailsClose } = params;
  const {
    membership: { isCompleted: membershipIsCompleted },
  } = useMembership();
  const { offer, loading: isOfferLoading } = useOffer({ offerId, limitsIncluded: true });

  const { isReturnRewardEnabled } = useConfiguration('loyaltyProgram');
  const isReadyToUseOffer = useMemo(() => (offer ? getIsReadyToUseOffer(offer) : false), [offer]);
  const { data: offerVoucherResponse, loading: isOfferVoucherLoading } = useGetOfferVoucherQuery({
    skip: !isReadyToUseOffer || !isReturnRewardEnabled || !offer?.id,
    variables: {
      offerId: offer?.id || '',
      voucherFilter: OPTIMIZED_VOUCHERS_FILTER,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { returnReward, returningReward } = useReturnReward({
    onCompleted: onOfferDetailsClose,
  });
  const getOfferLoyaltyAction = useGetOfferLoyaltyAction({
    membershipIsCompleted,
    onCompleted: onOfferDetailsClose,
  });
  const [actionOnClick, isExecuting, actionTitle] = offer ? getOfferLoyaltyAction(offer) : [];

  const action = useMemo<OfferDetailsAction | null>(() => {
    const pointSpendOfferId = getPointsSpendOfferIdToReturn(offerVoucherResponse);
    if (pointSpendOfferId) {
      return {
        title: messages.returnRewardButtonTitle,
        buttonVariant: 'outlined',
        onClick: () => {
          if (pointSpendOfferId) {
            returnReward(pointSpendOfferId);
          }
        },
        isExecuting: returningReward,
      };
    }
    if (actionOnClick && actionTitle) {
      return {
        onClick: () => actionOnClick(),
        title: offerMessages[actionTitle],
        isExecuting,
      };
    }
    return null;
  }, [actionOnClick, actionTitle, isExecuting, offerVoucherResponse, returnReward, returningReward]);

  return { offer, isOfferLoading, isOfferVoucherLoading, action };
};
